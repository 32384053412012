import React from 'react';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'utils';
import { Tabs, Tab } from '@mui/material';

const styles = (theme) => ({
  container: {
    backgroundColor: theme.palette.primary.accent,
  },
  header: {
    padding: theme.spacing(3),
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  headerTitle: {
    color: '#FFFFFF',
    fontSize: 24,
    paddingRight: 30,
  },
  tabs: {
    paddingTop: 3,
    paddingLeft: theme.spacing(3),
  },
  indicator: {
    backgroundColor: '#FFFFFF',
    height: 2,
    marginBottom: 8,
  },
  headerDivider: {},
  tabPanel: {
    color: '#E0E0E0',
    '&.Mui-selected': {
      color: '#FFFFFF',
    },
    '&:hover': {
      color: '#FFFFFF',
    },
  },
});

class ContactsTabs extends React.Component {
  render() {
    const { tab, classes } = this.props;

    return (
      <div className={classes.container}>
        <Tabs
          variant='scrollable'
          TabIndicatorProps={{ className: classes.indicator }}
          className={classes.tabs}
          value={tab}
          onChange={this.onTabChange}
        >
          <Tab
            className={classes.tabPanel}
            label='All'
            value='contacts'
            disableRipple
          />
          <Tab
            className={classes.tabPanel}
            label='Favorites'
            value='favorite_contacts'
            disableRipple
          />
          <Tab
            className={classes.tabPanel}
            label='Recent'
            value='recent_contacts'
            disableRipple
          />

        </Tabs>
      </div>
    );
  }

  onTabChange = (event, newValue) => {
    this.props.router.navigate(`/${newValue}/`);
  };
}

ContactsTabs.propTypes = {
  router: PropTypes.object.isRequired,
  classes: PropTypes.object,
  tab: PropTypes.string.isRequired,
};

const styled = withStyles(styles)(ContactsTabs);
const routed = withRouter(styled);
export { routed as ContactsTabs };
