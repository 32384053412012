import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { Box, Card, CardContent, Icon } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import {
  PatientType,
  StoreUtil,
  LocationTypeUtil,
} from 'doctivity-shared/utils';
import { loadRecentSearches } from 'store/actions/dashboardActions';
import TruncatedChips from './TruncatedChips';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    height: 540,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '48px',
    borderBottom: `1px solid ${theme.palette.tertiary.heavyGrey}`,
    marginBottom: theme.spacing(2),
    height: '48px',
    maxHeight: '48px',
    minHeight: '48px',
  },
  tabs: {
    '& .MuiButtonBase-root': {
      paddingBottom: 0,
      paddingTop: 18,
      minHeight: '32px',
      color: theme.palette.tertiary.accent,
    },
    '& .MuiTabs-root': {
      minHeight: '38px',
    },
    '& .MuiTabs-indicator': {
      margin: 0,
      backgroundColor: theme.palette.tertiary.accent,
    },
  },
  headerTitle: {
    fontSize: '20px',
    display: 'flex',
    flexWrap: 'nowrap',
    textWrap: 'nowrap',
  },
  newNoteButton: {
    marginLeft: theme.spacing(1),
    marginTop: '2px',
    lineHeight: '16px',
    fontSize: '10px',
    textWrap: 'nowrap',
    height: '18px',
    alignSelf: 'center',
  },
  searchRowContainer: {
    '&:hover': {
      backgroundColor: theme.palette.tertiary.main,
    },
    '& a': {
      color: '#000 !important',
    },
    '& a:visited': {
      color: '#000 !important',
    },
    background: 'white',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    height: '100%',
    '&:hover': {
      backgroundColor: theme.palette.tertiary.main,
    },
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: theme.spacing(1),
    textDecoration: 'none',
    fontSize: '12px',
  },
  createDate: {
    color: theme.palette.tertiary.mediumGrey,
    fontWeight: 300,
    paddingLeft: theme.spacing(1),
  },
  dueDate: {
    color: theme.palette.tertiary.mediumGrey,
    fontWeight: 300,
  },
  body: {
    textWrap: 'nowrap',
    fontWeight: 300,
    fontSize: '12px',
    minWidth: '25%',
    overflow: 'hidden',
    maxWidth: '25%',
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
    whiteSpace: 'nowrap',
  },
  searches: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '438px',
    maxHeight: '438px',
  },
  chip: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  cardBody: {
    fontSize: '14px',
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column-reverse',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    flexGrow: 1,
    alignItems: 'flex-start',
    overflow: 'hidden',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  allActivityLink: {
    cursor: 'pointer',
    color: '#214E56',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  noFavorites: {
    color: '#707070',
    fontFamily: 'Roboto',
    '& > p > h5': {
      color: '#333',
      fontSize: 14,
    },
    fontSize: 14,
    [theme.breakpoints.up('lg')]: {
      paddingTop: '20px',
    },
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    height: '25rem',
    justifyContent: 'flex-start',
  },
  patientType: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  searchIcon: {
    color: theme.palette.primary.main,
  }
});

const FIELD_DISPLAY_MAP = {
  type: () => {
    return undefined;
  },
  isMarket: () => {
    return undefined;
  },
  claimSetting: ({ value }) => {
    return value === 0
      ? undefined
      : LocationTypeUtil.getNames()[value] ?? undefined;
  },
  claimsType: ({ value }, _, classes) => {
    if (typeof value !== 'string') {
      return undefined;
    } else if (value?.trim().toLowerCase() === 'organizations') {
      return <Icon className={classes.searchIcon}>apartment</Icon>;
    } else if (value?.trim().toLowerCase() === 'providers') {
      return <Icon className={classes.searchIcon}>people</Icon>;
    }
    return undefined;
  },
  patientType: ({ value }, _, classes) => {
    switch (value) {
      case PatientType.PEDIATRIC_PATIENTS:
        return <span className={classes.patientType}>Projected<br/>Pediatrics</span>;
      case PatientType.CAPTURED_CLAIMS:
        return <span className={classes.patientType}>Captured<br />Claims</span>;
      default:
        return <span className={classes.patientType}>Projected<br />Patients</span>;
    }
  },
};
const DISPLAY_ORDER = ['claimsType', 'type', 'patientType', ];
const getWeight = (field, idx) =>
  DISPLAY_ORDER.indexOf(field) ?? idx + DISPLAY_ORDER.length;

const convertForDisplay = (search, classes) => {
  const displayValues = search.flatMap((item, idx) => {
    const weight = getWeight(item.field, idx);
    if (item.field in FIELD_DISPLAY_MAP) {
      return {
        weight,
        value: FIELD_DISPLAY_MAP[item.field](item, search, classes),
      };
    }
    if (!item.display) return { weight, value: search.value };
    if (Array.isArray(item.display)) {
      return item.display.map((displayItem) => {
        if (displayItem.trim() !== '') {
          return {
            weight,
            value: displayItem.trim(),
          };
        }
        return { weight, value: item.value };
      });
    } else if (item.display.trim() !== '') {
      return { weight, value: item.display.trim() };
    }
    return { weight, value: item.value };
  });
  return displayValues.sort((a, b) => b.weight - a.weight).map((v) => v.value);
};

function UserSavedCriteria({ classes }) {
  const clientId = useSelector(({ app }) => app.selectedClient);
  const userId = useSelector(({ user }) => user.id);
  const [lastUpdate, setLastUpdate] = useState(null);
  const criteria = useSelector(
    ({ dashboard }) => dashboard[`analytics-recent-searches-${clientId}`],
  );
  const dispatch = useDispatch();
  const load = useCallback(() => {
    if (!lastUpdate || new Date() - lastUpdate > 60000) {
      setLastUpdate(new Date());
      dispatch(loadRecentSearches({ clientId, userId }));
    } 
  }, [clientId, userId, dispatch]);
  useEffect(() => {
    if (!clientId || !userId) return;
    if (
      !criteria ||
      (!StoreUtil.isLoading(criteria) && !StoreUtil.isLoaded(criteria))
    ) {
      load();
    }
  }, [clientId, userId, criteria, load]);

  return (
    <Card className={classes.container}>
      <CardContent className={classes.cardContent}>
        <div className={classes.header}>
          <div className={classes.headerTitle}>
            <span>Your Recent Searches</span>
          </div>
        </div>
        <div className={classes.cardBody}>
          <div className={classes.searches}>
            {criteria &&
              criteria?.data?.length > 0 &&
              criteria.data.map((search, idx) => {
                const formattedItems = convertForDisplay(search, classes)?.filter(
                  (v) => !!v,
                );
                const components = formattedItems.filter(
                  (v) => typeof v === 'object',
                );
                return (
                  <Box
                    key={`search-${search.id}-${idx}`}
                    sx={{
                      border: '1px solid #ebebeb',
                      height: 40,
                      borderRadius: '5px',
                      scrollMarginTop: '80px',
                      paddingLeft: '8px',
                      paddingRight: '8px',
                    }}
                    className={classes.searchRowContainer}
                  >
                    {React.Children.toArray(components)}
                    <TruncatedChips
                      className={classes.chip}
                      text={formattedItems.filter(
                        (item) => typeof item !== 'object',
                      )}
                    />
                  </Box>
                );
              })}
            {criteria && criteria.data?.length === 0 && (
              <div className={classes.noFavorites}>
                <p>No recent searches</p>
              </div>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
UserSavedCriteria.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styled = withStyles(styles)(UserSavedCriteria);
export { styled as UserSavedCriteria };
