import { ReducerUtil } from 'doctivity-shared/utils';
import { success } from 'redux-saga-requests';

import {
  API_SAVED_CRITERIA_LIST,
} from 'store/actions/savedCriteriaActions';

export const savedCriteria = (state = {}, action = null) => {
  let result = ReducerUtil.simpleReduce(
    state,
    action,
    undefined,
    API_SAVED_CRITERIA_LIST,
  );
  if (result.actionConsumed) {
    return result.state;
  }
  switch (action.type) {
    case success(API_SAVED_CRITERIA_LIST):
      return {
        ...state,
        list: {
          data: action.data,
          meta: {
            ...state.meta,
            loadStatus: 'STATUS_SUCCESS',
          },
        }
      };
  }
  return state;
};
