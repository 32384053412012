import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';

const styles = () => ({
  container: {
  },
});

function CheckboxWidget(props) {
  const {
    schema,
    id,
    value,
    required,
    disabled,
    readonly,
    label,
    autofocus,
    onChange,
    rawErrors,
  } = props;

  let description = null;
  if (rawErrors != null) {
    description = rawErrors;
  } else if (schema.description) {
    description = schema.description;
  }

  let checked = false;
  if (value) {
    checked = true;
  }
  return (
    <FormControl component='fieldset' required={required} error={rawErrors != null}>
      <FormControlLabel
        autoFocus={autofocus}
        disabled={disabled || readonly}
        control={(
          <Checkbox
            id={id}
            checked={checked}
            onChange={(event) => onChange(event.target.checked)}
          />
        )}
        label={label}
      />
      {false && description
        && <FormHelperText>{description}</FormHelperText>}
    </FormControl>
  );
}

CheckboxWidget.defaultProps = {
  autofocus: false,
};

CheckboxWidget.propTypes = {
  schema: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  autofocus: PropTypes.bool,
  onChange: PropTypes.func,
  rawErrors: PropTypes.array,
  label: PropTypes.string,
};

const styled = withStyles(styles)(CheckboxWidget);
export { styled as CheckboxWidget };
