import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { useDispatch } from 'react-redux';
import { StoreUtil } from 'doctivity-shared/utils';
import { listCountiesByPostalCodes } from 'store/actions/countiesActions';

const styles = (theme) => ({
  zipList: {
    width: '100%',
    height: '25rem',
    maxHeight: '25rem',
    padding: theme.spacing(1),
    marginRight: theme.spacing(2),
    fontSize: '20px',
    fontFamily: 'Roboto',
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    background: 'rgba(0, 168, 135, 0.08)',
    [theme.breakpoints.down('md')]: {
      maxHeight: 'unset',
      flexWrap: 'nowrap',
    },
  },
  leftCol: {
    flex: 1,
    flexGrow: 1,
    width: '50%',
  },
  rightCol: {
    flex: 1,
    flexGrow: 1,
    width: '50%',
  },
  divRows: {
    paddingBottom: '4px',

  },
});

function ZipCityList(props) {
  const { classes } = props;
  const dispatch = useDispatch();
  const [zipCityList, setZipCityList] = useState([]);
  const counties = useSelector(
    (state) => state.counties?.counties_by_postalcode,
  );

  useEffect(() => {
    if (
      props.zipList &&
      Array.isArray(props.zipList) &&
      props.zipList.length > 0
    ) {
      dispatch(listCountiesByPostalCodes(props.zipList));
    }
  }, [props.zipList]);
  useEffect(() => {
    const zipCityList = [];
    const zipSet = new Set();
    if (
      !props.zipList ||
      !Array.isArray(props.zipList) ||
      props.zipList.length === 0
    ) {
      setZipCityList(zipCityList);
      return;
    }
    const storeName = props.zipList?.join(',');
    if (
      counties?.[storeName] &&
      StoreUtil.isLoaded(counties[storeName]) &&
      counties[storeName].data
    ) {
      counties[storeName].data.forEach((row) => {
        if (!zipSet.has(row.postal_code)) {
          zipCityList.push(row);
          zipSet.add(row.postal_code);
        }
      });
      setZipCityList(zipCityList);
    }
  }, [counties]);
  const left = [];
  const right = [];
  zipCityList.forEach((zipCity, index) => {
    if (zipCityList.length > 29) {
      if (index  < (zipCityList.length / 2)) {
        left.push(zipCity);
      } else {
        right.push(zipCity);
      }
    } else if (zipCityList.length > 14) {
      if (index < 15) {
        left.push(zipCity);
      } else {
        right.push(zipCity);
      }
    } else {
      left.push(zipCity);
    }
  });
  return (
    <div className={classes.zipList}>
      <div className={classes.leftCol}>
        {left.map((zipCity) => (
          <div key={zipCity.postal_code} className={classes.divRows}>
             {zipCity.postal_code} {zipCity.city}, {zipCity.state}
          </div>
        ))}
      </div>
      <div className={classes.rightCol}>
        {right.map((zipCity) => (
          <div key={zipCity.postal_code}  className={classes.divRows}>
            {zipCity.postal_code} {zipCity.city}, {zipCity.state}
          </div>
        ))}
      </div>
    </div>
  );
}

ZipCityList.propTypes = {
  zipList: PropTypes.array,
  classes: PropTypes.object,
};

const styled = withStyles(styles)(ZipCityList);
export { styled as ZipCityList };
