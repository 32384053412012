import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Card, CardContent, Link } from '@mui/material';
import axiosInstance from 'utils/axiosUtil';
import Chart from 'react-apexcharts';
import { withStyles } from '@mui/styles';
import { EncounterTypeUtil, StoreUtil } from 'doctivity-shared/utils';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    height: 544,
    display: 'flex',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '48px',
    maxHeight: '48px',
    minHeight: '48px',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  headerTitle: {
    fontSize: '20px',
    display: 'flex',
    flexWrap: 'nowrap',
    textWrap: 'nowrap',
  },
  cardBody: {},
  apexIcons: {
    fontFamily: 'Material Icons !important',
    fontSize: '16px',
    marginTop: '2px',
  },
  allActivityLink: {
    cursor: 'pointer',
    color: '#214E56',
    fontSize: '12px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: '24px',
  },
});
const colors = [
  '#FFBF69',
  '#FA9090',
  '#F219D0',
  '#2680E9',
  '#04CBE8',
  '#02E39D',
  '#9937BA',
  '#C6C6C6',
];

const SortedEncounters = [
  { id: 8, name: 'Unspecified', icon: 'help_outline' },
  ...EncounterTypeUtil.getAll(),
].sort((a, b) => a.id - b.id);

function ActivityNotesSummaryChart({ classes }) {
  const clientId = useSelector(({ app }) => app.selectedClient);
  const userId = useSelector(({ user }) => user.id);
  const editComment = useSelector(({ providerComments }) =>
    StoreUtil.get(providerComments, StoreUtil.COMMON_EDIT_ITEM),
  );
  const [loadedFor, setLoadedFor] = useState({
    clientId: undefined,
    userId: undefined,
  });
  const addIcons = useCallback(
    (/*chartContext, config*/) => {
      const elems = document.querySelectorAll('.apexcharts-yaxis-label');
      elems.forEach((elem, index) => {
        elem.style.fill = colors[index];
        const text = elem.getElementsByTagName('tspan');
        if (text && text.length === 1) {
          const child = text[0].cloneNode(true);
          text[0].setAttribute('dx', '-16px');
          text[0].setAttribute('dy', '2px');
          // Safari uses the width of the icon text name to figure out the width
          // of the icon but doesn't seem to want to respect any other options
          child.setAttribute('x', '-22px');
          child.setAttribute('dy', '4px');
          child.setAttribute('text-anchor', 'start');
          child.setAttribute(
            'class',
            `material-symbols-outlined ${classes.apexIcons}`,
          );
          child.innerHTML = SortedEncounters[index].icon;
          elem.append(child);
        }
      });
    },
    [],
  );
  const [chartOptions, setChartOptions] = useState({
    series: [{ data: [0, 0, 0, 0, 0, 0, 0, 0] }],
    options: {
      colors,
      chart: {
        id: 'activity-chart',
        toolbar: {
          show: false,
        },
        type: 'bar',
        events: {
          mounted: addIcons,
          updated: addIcons,
        },
      },
      grid: {
        padding: {
          left: 32,
          bottom: 8,
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '35%',
          borderRadius: 5,
          borderRadiusApplication: 'end',
          distributed: true,
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        offsetX: -10,
        background: {
          enabled: true,
          borderRadius: 5,
          opacity: 0.1,
          borderWidth: 1,
        },
        total: { enabled: true },
      },
      legend: {
        show: false,
      },
      yaxis: {
        style: {
          fontSize: '14px',
          colors: ['#dcdcdc'],
        },
        title: {
          style: {
            color: '#dcdcdc',
          },
        },
        labels: {
          maxWidth: 400,
          minWidth: 80,
          offsetY: 6,
        },
        stepSize: 2,
      },
      xaxis: {
        categories: [
          ['Email'],
          ['Call'],
          ['Text'],
          ['Provider'],
          ['Office Visit'],
          ['Virtual'],
          ['Event'],
          ['Unspecified'],
        ],
        range: 20,
        decimalsInFloat: 0,
        axisTicks: {
          color: '#C6C6C6',
        },
        labels: {
          style: {
            fontSize: '12px',
            colors: ['#000'],
          },
          showDuplications: false,
          formatter: function (val) {
            return val.toFixed(0);
          },
        },
        title: {
          style: {
            color: '#C6C6C6',
          },
        },
      },
    },
  });
  const recentActivityNotes = useSelector(({ dashboard }) =>
    StoreUtil.get(dashboard, `analytics-recent-activitynotes-${clientId}`),
  );
  const loadCounts = useCallback(async () => {
    const results = await axiosInstance.get(
      `/v2/my/comments/monthly_activity?clientId=${encodeURIComponent(clientId)}`,
    );
    if (results && results.data) {
      const typeToName = SortedEncounters.reduce((acc, type) => {
        acc[type.id] = type.name;
        return acc;
      }, {});
      const counts = results.data.reduce((acc, item) => {
        acc[typeToName[item.encounter_type_id ?? 8]] = item.total;
        return acc;
      }, {});
      let max = 20;
      const series = Array.from(Object.keys(typeToName).length).fill(0);
      SortedEncounters.forEach((type) => {
        if (!counts[type.name]) {
          counts[type.name] = 0;
        }
        series[(type.id ?? 8) - 1] = counts[type.name];
        max = Math.max(20, counts[type.name]);
      });
      const newChartOptions = {
        ...chartOptions,
        series: [{ name: 'Total', data: series }],
      };
      newChartOptions.options.xaxis.max = max;
      newChartOptions.options.xaxis.min = 0;
      newChartOptions.options.xaxis.tickAmount = 'dataPoints';

      setChartOptions(newChartOptions);
    }
  }, [clientId, userId]);

  useEffect(() => {
    if (StoreUtil.isSaved(editComment)) {
      loadCounts();
    }
  }, [editComment]);

  useEffect(() => {
    if (clientId && userId && loadedFor.clientId !== clientId) {
      setLoadedFor({ clientId, userId });
      loadCounts();
    }
  }, [recentActivityNotes, clientId, userId]);

  return (
    <Card className={classes.container}>
      <CardContent className={classes.cardContent}>
        <div className={classes.header}>
          <div className={classes.headerTitle}>
            <span>Your Activity This Month</span>
          </div>
        </div>
        <div className={classes.cardBody}>
          <Chart
            style={{ border: '1px solid #dcdcdc' }}
            options={chartOptions.options}
            series={chartOptions.series}
            type='bar'
            height={420}
          />
        </div>
      </CardContent>
      <Link className={classes.allActivityLink} href={'/notes_report'}>
        See activity note reports
      </Link>
    </Card>
  );
}

ActivityNotesSummaryChart.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styled = withStyles(styles)(ActivityNotesSummaryChart);
export { styled as ActivityNotesSummaryChart };
