import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import withStyles from '@mui/styles/withStyles';
import {
  MenuItem,
  Button,
  Popover,
  FormControl,
  Select,
  InputLabel,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { setDateRange } from 'store/actions/appActions';
import { DateUtil, StoreUtil } from 'doctivity-shared/utils';

const styles = (theme) => ({
  dateRangeButton: {
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.bright,
    },
    fontWeight: 400,
  },
  rangeContainer: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  rangeControl: {
    minWidth: 120,
    marginBottom: 20,
  },
  dateRangeIcon: {
    marginRight: theme.spacing(),
  },
});

class DateRangeSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rangeMenuOpen: false,
      dateRangeError: undefined,
      debounceTimer: null,
    };
  }

  render() {
    const { classes, app } = this.props;
    const { rangeMenuOpen } = this.state;

    const startDate = this.getStartDate();

    const lastMonth = startDate.clone().endOf('month');
    const lastYear = lastMonth.clone().add(1, 'day').subtract(1, 'year');
    const last365 = 'Last 365 Days';

    let dateRange = app.dateRange;
    if (!dateRange) {
      // set to default, nothing set yet
      dateRange = {
        start: lastYear,
        end: lastMonth,
        name: last365,
        preset: 3,
      };
    }

    return (
      <>
        <Button
          className={classes.dateRangeButton}
          onClick={() => {
            this.setState({ rangeMenuOpen: !rangeMenuOpen });
          }}
          endIcon={<ExpandMoreIcon />}
          ref={(b) => {
            this.rangeButtonRef = b;
          }}
        >
          <DateRangeIcon className={classes.dateRangeIcon} />
          <div>{dateRange.name}</div>
        </Button>
        <Popover
          open={rangeMenuOpen}
          anchorEl={this.rangeButtonRef}
          onClose={() => {
            this.closeRangeMenu();
          }}
        >
          <div className={classes.rangeContainer}>
            <FormControl className={classes.rangeControl}>
              <InputLabel>Date Range</InputLabel>
              <Select
                label='Date Range'
                value={dateRange.preset || 0}
                onChange={this.changePresetDate}
              >
                <MenuItem value={1}>Last Year</MenuItem>
                <MenuItem value={2}>Year to Date</MenuItem>
                <MenuItem value={3}>{last365}</MenuItem>
                <MenuItem value={4}>
                  {startDate.clone().subtract(1, 'month').format('MMMM YYYY')}
                </MenuItem>
                <MenuItem value={5}>{startDate.format('MMMM YYYY')}</MenuItem>
                <MenuItem value={0}>Custom Range</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.rangeControl}>
              <DatePicker
                renderInput={(params) => <TextField {...params} />}
                label='From'
                name='from'
                value={new Date(dateRange.start)}
                onChange={this.changeStartDate}
                format={DateUtil.LDML_DISPLAY_FORMAT}
                maxDate={startDate}
                disableFuture={false}
                disablePast={false}
                disabled={dateRange.preset > 0}
              />
            </FormControl>
            <FormControl className={classes.rangeControl}>
              <DatePicker
                renderInput={(params) => <TextField {...params} />}
                label='To'
                value={new Date(dateRange.end)}
                onChange={this.changeEndDate}
                name='to'
                format={DateUtil.LDML_DISPLAY_FORMAT}
                minDate={dateRange.start.toDate()}
                maxDate={startDate.clone().endOf('month').endOf('day').toDate()}
                disableFuture={false}
                disablePast={false}
                disabled={dateRange.preset > 0}
              />
            </FormControl>
            {this.state.dateRangeError && (
              <div style={{ fontFamily: 'Roboto', color: 'red' }}>{this.state.dateRangeError}</div>
            )}
          </div>
        </Popover>
      </>
    );
  }

  getStartDate() {
    const { currentClient, clients } = this.props;

    let startDate = null;
    let client = null;
    if (currentClient) {
      client = currentClient;
    } else if (!client && clients && Array.isArray(clients)) {
      client = clients[0];
    }

    if (client) {
      if (client.most_recent_date) {
        startDate = moment(client.most_recent_date);
      }
    }

    if (!startDate) {
      startDate = moment().subtract(1, 'month');
    }
    return startDate;
  }

  closeRangeMenu() {
    this.setState({
      rangeMenuOpen: false,
      dateRangeError: undefined,
    });
  }

  changePresetDate = (event) => {
    const { dispatch, app } = this.props;

    const startDate = this.getStartDate();

    let newRange;
    const value = event.target.value;
    if (value === 0) {
      newRange = app.dateRange;
      if (newRange && typeof newRange.start === 'string') {
        newRange.start = moment(newRange.start);
      }
      if (newRange && typeof newRange.end === 'string') {
        newRange.end = moment(newRange.end);
      }
      newRange.preset = 0;
    } else if (value === 1) {
      // last year
      newRange = {
        name: 'Last Year',
        start: startDate.clone().subtract(1, 'year').startOf('year'),
        end: startDate.clone().subtract(1, 'year').endOf('year'),
        preset: value,
      };
    } else if (value === 2) {
      // year to date
      const { useClientDate, currentClient } = this.props;
      const selectedEnd = startDate.clone().endOf('day');
      const maxDate =
        currentClient &&
        moment(currentClient.most_recent_date).isBefore(selectedEnd)
          ? moment(currentClient.most_recent_date)
          : selectedEnd;
      const end = useClientDate ? maxDate : selectedEnd;
      newRange = {
        name: 'Year to Date',
        start: startDate.clone().startOf('year'),
        end,
        preset: value,
      };
    } else if (value === 3) {
      // last 365 days
      const lastMonth = startDate.clone().endOf('month');
      const lastYear = lastMonth.clone().add(1, 'day').subtract(1, 'year');
      newRange = {
        name: 'Last 365 Days',
        start: lastYear,
        end: lastMonth,
        preset: value,
      };
    } else if (value === 4) {
      // 2 months ago
      newRange = {
        name: startDate.clone().subtract(1, 'month').format('MMMM YYYY'),
        start: startDate.clone().subtract(1, 'month').startOf('month'),
        end: startDate.clone().subtract(1, 'month').endOf('month'),
        preset: value,
      };
    } else if (value === 5) {
      // month to date
      newRange = {
        name: startDate.format('MMMM YYYY'),
        start: startDate.clone().startOf('month'),
        end: startDate.clone().endOf('month'),
        preset: value,
      };
    }

    if (newRange) {
      dispatch(setDateRange(newRange));
      if (newRange.preset > 0) {
        this.closeRangeMenu();
      }
    }
  };

  validateDateRange = (from, to) => {
    const start = moment(from);
    const end = moment(to);
    if (!start.isValid()) {
      this.setState({
        dateRangeError: 'Invalid start date',
      });

    } else if (!end.isValid()) {
      this.setState({
        dateRangeError: 'Invalid end date',
      });
    } else if (start.isAfter(end)) {
      this.setState({
        dateRangeError: 'Start date must be before end date',
      });
    } else if (moment(end).diff(start, 'years') > 3) {
      this.setState({
        dateRangeError: 'Date range cannot exceed 3 years',
      });
    } else {
      this.setState({
        dateRangeError: undefined,
      });
      return true;
    }
    return false;
  };

  changeStartDate = (value) => {
    const { app } = this.props;
    if (
      this.validateDateRange(value, app.dateRange.end)
    ) {
      this.changeDateRange(moment(value), app.dateRange.end);
    }
  };

  changeEndDate = (value) => {
    const { app } = this.props;
    if (
      this.validateDateRange(app.dateRange.start, value)
    ) {
      this.changeDateRange(app.dateRange.start, moment(value));
    }
  };

  changeDateRange = (start, end) => {
    const { dispatch } = this.props;
    const { debounceTimer } = this.state;
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    this.setState({
      debounceTimer: setTimeout(() => {
        dispatch(
          setDateRange({
            start,
            end,
            name: `${DateUtil.formatDateForUser(start)} - ${DateUtil.formatDateForUser(end)}`,
          }),
        );
      }, 500),
    });
  };
}

DateRangeSelector.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  app: PropTypes.object,
  clients: PropTypes.array,
  selectedClient: PropTypes.number,
  useClientDate: PropTypes.bool,
  currentClient: PropTypes.object,
};

function mapStateToProps(state) {
  const {
    app: { selectedClient },
    clients,
  } = state;
  const list = StoreUtil.get(clients, StoreUtil.COMMON_LIST);
  const currentClient = list?.data?.find((c) => c.id === selectedClient);

  return {
    app: state.app,
    currentClient,
  };
}

const styled = withStyles(styles)(DateRangeSelector);
const connected = connect(mapStateToProps)(styled);
export { connected as DateRangeSelector };
