import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import { StoreUtil } from 'doctivity-shared/utils';
import { withRouter } from 'utils';
import { DynamicTable } from 'components';
import { favoritesByType } from 'store/actions/favoritesActions';
import { ContactsTabs } from './ContactsTabs';

import { Helmet } from 'react-helmet';
import { Typography } from '@mui/material';

const styles = (theme) => ({
 title: {
    padding: theme.spacing(3),
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    backgroundColor: theme.palette.primary.accent,
  },
  headerTitle: {
    color: '#FFFFFF',
    fontSize: 24,
    paddingRight: 30,
  },
  container: {
    padding: theme.spacing(3),
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    marginRight: theme.spacing(2),
    flex: 1,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});

class FavoriteContactsPage extends React.Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        label: 'Last Name',
        key: 'last_name',
        fulltext: true,
        style: { minWidth: 70 },
      },
      {
        label: 'First Name',
        key: 'first_name',
        fulltext: true,
        style: { minWidth: 70 },
      },
      {
        label: 'Organization',
        key: 'location.organization.name',
        fulltext: true,
      },
      { 
        label: 'Company',
        key: 'company',
        style: { minWidth: 100 },
      },
      {
        label: 'Owner',
        key: 'owner.last_name',
        format: (value, row) => {
          if (row.owner) {
            return `${row.owner.last_name}, ${row.owner.first_name}`;
          }
          return '';
        },
        style: { width: 80 }
      },
    ];

    this.state = {
      query: {
        order: [['name', 'ASC']],
        limit: 25,
        offset: 0,
      },
      addDialogOpen: false,
    };
  }

  componentDidMount() {
    this.fetchData();
  }


  componentDidUpdate(prevProps) {
    if (this.props.clientId !== prevProps.clientId
      || this.props.userId !== prevProps.userId) {
      this.fetchData();
    }
  }

  fetchData() {
    if (!StoreUtil.isLoaded(this.props.favorites)) {
      this.props.dispatch(favoritesByType(
        this.props.clientId,
        this.props.userId,
        'Contacts',
      ));
    }
  }


  render() {
    const { classes } = this.props;

    const { query } = this.state;

    const favorites = this.props.favorites;
    console.info(query);
    console.info(this.columns);
    var favoritesData = [];

    if (favorites.data) {
      for (let i = 0; i < favorites.data.rows.length; i++) {
        favoritesData.push(favorites.data.rows[i].contact);
      }
    }

    return (
      <>
          <Helmet defer={false}>
          <title>Contacts</title>
        </Helmet>
        <div className={classes.title}>
          <Typography className={classes.headerTitle}>Contacts</Typography>
         
        </div>
        <ContactsTabs tab='favorite_contacts' />
        <div className={classes.container}>

          <DynamicTable
            header='Favorite Contacts'            
            showFilters={false}
            columns={this.columns}
            query={query}
            data={favoritesData}
            onQueryChange={this.onQueryChange}
            getRowHref={(row) => `/contacts/${row.id}`}
          />
        </div>
      </>
    );
  }

  onQueryChange = (query) => {
    this.setState({ query }, this.fetchData.bind(this));
  };
}

FavoriteContactsPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  favorites: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired
};

function mapStateToProps(state) {
  const { favorites } = state;
  const userId = state.user ? state.user.id : null;
  const clientId = state.app.selectedClient;

  console.log(StoreUtil.get(favorites, 'by_type', `${clientId}-${userId}-Contacts`));


  return {
    userId,
    clientId,
    favorites: StoreUtil.get(favorites, 'by_type', `${clientId}-${userId}-Contacts`),
   
  };
}

const styled = withStyles(styles)(FavoriteContactsPage);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as FavoriteContactsPage };
