import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { PropTypes } from 'prop-types';
import { Routes, Route } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import { Card, CardHeader, CardContent, Typography } from '@mui/material';

import { LoadingView, ConfirmationDialog, Footer } from 'components';
import { StoreUtil } from 'doctivity-shared/utils';
import { withRouter, withIsMobile, withClientQuery } from 'utils';
import { setDateRange } from 'store/actions/appActions';
import {
  UsersPage,
  UserPage,
  ActivityLogPage,
  ClientsPage,
  ProvidersPage,
  AffiliatedProvidersPage,
  ReferringProvidersPage,
  ProviderDetailPage,
  UploadPage,
  TaxonomyPage,
  ProceduresPage,
  ReferralFlowPage,
  VisitsPage,
  RVUBenchmarksPage,
  ProviderCommentsPageContainer,
  RecentProvidersPage,
  FavoriteProvidersPage,
  LoginSettingsPage,
} from 'containers/pages';
import {
  DashboardV2Page,
} from 'dashboard';
import {
  OrganizationsPage,
  OrganizationDetailPage,
  RecentOrganizationsPage,
  AffiliatedOrganizationsPage,
  FavoriteOrganizationsPage,
} from 'organizations';
import {
  TagNamespacesPage,
  TagsPage as LabelTypesPage,
  TagDetailsPage,
} from 'tags';
import { ContactsPage, ContactDetailPage, FavoriteContactsPage, RecentContactsPage} from 'contacts';
import { DocumentsPage } from 'documents';
import { hideConfirmationDialog } from 'store/actions/systemActions';
import { listClients } from 'store/actions/clientsActions';
import { Header } from './Header';
import { AppNavigation } from './AppNavigation';
import {
  LocationsPage,
  LocationDetailPage,
  RecentLocationsPage,
  FavoriteLocationsPage,
} from 'locations';
import { ActivitySnackbar, ClaimsTabs } from 'components';

const drawerWidth = 224;
const minimizedDrawerWidth = 100;

const styles = (theme) => ({
  root: {
    display: 'flex',
    fontFamily: 'sans-serif',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    backgroundColor: '#fafafa',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      left: minimizedDrawerWidth,
      right: 0,
    },
  },
  container: {
    padding: 0,
    minHeight: '80%', // enough to get footer down
  },
  cardContainer: {
    marginTop: 200,
    marginLeft: 200,
    width: 350,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  button: {
    width: 150,
  },
  cardContent: {
    marginTop: 0,
    paddingTop: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  link: {
    color: '#00A887',
  },
  listSubheader: {
    backgroundColor: '#00A887',
    color: '#fff',
  },
  selectedWedgeIcon: {
    color: '#FAFAFA',
    marginRight: -17,
    fontSize: 40,
    position: 'absolute',
    right: 0,
  },
  divider: {
    borderColor: '#05BE8D',
  },
  accountIcon: {
    height: 16,
    width: 16,
    marginLeft: -20,
    paddingRight: 4,
    color: theme.palette.primary.bright,
  },
  logout: {
    '&:hover': {
      textDecoration: 'underline',
    },
    color: '#FFFFFF',
  },
  clientContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: minimizedDrawerWidth - 5 * parseInt(theme.spacing()),
    height: 24,
    backgroundColor: theme.palette.secondary.accent,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    padding: theme.spacing(),
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: theme.spacing(2),
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.shorter,
    }),
  },
  clientIcon: {
    fontSize: 12,
    fontWeight: 300,
    marginLeft: theme.spacing(),
    color: theme.palette.primary.accent,
  },
  clientName: {
    fontSize: 12,
    color: theme.palette.primary.accent,
  },
  clientContainerOpen: {
    maxWidth: drawerWidth - 5 * parseInt(theme.spacing()),
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter,
    }),
  },
  clientListItem: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    padding: 0,
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    color: theme.palette.primary.accent,
  },
  listItemText: {
    marginLeft: -16,
  },
  userNameContainer: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    width: minimizedDrawerWidth - 20,
  },
  userNameContainerLong: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: minimizedDrawerWidth + 40,
  },
  userName: {
    fontSize: 11,
    textAlign: 'center',
  },
});

class AppRoot extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clientMenuOpen: false,
      waitingForMouseExit: false,
    };
  }

  componentDidMount() {
    const { user, clients, dispatch } = this.props;

    if (user && user.id) {
      if (StoreUtil.needsLoadNoCache(clients)) {
        dispatch(listClients());
      }
      this.setDefaultDate();
    }
  }

  componentDidUpdate(props) {
    if (
      this.props.user &&
      (!props.user || this.props.user.id !== props.user.id)
    ) {
      dispatch(listClients());
    }
    if (this.props.selectedClient !== props.selectedClient) {
      this.setDefaultDate();
      const { router: { query, setQuery } } = this.props;
      setQuery({ ...query, client: this.props.selectedClient });
    }
    if (this.props.clients !== props.clients) {
      this.setDefaultDate();
    }
  }

  render() {
    const { classes, user, clients, app, system, isMobile } = this.props;

    if (!StoreUtil.isLoaded(clients)) {
      // don't load anything until clients are loaded
      return <LoadingView />;
    }

    let client = null;
    if (app.selectedClient && StoreUtil.isLoaded(clients)) {
      const data = StoreUtil.getData(clients);
      client = data.find((c) => c.id === parseInt(app.selectedClient));
    }

    if (
      StoreUtil.isLoaded(clients) &&
      StoreUtil.getData(clients).length === 0
    ) {
      return (
        <div className={classes.root}>
          <Header />
          <Card className={classes.cardContainer}>
            <CardHeader title='No Data Access' />
            <CardContent className={classes.cardContent}>
              <Typography variant='body2' color='error'>
                Your account does not have access to any clients. Please contact
                the Doctivity team and ask them to provide you access to your
                data.
              </Typography>
            </CardContent>
          </Card>
        </div>
      );
    }

    // don't load pages until we have this stuff
    const isLoading = !app.dateRange || typeof app.dateRange.start === 'string';

    let dialogs = [];
    if (system.dialogs) {
      dialogs = Object.keys(system.dialogs).map((id) => {
        const dialog = system.dialogs[id];
        if (dialog && dialog.props) {
          return dialog;
        }
        return null;
      });
    }
    return (
      <div className={classes.root}>
        <Helmet defer={false}>
          <title>Doctivity</title>
        </Helmet>

        <AppNavigation />

        <main className={classes.content}>
          {isMobile && <div className={classes.appBarSpacer} />}
          <div className={classes.container}>
            {isLoading ? (
              <LoadingView />
            ) : (
              <Routes>
                {user.is_admin && (
                  <Route exact path='/users' element={<UsersPage />} authed />
                )}
                <Route path='/users/:id' element={<UserPage />} authed />
                {user.is_admin && (
                  <Route
                    exact
                    path='/clients'
                    element={<ClientsPage />}
                    authed
                  />
                )}
                {user.is_admin && (
                  <Route
                    exact
                    path='/activity'
                    element={<ActivityLogPage />}
                    authed
                  />
                )}
                {user.is_admin && (
                  <Route
                    exact
                    path='/login_settings'
                    element={<LoginSettingsPage />}
                    authed
                  />
                )}
                <Route
                  exact
                  path='/providers'
                  element={<ProvidersPage />}
                  authed
                />
                 <Route
                  exact
                  path='/favorite_providers'
                  element={<FavoriteProvidersPage />}
                  authed
                />
                <Route
                  exact
                  path='/recent_providers'
                  element={<RecentProvidersPage />}
                  authed
                />
                <Route
                  exact
                  path='/referring_providers'
                  element={<ReferringProvidersPage />}
                  authed
                />
                <Route
                  exact
                  path='/affiliated_providers'
                  element={<AffiliatedProvidersPage />}
                  authed
                />
                <Route
                  path='/providers/:id'
                  element={<ProviderDetailPage />}
                  authed
                />
                <Route
                  path='/providers/:id/:tab'
                  element={<ProviderDetailPage />}
                  authed
                />
                {user.is_admin && (
                  <Route
                    exact
                    path='/taxonomy'
                    element={<TaxonomyPage />}
                    authed
                  />
                )}
                <Route
                  exact
                  path='/procedures'
                  element={<ProceduresPage />}
                  authed
                />
                {user.is_admin && (
                  <Route exact path='/upload' element={<UploadPage />} authed />
                )}
                <Route
                  exact
                  path='/documents'
                  element={<DocumentsPage />}
                  authed
                />
                <Route
                  exact
                  path='/documents/data'
                  element={<DocumentsPage />}
                  authed
                />

                <Route
                  exact
                  path='/notes/'
                  element={<ProviderCommentsPageContainer />}
                  authed
                />
                <Route
                  exact
                  path='/notes_report/'
                  element={<ProviderCommentsPageContainer tab='report' />}
                  authed
                />
                <Route
                  path='/notes/:id'
                  // Unique key to force navigation to note tab even if it is loaded
                  element={<ProviderCommentsPageContainer tab='details' />}
                  authed
                />
                <Route
                  exact
                  path='/rvu_benchmarks'
                  element={<RVUBenchmarksPage />}
                  authed
                />
                <Route exact path='/visits' element={<VisitsPage />} authed />
                <Route
                  path='/flow/:direction/:providerId'
                  element={<ReferralFlowPage />}
                  authed
                />
                <Route
                  path='/flow/:direction'
                  element={<ReferralFlowPage />}
                  authed
                />
                <Route path='/flow' element={<ReferralFlowPage />} authed />
                {client.has_claims_access && (
                  <Route exact path='/claims' element={<ClaimsTabs />} authed />
                )}
                <Route
                  exact
                  path='/organizations'
                  element={<OrganizationsPage />}
                  authed
                />
               <Route
                  exact
                  path='/favorite_organizations'
                  element={<FavoriteOrganizationsPage />}
                  authed
                />
                <Route
                  exact
                  path='/recent_organizations'
                  element={<RecentOrganizationsPage />}
                  authed
                />
                <Route
                  exact
                  path='/affiliated_organizations'
                  element={<AffiliatedOrganizationsPage />}
                  authed
                />
                <Route
                  path='/organizations/:organizationId'
                  element={<OrganizationDetailPage />}
                  authed
                />
                <Route
                  path='/organizations/:organizationId/:tab'
                  element={<OrganizationDetailPage />}
                  authed
                />
                <Route
                  exact
                  path='/locations'
                  element={<LocationsPage />}
                  authed
                />
                <Route
                  exact
                  path='/recent_locations'
                  element={<RecentLocationsPage />}
                  authed
                />
                <Route
                  exact
                  path='/favorite_locations'
                  element={<FavoriteLocationsPage />}
                  authed
                />
                <Route
                  path='/locations/:id'
                  element={<LocationDetailPage />}
                  authed
                />
                <Route
                  path='/locations/:id/:tab'
                  element={<LocationDetailPage />}
                  authed
                />
                <Route
                  exact
                  path='/contacts'
                  element={<ContactsPage />}
                  authed
                />
                <Route
                  path='/contacts/:contactId'
                  element={<ContactDetailPage />}
                  authed
                />
                <Route
                  path='/contacts/:contactId/:tab'
                  element={<ContactDetailPage />}
                  authed
                />
                 <Route
                  exact
                  path='/favorite_contacts'
                  element={<FavoriteContactsPage />}
                  authed
                />
                <Route
                  exact
                  path='/recent_contacts'
                  element={<RecentContactsPage />}
                  authed
                />
                <Route
                  exact
                  path='/labels'
                  element={<TagDetailsPage />}
                  authed
                />
                <Route
                  path='/labels/:tagId'
                  element={<TagDetailsPage />}
                  authed
                />
                <Route
                  path='/labels/:tagId/:tab'
                  element={<TagDetailsPage />}
                  authed
                />
                <Route
                  exact
                  path='/label_types/'
                  element={<TagNamespacesPage />}
                  authed
                />
                <Route
                  exact
                  path='/label_types/:tagNamespaceId'
                  element={<LabelTypesPage />}
                  authed
                />
                <Route
                  exact
                  path='/dashboard'
                  element={<DashboardV2Page tab='providers' />}
                  authed
                />
                <Route
                  exact
                  path='/dashboard/providers'
                  element={<DashboardV2Page tab='providers' />}
                  authed
                />
                <Route
                  exact
                  path='/dashboard/locations'
                  element={<DashboardV2Page tab='locations' />}
                  authed
                />
                <Route
                  exact
                  path='/dashboard/contacts'
                  element={<DashboardV2Page tab='contacts' />}
                  authed
                />
                <Route path='*'
                  element={<DashboardV2Page tab='providers' />}
                />
              </Routes>
            )}
          </div>
          <Footer />
          <ActivitySnackbar />
          {system.confirmationDialog && (
            <ConfirmationDialog
              open={system.confirmationDialog != null}
              onCancel={
                system.confirmationDialog.onCancel
                  ? () => {
                      system.confirmationDialog.onCancel();
                      this.props.dispatch(hideConfirmationDialog());
                    }
                  : null
              }
              onClose={() => {
                this.props.dispatch(hideConfirmationDialog());
              }}
              onConfirm={() => {
                if (typeof system.confirmationDialog.onConfirm === 'function') {
                  system.confirmationDialog.onConfirm();
                }
                this.props.dispatch(hideConfirmationDialog());
              }}
              title={system.confirmationDialog.title}
              content={system.confirmationDialog.content}
              confirmLabel={system.confirmationDialog.confirmLabel}
              cancelLabel={system.confirmationDialog.cancelLabel}
            />
          )}
          {dialogs}
        </main>
      </div>
    );
  }

  getStartDate = () => {
    const { selectedClient, clients } = this.props;

    let startDate = null;
    let client = null;
    const clientData = StoreUtil.getData(clients);
    if (selectedClient) {
      client = clientData?.find((c) => c.id === parseInt(selectedClient));
    }

    if (client) {
      if (client.most_recent_date) {
        startDate = moment(client.most_recent_date);
      }
    }

    if (!startDate) {
      startDate = moment().subtract(1, 'month');
    }
    return startDate;
  };

  setDefaultDate = () => {
    const { dispatch } = this.props;

    let dateRange = app.dateRange;
    if (!dateRange) {
      const startDate = this.getStartDate();

      const lastMonth = startDate.clone().endOf('month');
      const lastYear = lastMonth.clone().add(1, 'day').subtract(1, 'year');
      const last365 = 'Last 365 Days';

      // set to default, nothing set yet
      dateRange = {
        start: lastYear,
        end: lastMonth,
        name: last365,
        preset: 3,
      };
    }

    dispatch(setDateRange(dateRange));
  };
}

AppRoot.propTypes = {
  router: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  selectedClient: PropTypes.number,
  user: PropTypes.object,
  clients: PropTypes.object,
  app: PropTypes.object,
  system: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    app: state.app,
    user: state.user,
    system: state.system,
    selectedClient: state.app.selectedClient,
    clients: StoreUtil.get(state.clients, StoreUtil.COMMON_LIST),
  };
}
const styledApp = withStyles(styles)(withIsMobile(AppRoot));
const connected = connect(mapStateToProps)(styledApp);
const routed = withRouter(withClientQuery(connected));
export { routed as AppRoot };
