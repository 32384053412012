export const ClaimsPageQueryParams = {
  CLAIMS_TYPE: { name: 'claimsType', accessor: 'claimsType' },
  REPORT: { name: 'report', accessor: 'report' },
  PATIENT_TYPE: { name: 'patientType', accessor: 'patientType' },
  IS_MARKET: { name: 'isMarket', accessor: 'isMarket' },
  MARKET: { name: 'market', accessor: 'market' },
  TAG: { name: 'tag', accessor: 'tag' },
  SERVICELINE: { name: 'serviceline', accessor: 'serviceline' },
  CODES: { name: 'codes', accessor: 'codes' },
  CODESTYPE: { name: 'codesType', accessor: 'codesType' },
  CARE_SETTING: { name: 'claimSetting', accessor: 'claimSetting' },
  TAXONOMIES: { name: 'specialties', accessor: 'taxonomies' },
  CLAIMS_DATE: { name: 'claimsDate', accessor: 'before' },
  AFFILIATION: { name: 'affiliation', accessor: 'affiliation' },
  AFFILIATED_CLIENT_ID: { name: 'affiliated_client_id', accessor: 'affiliated_client_id' },
  GROUP_BY_SERVICELINE: { name: 'groupByServicelines', accessor: 'groupByServicelines' },
  PAYORS: { name: 'payors', accessor: 'payors' },
};

export default ClaimsPageQueryParams;
