import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import {
  Grid,Typography
} from '@mui/material';
import {
  StoreUtil,
  PatientTypes,
} from 'doctivity-shared/utils';
import {
  ClaimsPatients,
  ClaimsPayers,
  ClaimsLocations,
  ClaimsByServiceline,
  ScrollableComponent
} from 'components';

import { withIsMobile} from 'utils';
import { listClaimsByProvider } from 'store/actions/claimsActions';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  card: {},
  filterDropDown: {
    width: 180,
    marginRight: theme.spacing(2),
  },
  providers: {
    width: '80%',
    paddingTop: 20,
    paddingBottom: 20,
  },
  providerRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    height: 24,
    marginBottom: 4,
    color: '#2E93fA',
    fontSize: 12,
    fontWeight: 300,
    position: 'relative',
    width: '100%',
    whiteSpace: 'nowrap',
  },
  providerBar: {
    position: 'absolute',
    backgroundColor: '#2E93fA',
    transitionProperty: 'width',
    transitionDelay: '1s',
    transitionDuration: '1s',
    width: 0,
    height: 24,
    top: 0,
    left: 0,
    borderRadius: 2,
    zIndex: 1,
    overflow: 'hidden',
    color: '#ffffff',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  providerName: {
    paddingTop: 2,
    paddingLeft: 8,
    zIndex: 0,
  },
  providerCount: {
    position: 'absolute',
    top: 7,
    left: 200,
    fontSize: 11,
    fontWeight: 300,
    zIndex: 0,
  },
  providerNameBar: {
    paddingTop: 6,
    paddingLeft: 8,
    zIndex: 2,
    overflow: 'hidden',
  },
  providerCountBar: {
    position: 'absolute',
    top: 7,
    left: 200,
    fontSize: 11,
    fontWeight: 300,
    zIndex: 3,
    overflow: 'hidden',
  },
  organizationCount: {
    position: 'absolute',
    top: 7,
    left: 440,
    fontSize: 11,
    fontWeight: 300,
    zIndex: 0,
  },
  organizationCountBar: {
    position: 'absolute',
    top: 7,
    left: 440,
    fontSize: 11,
    fontWeight: 300,
    zIndex: 3,
    overflow: 'hidden',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  spacer: {
    flex: 1,
  },
  header: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  headerTitle: {
    fontSize: 24,
    paddingRight: 30,
  },
  headerItem: {
    fontSize: 14,
    fontWeight: 200,
    paddingRight: 15,
  },
  rankBar: {
    marginBottom: 10,
    backgroundColor: '#2E93fA',
    fontSize: 14,
    color: '#ffffff',
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    borderRadius: 2,
  },
  rankTotal: {
    marginLeft: 4,
    fontSize: 11,
    color: '#ffffff',
    opacity: 0.5,
  },
  centerLabel: {
    width: '100%',
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 200,
    color: '#999999',
  },
});

class ClaimsProviderTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialHash: window.location.hash,
    };
  }
  componentDidMount() {
    if (StoreUtil.needsLoadNoCache(this.props.claims)) {
      this.fetchClaims();
    }
    if (this.state.initialHash) {
      const hash = this.state.initialHash.replace('#','');
      let tries = 0;
      let scrollInt = setInterval(() => {
        const elem = document.getElementById(hash);
        tries++;
        if (!elem) {
          if (tries > 20) clearInterval(scrollInt);
          return;
        }
        setTimeout(() => elem?.scrollIntoView( { block: 'start' } ), 100);
        clearInterval(scrollInt);
        scrollInt = undefined;
      }, 200);
    }
  }

  componentDidUpdate(prevProps) {
    // is object getting edited?
    if (
      this.props.provider !== prevProps.provider ||
      this.props.patientType !== prevProps.patientType
    ) {
      this.fetchClaims();
    }
  }

  fetchClaims() {
    this.props.dispatch(
      listClaimsByProvider(this.props.provider.id, this.props.patientType)
    );
  }

  render() {
    const { claims, isMobile, classes } = this.props;
    
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={5}>
          <ScrollableComponent id=''>
            {claims?.data && <ClaimsPatients provider={this.props.provider} claims={claims?.data} />}
          </ScrollableComponent>
        </Grid>
        <Grid item xs={12} md={7}>
          <ClaimsPayers
            provider={this.props.provider}
            patientType={this.props.patientType}
          />
        </Grid>
        <Grid item xs={12} style={{ display: isMobile ? 'none' : 'block' }}>
          <ScrollableComponent id='serviceline'>
            <ClaimsByServiceline
              isProvider
              claims={claims}
              patientType={this.props.patientType}
            />
          </ScrollableComponent>
        </Grid>
        <Grid item xs={12} style={{ display: isMobile ? 'none' : 'block' }}>
          <ScrollableComponent id='organizations'>
            <ClaimsLocations
              provider={this.props.provider}
              patientType={this.props.patientType}
            />
          </ScrollableComponent>
        </Grid>
        <Grid item xs={12} style={{ display: isMobile ? 'block' : 'none' }}>
        <Typography className={classes.centerLabel}>
          Please visit this page on desktop to see all charts.
        </Typography>
        </Grid>
      </Grid>
    );
  }
}

ClaimsProviderTab.propTypes = {
  classes: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  provider: PropTypes.object,
  claims: PropTypes.object,
  patientType: PropTypes.oneOf(PatientTypes),
};

function mapStateToProps(state) {
  return {
    claims: StoreUtil.get(state.claims, 'claims_list'),
    patientType: state.app.patientType,
  };
}
const styled = withStyles(styles)(withIsMobile(ClaimsProviderTab));
const connected = connect(mapStateToProps)(styled);
export { connected as ClaimsProviderTab };
