import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import axiosInstance from 'utils/axiosUtil';
import fileDownload from 'js-file-download';
import moment from 'moment';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { DynamicTable, LoadingView } from 'components';
import { getColumns, availableDownloads, getCSV } from './downloadTypes';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  header: {
    padding: theme.spacing(3),
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  headerTitle: {
    color: '#FFFFFF',
    fontSize: 24,
    paddingRight: 30,
  },
  tabs: {
    paddingTop: 3,
    paddingLeft: theme.spacing(3),
    backgroundColor: theme.palette.primary.accent,
  },
  indicator: {
    backgroundColor: '#FFFFFF',
    height: 2,
    marginBottom: 8,
  },
  headerDivider: {},
  tabPanel: {
    color: '#E0E0E0',
    '&.Mui-selected': {
      color: '#FFFFFF',
    },
    '&:hover': {
      color: '#FFFFFF',
    },
  },
  element: {
    paddingBottom: theme.spacing(2),
  },
  downloadForm: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(1),
  },
  displayedRowsBold: {
    fontWeight: '600',
  }
});

function debounce(func, delay = 250) {
    let debounceTimer;
    return function() {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
    }
}

class DataDownload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDownload: 0,
      showLoading: false,
      query: {
        ...availableDownloads[0].initialQuery,
        limit: 25,
        offset: 0,
      },
      data: {loading: false},
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(_, prevState) {
    if (prevState.selectedDownload !== this.state.selectedDownload) {
      this.setState(
        {
          query: {
            ...availableDownloads[this.state.selectedDownload].initialQuery,
            limit: 25,
            offset: 0,
          },
        },
        () => {
          this.fetchData();
        },
      );
    }
    if (prevState.query !== this.state.query) {
      this.fetchData();
    }
  }

  render() {
    const { showLoading, query, selectedDownload, data } = this.state;
    const { classes } = this.props;
    const columns = getColumns(selectedDownload);
    const download = availableDownloads[selectedDownload];
    const FilterForm = download.filterForm;
    if (showLoading) {
      return <LoadingView />;
    }
    return (
      <>
        <Helmet defer={false}>
          <title>Data Download</title>
        </Helmet>
        <div className={classes.container}>
          <FormControl variant='outlined' className={classes.element}>
            <InputLabel>Download Type</InputLabel>
            <Select
              value={selectedDownload}
              label='Download Type'
              onChange={this.onDownloadTypeChange}
            >
              {availableDownloads.map((d, idx) => (
                <MenuItem key={idx} value={idx}>
                  {d.displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <DynamicTable
            header={
              <div className={classes.headerContainer}>
                <Typography
                  component='h2'
                  variant='h6'
                  color='primary'
                  gutterBottom
                >
                  Download
                </Typography>
                <p>{download.helpText}</p>
                <p>
                  <h6>NOTE: ONLY THE FIRST 2000 ROWS WILL BE DOWNLOADED</h6>
                </p>
                <div className={classes.downloadForm}>
                  <FilterForm query={query} setQuery={this.setQuery} />
                  <Button
                    variant='contained'
                    onClick={this.fetchCSVDownload}
                    color='primary'
                  >
                    Download
                  </Button>
                </div>
                <div className={classes.tableHeader} />
              </div>
            }
            classes={data?.count > 2000 ? { displayedRows: classes.displayedRowsBold } : {}}
            columns={columns}
            query={query}
            onQueryChange={this.onQueryChange}
            data={data}
          />
        </div>
      </>
    );
  }

  setQuery = (query) => {
    this.setState({ query });
  };

  fetchData = debounce(async () => {
    if (this.state.data.loading ) return;
    this.setState({ data: { loading: true } },async () => {
      const { query, selectedDownload } = this.state;
      const downloadInfo = availableDownloads[selectedDownload];
      const results = await axiosInstance.get(`/${downloadInfo.endpoint}`, {
        params: {
          opts: query,
        },
      });
      this.setState({ data: { ...results.data, loading: false }});
    });
  });

  getFilename = () => {
    const { selectedDownload } = this.state;
    const downloadInfo = availableDownloads[selectedDownload];
    return downloadInfo.downloadName.replace('{date}' ,moment().format('YY_MM_DD'));
  }

  fetchCSVDownload = async () => {
    const { query, selectedDownload } = this.state;
    try {
      const data = await getCSV(availableDownloads[selectedDownload], query, this.props.store);
      const filename = this.getFilename();
      try {
        fileDownload(data, filename);
      } catch (err) {
        console.error(`Could not format csv for ${filename}`);
        console.error(err);
      }
    } catch (e) {
      console.error(e);
    }
  };

  onQueryChange = (query) => {
    this.setState({ query, showLoading: false });
  };

  onDownloadTypeChange = (event) => {
    this.setState({ selectedDownload: event.target.value });
  };
}

DataDownload.propTypes = {
  classes: PropTypes.object.isRequired,
  app: PropTypes.object,
  user: PropTypes.object,
  dispatch: PropTypes.func,
  store: PropTypes.object,
};

function mapStateToProps(state) {
  const { app, user } = state;

  return {
    app,
    user,
    store: state,
  };
}

const styled = withStyles(styles)(DataDownload);
const connected = connect(mapStateToProps)(styled);
export { connected as DataDownload };
