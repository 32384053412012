import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import { Icon, Typography, Tooltip } from '@mui/material';

import { StoreUtil } from 'doctivity-shared/utils';

import { DynamicTable, ProvidersTabs } from 'components';

import {
  queryProviders,
  API_PROVIDERS_LIST,
} from 'store/actions/providersActions';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  affiliatedIcon: {
    fontSize: 14,
    verticalAlign: 'text-top',
    color: theme.palette.secondary.main,
  },
});

class ProvidersPage extends React.Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        label: '',
        key: 'affiliated',
        style: { width: 14 },
        sortable: false,
        showFilter: false,
        format: (affiliated) => {
          if (affiliated > 0) {
            return (
              <Tooltip
                placement='bottom-start'
                title={
                  <Typography
                    style={{
                      fontSize: 13,
                      fontWeight: 200,
                    }}
                  >
                    Affiliated Provider
                  </Typography>
                }
              >
                <div
                  style={{
                    width: 14,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Icon className={props.classes.affiliatedIcon}>hub</Icon>
                </div>
              </Tooltip>
            );
          }
          return <></>;
        },
      },
      {
        label: 'Last Name',
        key: 'last_name',
        fulltext: true,
        style: { minWidth: 100 },
      },
      {
        label: 'First Name',
        key: 'first_name',
        fulltext: true,
        style: { minWidth: 100 },
      },
      {
        label: 'NPI',
        key: 'npi',
        style: { minWidth: 140, verticalAlign: 'top' },
      },
      {
        label: 'Specialty',
        key: 'taxonomy.classification',
        /*
        format: (classification, row) => {
          return SpecialtyUtil.getNameFromTaxonomy(row.taxonomy);
        },
        */
      },
      {
        label: 'City',
        key: 'location.city',
        fulltext: true,
      },
      {
        label: 'State',
        key: 'location.state',
        style: { width: 50 },
        filterExact: true,
        autoCapitalize: true,
      },
    ];

    this.state = {
      query: {
        attributes: ['id', 'first_name', 'last_name', 'npi'],
        include: [
          {
            association: 'taxonomy',
            attributes: ['classification'],
            require: false,
          },
          {
            attributes: ['id', 'city', 'state'],
            association: 'location',
            require: false,
          },
        ],
        order: [['id', 'DESC']],
        limit: 25,
        offset: 0,
      },
    };
  }

  componentDidMount() {
    this.fetchData();

    const oldQuery = sessionStorage.getItem('providersTableQuery');
    if (oldQuery) {
      this.onQueryChange(JSON.parse(oldQuery));
    }
  }

  componentDidUpdate(prevProps) {
    const { providers } = this.props;
    // is object getting edited?
    if (this.props.app.selectedClient !== prevProps.app.selectedClient) {
      this.fetchData();
    }

    if (
      !StoreUtil.isLoaded(prevProps.providers) &&
      StoreUtil.isLoaded(providers)
    ) {
      this.fetchAffiliated();
    }
  }

  onQueryChange = (query) => {
    if (query.where && Object.keys(query.where).length === 0) {
      delete query.where;
    }
    sessionStorage.setItem('providersTableQuery', JSON.stringify(query));
    this.setState({ query }, this.fetchData.bind(this));
  };

  fetchData() {
    const { dispatch, affiliatedProviders } = this.props;

    const { query } = this.state;

    StoreUtil.setInvalid(affiliatedProviders, StoreUtil.COMMON_LIST);
    dispatch(
      queryProviders({
        ...query,
      })
    );
  }

  fetchAffiliated() {
    const { dispatch, providers, clientId } = this.props;

    const { query } = this.state;

    if (providers?.data?.rows) {
      const providerIds = providers.data.rows.map((provider) => provider.id);
      dispatch(
        queryProviders(
          {
            ...query,
            where: { ...query.where, id: providerIds },
            limit: undefined,
            offset: undefined,
            include: [
              {
                association: 'clients',
                attributes: ['id'],
                required: true,
                through: { attributes: [] }, // don't need the join table data
                where: {
                  id: clientId,
                },
              },
            ],
          },
          API_PROVIDERS_LIST
        )
      );
    }
  }

  render() {
    const { classes, providers, affiliatedProviders } = this.props;

    const { query } = this.state;

    const affiliatedProvidersData = StoreUtil.getData(affiliatedProviders);
    const providersData = StoreUtil.getData(providers);
    if (affiliatedProvidersData && providersData?.rows) {
      for (let i = 0; i < providersData.rows.length; i++) {
        providersData.rows[i].affiliated = affiliatedProvidersData.some(
          (provider) => {
            return provider.id === providersData.rows[i].id;
          }
        );
      }
    }

    return (
      <>
        <ProvidersTabs tab='providers' />
        <div className={classes.container}>
          <DynamicTable
            header='Providers'
            columns={this.columns}
            query={query}
            data={providers}
            onQueryChange={this.onQueryChange}
            getRowHref={(row) => `/providers/${row.id}`}
          />
        </div>
      </>
    );
  }
}

ProvidersPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  providers: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  classes: PropTypes.object,
  app: PropTypes.object,
  affiliatedProviders: PropTypes.object,
};

function mapStateToProps(state) {
  const { providers, app } = state;

  return {
    app,
    clientId: app.selectedClient,
    providers: StoreUtil.get(providers, StoreUtil.COMMON_TABLE),
    affiliatedProviders: StoreUtil.get(providers, StoreUtil.COMMON_LIST),
  };
}

const styled = withStyles(styles)(ProvidersPage);
const connected = connect(mapStateToProps)(styled);
export { connected as ProvidersPage };
