import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import { Card, CardContent, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import {
  TitleTooltip,
  LoadingView,
  PatientTypeSelect,
  ServiceLineSelect,
} from 'components';

import { StoreUtil, PatientTypes, PatientType } from 'doctivity-shared/utils';
import { ColorUtil, withIsMobile, withRouter } from 'utils';

import { loadAnalytics } from 'store/actions/analyticsActions';
import {
  queryOrganizations,
  API_ORGANIZATIONS_LIST,
} from 'store/actions/organizationsActions';

const styles = (theme) => ({
  card: {},
  leftIcon: {
    marginRight: theme.spacing(),
  },
  filterDropDown: {
    width: 320,
    marginRight: theme.spacing(2),
  },
  filterLabel: {
    backgroundColor: 'white',
    paddingRight: 4,
  },
  filters: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  filter: {
    marginRight: theme.spacing(),
    minWidth: 168,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  },
  lastFilter: {
    marginBottom: 0,
  },
  noData: {
    textAlign: 'center',
    paddingTop: 50,
    minHeight: 200,
  },
  colorKey: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 12,
    fontWeight: 400,
    minHeight: 40,
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  colorKeyIcon: {
    width: 14,
    height: 14,
    marginRight: 2,
    borderRadius: 2,
  },
  colorKeyText: {
    marginRight: 12,
  },
});

class ClaimsLocations extends React.Component {
  constructor(props) {
    super(props);

    this.cardElement = React.createRef(null);

    this.state = {
      serviceline: 'all',
    };
  }

  componentDidMount() {
    const { network } = this.props;

    if (StoreUtil.needsLoadNoCache(network)) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.provider !== prevProps.provider ||
      this.props.patientType !== prevProps.patientType
    ) {
      this.fetchData();
    }

    if (
      !StoreUtil.isLoaded(prevProps.network) &&
      StoreUtil.isLoaded(this.props.network)
    ) {
      this.fetchNetworkAffiliated();
    }
  }

  fetchData() {
    const { dispatch, organizations, provider, patientType } = this.props;
    const { serviceline } = this.state;

    if (organizations?.list) {
      StoreUtil.setInvalid(organizations, StoreUtil.COMMON_LIST);
    }
    dispatch(
      loadAnalytics({
        type: 'CLAIMS_ORGANIZATIONS',
        filter: {
          provider_id: provider.id,
          patient_type: patientType,
          serviceline: serviceline === 'all' ? undefined : serviceline,
        },
        opts: {
          limit: 100,
          offset: 0,
        },
      })
    );
  }

  fetchNetworkAffiliated() {
    const { dispatch, network, clientId } = this.props;

    if (network?.data?.rows) {
      const networkOrganizationIds = network.data.rows.map(
        (network) => network.organization.id
      );
      dispatch(
        queryOrganizations(
          {
            where: { id: networkOrganizationIds },
            include: [
              {
                attributes: ['id'],
                association: 'affiliated',
                required: false,
                through: { attributes: [] }, // don't need the join table data
                where: {
                  id: clientId,
                },
              },
            ],
          },
          API_ORGANIZATIONS_LIST
        )
      );
    }
  }

  render() {
    const { classes, network, organizations } = this.props;
    const { serviceline } = this.state;

    const networkAffiliatedData = StoreUtil.getData(organizations);
    const isLoaded = !( !network || !StoreUtil.isLoaded(network) || !networkAffiliatedData)

    const data = [];
    const affiliatedLoaded =
      network?.data?.rows?.length === networkAffiliatedData?.length;
    network?.data?.rows?.forEach((d) => {
      let name = d.organization.name;
      const affiliated = affiliatedLoaded
        ? networkAffiliatedData.find(
            (networkAffiliated) => networkAffiliated.id === d.organization.id
          )?.affiliated?.length > 0 || false
        : false;
      if (name.length > 30) {
        name = `${name.substring(0, 30)}...`;
      }
      data.push({
        x: name,
        y: d.sum_total_patients,
        id: d.organization.id,
        fillColor: affiliated
          ? ColorUtil.AffiliatedColor
          : ColorUtil.NonAffiliatedColor,
        affiliated,
      });
    });
    const series = [
      {
        data,
      },
    ];

    const options = {
      legend: {
        show: false,
      },
      chart: {
        height: 350,
        type: 'treemap',
        toolbar: {
          show: false,
        },
        animations: {
          enabled: false,
        },
        events: {
          click: this.onChartClick,
        },
      },
      plotOptions: {
        treemap: {
          distributed: true,
          enableShades: false,
        },
      },
      tooltip: {
        custom: ({ dataPointIndex }) =>
          `<div style="padding:8px;">
            <div><b>${series[0].data[dataPointIndex].x}</b></div>
            ${
              series[0].data[dataPointIndex].affiliated
                ? '<div>AFFILIATED<div>'
                : ''
            }
            <div>${series[0].data[dataPointIndex].y} shared patients</span>
          </div>`,
      },
    };

    return (
      <Card className={classes.card} ref={this.cardElement}>
        <CardContent>
          <TitleTooltip
            title='Organizations'
            tooltip='Insight into relationships between providers and the organizations they work with.'
            rightLabel={null && 'Based on claims from all ages'}
          />

          <div className={classes.filters}>
            <div className={clsx(classes.filter, classes.lastFilter)}>
              <ServiceLineSelect
                value={serviceline}
                onChange={this.onServicelineChange}
                showSubServicelines
                showAll
              />
            </div>
            <div className={classes.filter}>
              <PatientTypeSelect noCapturedClaims />
            </div>
          </div>
          {!isLoaded && <LoadingView />}
          {isLoaded && network.data.rows.length === 0 && (
            <Typography className={classes.noData}>
              No known organizations.
            </Typography>
          )}
          {isLoaded && network.data.rows.length > 0 && (
            <>
              <div className={classes.colorKey}>
                <div
                  className={classes.colorKeyIcon}
                  style={{
                    backgroundColor: ColorUtil.AffiliatedColor,
                  }}
                />
                <div className={classes.colorKeyText}>Affiliated</div>

                <div
                  className={classes.colorKeyIcon}
                  style={{
                    backgroundColor: ColorUtil.NonAffiliatedColor,
                  }}
                />
                <div className={classes.colorKeyText}>Non-Affiliated</div>
              </div>
              <Chart
                type='treemap'
                height={350}
                options={options}
                series={series}
                colors={ColorUtil.getColors()}
              />
            </>
          )}
        </CardContent>
      </Card>
    );
  }

  onChartClick = (event, chartContext, config) => {
    const { isMobile } = this.props;
    if (!isMobile) {
      if (config.dataPointIndex > -1) {
        const id =
          this.props.network.data.rows[config.dataPointIndex].organization.id;
        this.props.router.navigate(`/organizations/${id}/market`);
      }
    }
  };

  onServicelineChange = (id) => {
    this.setState(
      {
        serviceline: id,
      },
      this.fetchData
    );
  };
}

ClaimsLocations.propTypes = {
  classes: PropTypes.object,
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  clientId: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  organizations: PropTypes.object,
  provider: PropTypes.object,
  network: PropTypes.object,
  patientType: PropTypes.oneOf(PatientTypes),
};

function mapStateToProps(state) {
  return {
    clientId: state.app.selectedClient,
    network: StoreUtil.get(state.analytics, 'CLAIMS_ORGANIZATIONS'),
    organizations: StoreUtil.get(state.organizations, StoreUtil.COMMON_LIST),
    patientType:
      state.app.patientType === PatientType.CAPTURED_CLAIMS
        ? PatientType.ALL_PATIENTS
        : state.app.patientType,
  };
}

const styled = withStyles(styles)(withIsMobile(ClaimsLocations));
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as ClaimsLocations };
