export const API_COUNTIES_BY_STATE = 'API_COUNTIES_BY_STATE';
export const API_POSTALCODES_BY_COUNTY = 'API_POSTALCODES_BY_COUNTY';
export const API_COUNTIES_BY_POSTALCODE = 'API_COUNTIES_BY_POSTALCODE';

export const listCountiesByState = (state) => ({
  type: API_COUNTIES_BY_STATE,
  id: state,
  request: {
    url: '/CountiesToPostal',
    method: 'GET',
    params: {
      opts: {
        group: [
          'county_name',
          'geo_id',
        ],
        attributes: [
          'county_name',
          'geo_id',
        ],
        where: { state },
        order: [
          ['county_name', 'ASC'],
        ],
      },
    },
  },
});

export const listPostalcodesByCounty = (county, state) => ({
  type: API_POSTALCODES_BY_COUNTY,
  id: `${county},${state}`,
  request: {
    url: '/CountiesToPostal',
    method: 'GET',
    params: {
      opts: {
        attributes: ['postal_codes'],
        where: {
          state,
          county_name: county,
        },
        order: [
          ['postal_code', 'ASC'],
        ],
      },
    },
  },
});

export const listCountiesByPostalCodes = (postCodes) => ({
  type: API_COUNTIES_BY_POSTALCODE,
  id: postCodes.join(','),
  request: {
    url: '/CountiesToPostal',
    method: 'GET',
    params: {
      opts: {
        attributes: ['city', 'state', 'county_name', 'geo_id', 'postal_code'],
        where: {
          postal_code: postCodes,
        },
        order: [
          ['postal_code', 'ASC'],
        ],
      },
    },
  },
});


