import React, { useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

const ScrollableComponent = ({ id, children }) => {
  const elementRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const element = elementRef.current;
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const { pathname, search } = location;
            navigate(`${pathname}${search}#${id}`, { replace: true });
          }
        });
      },
      {
        rootMargin: '0px',
        threshold: 0.8,
      },
    );

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [id, navigate]);

  return (
    <div id={id} ref={elementRef}>
      {children}
    </div>
  );
};
ScrollableComponent.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
};
export { ScrollableComponent };
