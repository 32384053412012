import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';

const styles = (theme) => ({
  container: {
    marginTop: theme.spacing.unit,
  },
  list: {
    width: '100%',
  },
  selectAll: {

  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  containerP: {
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    margin: 0,
  },
  containerPAlt: {
    backgroundColor: '#F5F5F5',
  },
  listP: {
    width: '25%',
    backgroundColor: 'transparent',
  },
  headerP: {
    width: '60%',
  },
});

function selectValue(value, selected, all) {
  const at = all.indexOf(value);
  const updated = selected.slice(0, at).concat(value, selected.slice(at));
  // As inserting values at predefined index positions doesn't work with empty
  // arrays, we need to reorder the updated selection to match the initial order
  return updated.sort((a, b) => all.indexOf(a) > all.indexOf(b));
}

function deselectValue(value, selected) {
  return selected.filter((v) => v !== value);
}

function CheckboxesWidget(props) {
  const {
    id,
    disabled,
    options,
    value,
    autofocus,
    readonly,
    onChange,
    rawErrors,
    required,
    schema,
    classes,
  } = props;
  const { enumOptions } = options;
  let description = null;
  if (rawErrors != null) {
    description = rawErrors;
  } else if (schema.description) {
    description = schema.description;
  }

  const showAllButton = options.showAllButton;
  const all = enumOptions;
  const allChecked = value && value.length === all.length;
  let buttonText;
  if (allChecked) {
    buttonText = 'Deselect All';
  } else {
    buttonText = 'Select All';
  }

  let containerCls;
  let headerCls;
  let itemCls;
  if (options.pathwayMode) {
    if (options.altRow) {
      containerCls = `${classes.containerP} ${classes.containerPAlt}`;
    } else {
      containerCls = classes.containerP;
    }
    headerCls = classes.headerP;
    itemCls = classes.listP;
  } else {
    containerCls = classes.container;
    headerCls = classes.header;
    itemCls = classes.list;
  }

  return (
    <div className={containerCls}>
      <div className={headerCls}>
        <FormLabel component='legend'>{schema.title}</FormLabel>
        {showAllButton && (
        <Button
          color='primary'
          variant='text'
          onClick={() => {
            if (allChecked) {
              onChange([]);
            } else {
              onChange(all);
            }
          }}
        >
          {buttonText}

        </Button>
        )}
      </div>
      <FormControl
        className={itemCls}
        component='fieldset'
        required={required}
        error={rawErrors != null}
      >
        {enumOptions.map((option, index) => {
          const checked = value ? value.indexOf(option.value) !== -1 : false;
          const checkbox = (
            <FormControlLabel
              autoFocus={autofocus && index === 0}
              disabled={disabled || readonly}
              key={`${id}_${index}`}
              control={(
                <Checkbox
                  id={`${id}_${index}`}
                  checked={checked}
                  onChange={(event) => {
                    if (event.target.checked) {
                      onChange(selectValue(option.value, value || [], all));
                    } else {
                      onChange(deselectValue(option.value, value || []));
                    }
                  }}
                />
)}
              label={option.label}
            />
          );
          return checkbox;
        })}
        {false && description
          && <FormHelperText>{description}</FormHelperText>}
      </FormControl>
    </div>
  );
}

CheckboxesWidget.defaultProps = {
  autofocus: false,
  options: {
    inline: false,
  },
};

CheckboxesWidget.propTypes = {
  schema: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  options: PropTypes.shape({
    enumOptions: PropTypes.array,
    inline: PropTypes.bool,
    showAllButton: PropTypes.bool,
    pathwayMode: PropTypes.bool,
    altRow: PropTypes.bool,
  }),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  required: PropTypes.bool,
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  autofocus: PropTypes.bool,
  onChange: PropTypes.func,
  rawErrors: PropTypes.array,
  classes: PropTypes.object,
};

const styled = withStyles(styles)(CheckboxesWidget);
export { styled as CheckboxesWidget };
