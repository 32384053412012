const LOCATION_TYPES = [
  { id: 0, name: 'All', color: '#FA9090' },
  { id: 1, name: 'Ambulatory Surgery Center', color: '#537DC9' },
  { id: 2, name: 'Outpatient', color: '#02E39D' },
  { id: 3, name: 'Office', color: '#FFBF69' },
  { id: 4, name: 'Inpatient', color: '#FF6B6B' },
  { id: 5, name: 'Lab', color: '#25C3E9' },
  { id: 10, name: 'Urgent Care', color: '#9937BA' },
  { id: 6, name: 'Skill Nursing Facility', color: '#6C52EB', codesOnly: true },
  { id: 7, name: 'Telehealth', color: '#2680E9', codesOnly: true },
  { id: 8, name: 'Home', color: '#02E39D', codesOnly: true },
  { id: 9, name: 'Other', color: '#FFDD6A', codesOnly: true },
];

class LocationTypeUtil {
  static getName(id) {
    const found = LOCATION_TYPES.find((locationType) => (locationType.id === id));
    if (found) {
      return found.name;
    }
    return null;
  }

  static getColor(id) {
    const found = LOCATION_TYPES.find((locationType) => (locationType.id === id));
    if (found) {
      return found.color;
    }
    return null;
  }

  static getIdFromClaimsDb(locationType) {
    if (locationType === 'ASC') {
      return 1;
    } else if (locationType === 'OP' || locationType === 'OUTPATIENT') {
      return 2;
    } else if (locationType === 'OFFICE') {
      return 3;
    } else if (locationType === 'IP' || locationType === 'INPATIENT') {
      return 4;
    } else if (locationType === 'LAB') {
      return 5;
    } else if (locationType === 'SNF') {
      return 6;
    } else if (locationType === 'TELE') {
      return 7;
    } else if (locationType === 'HOME') {
      return 8;
    } else if (locationType === 'OTHER') {
      return 9;
    } else if (locationType === 'URG') {
      return 10;
    }
    return null;
  }

  static getNames() {
    return LOCATION_TYPES.map((locationType) => (locationType.name));
  }

  static getIds() {
    return LOCATION_TYPES.map((locationType) => (locationType.id));
  }

  static getColors() {
    return LOCATION_TYPES.map((locationType) => (locationType.color));
  }

  static getAll() {
    return LOCATION_TYPES;
  }
}

module.exports = { LocationTypeUtil };
