import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import { Icon, Typography, Tooltip } from '@mui/material';
import { StoreUtil } from 'doctivity-shared/utils';
import { withRouter } from 'utils';
import { DynamicTable } from 'components';
import { favoritesByType } from 'store/actions/favoritesActions';
//import { OrganizationEditDialog } from './OrganizationEditDialog';
import { OrganizationsTabs } from './OrganizationsTabs';


const styles = (theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  affiliatedIcon: {
    fontSize: 14,
    verticalAlign: 'text-top',
    color: theme.palette.secondary.main,
  },
});

class FavoriteOrganizationsPage extends React.Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        label: '',
        key: 'affiliated',
        style: { width: 14 },
        sortable: false,
        showFilter: false,
        format: (affiliated) => {
          if (affiliated?.length > 0) {
            return (
              <Tooltip
                placement='bottom-start'
                title={
                  <Typography
                    style={{
                      fontSize: 13,
                      fontWeight: 200,
                    }}
                  >
                    Affiliated Organization
                  </Typography>
                }
              >
                <div
                  style={{
                    width: 14,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Icon className={props.classes.affiliatedIcon}>hub</Icon>
                </div>
              </Tooltip>
            );
          }
          return <></>;
        },
      },
      {
        label: 'Name',
        key: 'name',
        fulltext: true,
      },
      {
        label: 'Type',
        key: 'facility_type',
      },
      {
        label: 'NPI',
        key: 'npi',
        style: { minWidth: 140, verticalAlign: 'top' },
      },
      {
        label: 'City',
        key: 'primary_location.city',
        fulltext: true,
      },
      {
        label: 'State',
        key: 'primary_location.state',
        style: { width: 50 },
        filterExact: true,
        autoCapitalize: true,
      },
    ];


    this.state = {
      query: {
        order: [['name', 'ASC']],
        include: [
          {
            attributes: ['id', 'city', 'state'],
            association: 'primary_location',
          },
          {
            attributes: ['id'],
            association: 'affiliated',
            required: false,
            through: { attributes: [] }, // don't need the join table data
            where: {
              id: props.clientId,
            },
          },
        ],
        limit: 25,
        offset: 0,
      },
      addDialogOpen: false,
    };
  }

  componentDidMount() {
    this.fetchData();
  }


  componentDidUpdate(prevProps) {
    if (this.props.clientId !== prevProps.clientId
      || this.props.userId !== prevProps.userId) {
      this.fetchData();
    }
  }

  fetchData() {
    if (!StoreUtil.isLoaded(this.props.favorites)) {
      this.props.dispatch(favoritesByType(
        this.props.clientId,
        this.props.userId,
        'Organizations',
      ));
    }
  }


  render() {
    const { classes } = this.props;

    const { query } = this.state;

    const favorites = this.props.favorites;
    console.info(query);
    console.info(this.columns);
    var favoritesData = [];

    if (favorites.data) {
      for (let i = 0; i < favorites.data.rows.length; i++) {
        favoritesData.push(favorites.data.rows[i].organization);
      }
    }

    return (
      <>
        <OrganizationsTabs tab='favorite_organizations' />
        <div className={classes.container}>

          <DynamicTable
            header='Favorite Organizations'            
            showFilters={false}
            columns={this.columns}
            query={query}
            data={favoritesData}
            onQueryChange={this.onQueryChange}
            getRowHref={(row) => `/organizations/${row.id}`}
          />
        </div>
      </>
    );
  }

  onQueryChange = (query) => {
    this.setState({ query }, this.fetchData.bind(this));
  };
}

FavoriteOrganizationsPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  favorites: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired
};

function mapStateToProps(state) {
  const { favorites } = state;
  const userId = state.user ? state.user.id : null;
  const clientId = state.app.selectedClient;

  console.log(StoreUtil.get(favorites, 'by_type', `${clientId}-${userId}-Organizations`));


  return {
    userId,
    clientId,
    favorites: StoreUtil.get(favorites, 'by_type', `${clientId}-${userId}-Organizations`),
   
  };
}

const styled = withStyles(styles)(FavoriteOrganizationsPage);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as FavoriteOrganizationsPage };
