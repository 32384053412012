import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { listTagNamespacesByClient } from 'store/actions/tagNamespacesActions';
import { StoreUtil } from 'doctivity-shared/utils';
import { withRouter } from 'utils';
import { PropTypes } from 'prop-types';
import { Tags } from 'tags';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Button, Card, CardContent, Typography } from '@mui/material';
import { EditProviderTags } from 'tags'

const styles = (theme) => ({
  editButton: {
    color: theme.palette.secondary.main,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2),
    borderRightStyle: 'solid',
    borderRightWidth: '1px',
    borderColor: theme.palette.tertiary.main,
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
  detailsCard: {
    paddingTop: '0px !important',
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    width: '100%',
   '&:last-child': {
      paddingBottom: 0,
      flex: 1,
    },
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  noTagsAvailable: {
    fontSize: '13px',
    fontStyle: 'italic',
    color: '#9E9E9E',
    fontFamily: 'Roboto',
    width: '100%',
    textAlign: 'center',
    '& > *': {
      marginTop: theme.spacing(2),
    },
    paddingBottom: theme.spacing(2),
  },
  disclaimerContent: {
    color: '#9E9E9E',
    fontStyle: 'italic',
    fontSize: 13,
  },
});

function ProviderTagList({ classes, provider, router }) {
  const tagNamespaces = useSelector((state) => StoreUtil.get(state.tagNamespaces, 'API_TAG_NAMESPACES_LIST_BY_CLIENT')?.data);
  const tagNamespacesAvailable = (tagNamespaces ?? []).length > 0;
  const clientId = useSelector((state) => state.app.selectedClient);
  const [open, setOpen] = useState(false)
  function editProvider() {
    let newTags = {};

    //. loop through the providers.tags field
    provider.tags.forEach((obj) => {
      newTags = {...newTags, [obj.namespace_id]: [obj.id]};
    })
  
    provider.selectTags = newTags;



    setOpen(true)
  }
  const { tags } = provider
  const tagsByNamespace = tags?.reduce((acc, val) => {
    if (acc[val.namespace.name] === undefined) {
      acc[val.namespace.name] = [];
    }
    acc[val.namespace.name].push(val);
    return acc;
  }, {});
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(listTagNamespacesByClient(clientId));
  }, [clientId]);

  return (
    <Card>
      <div className={classes.titleContainer}>
        <Typography component='h2' variant='h6' color='primary'>
          Provider Labels
        </Typography>
        <div className={classes.linksContainer}>
          <Button
            variant='text'
            color='primary'
            onClick={editProvider}
            className={classes.editButton}
            disabled={tagNamespacesAvailable < 1}
          >
            <EditOutlinedIcon className={classes.leftIcon} />
            Edit
          </Button>
        </div>
      </div>
      <CardContent className={classes.detailsCard}>
        {Object.entries(tagsByNamespace)?.map(([namespace, tags]) => (
          <Tags tags={tags} key={namespace} onClick={(id) => {
            router.navigate(`/labels/${id}`);
          }} />
        ))}
        { tagNamespacesAvailable < 1 ? <span className={classes.noTagsAvailable}>
          This client does not currently support provider labelling. Please contact Doctivity support to add label types and labels.
        </span>:undefined}
        {Object.keys(tagsByNamespace).length === 0 && <span className={classes.noTagsAvailable}>
          <Typography className={classes.disclaimerContent}>
            Labels allow you to group providers. Click Edit to browse and add labels.
          </Typography>
          <Typography className={classes.disclaimerContent}>
Contact a Doctivity admin if you’d like a new Label Type or Labels added to your client.
          </Typography>
        </span>}
      </CardContent>
      { open && (
        <EditProviderTags open={open} provider={provider} setOpen={(v) => setOpen(v)} />
      )}
    </Card>
  );
}

ProviderTagList.propTypes = {
  classes: PropTypes.object,
  provider: PropTypes.object,
  router: PropTypes.object.isRequired,
};
const styled = withStyles(styles)(ProviderTagList);
const routed = withRouter(styled);
export { routed as ProviderTagList };
