import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import {
  Typography,
  Card,
  CardContent,
  FormControl,
  TextField,
  IconButton,
} from '@mui/material';
import { GetApp } from '@mui/icons-material';

import { subDays, startOfDay, endOfDay } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers';

import withStyles from '@mui/styles/withStyles';

import { withRouter } from 'utils';
import {
  DynamicTable,
  LoadingView,
} from 'components';
import axiosInstance from 'utils/axiosUtil';
import { CsvUtil, StoreUtil, DateUtil } from 'doctivity-shared/utils';

import { loadAnalytics } from 'store/actions/analyticsActions';
import fileDownload from 'js-file-download';
import moment from 'moment';

const styles = (theme) => ({
  filters: {
    '& > *': {
      marginBottom: theme.spacing(1),
    },
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
  lineBreaks: {
    whiteSpace: 'pre',
  },
  encounterIcon: {
    fontSize: 16,
    color: '#333333',
    marginLeft: 2,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    marginBottom: 10,
  },
  spacer: {
    flex: 1,
  },
  pdfButton: {
    flex: 1,
  },
  pdfIcon: {
    fontSize: 20,
    fontWeight: 200,
  },
  filterSpace: {
    width: 4,
  },
  rangeControl: {
    minWidth: 90,
  },
});

// this controls which columns are displayed and how they are looked up in data
const columns = [
  {
    label: 'Last Name',
    key: 'user.last_name',
    style: { width: 100 },
    hideForMobile: false,
  },
  {
    label: 'First Name',
    key: 'user.first_name',
    style: { width: 100 },
    hideForMobile: false,
  },
  {
    label: 'Created Activity Notes',
    key: 'created_notes',
    style: { width: 150 },
    hideForMobile: false,
  },
  {
    label: 'Unresolved Activity Notes',
    key: 'unresolved_notes',
    style: { width: 150 },
    hideForMobile: true,
  },
  {
    label: 'Last Created',
    key: 'most_recent',
    format: DateUtil.formatDateTimeFromDB,
    style: { width: 150 },
    hideForMobile: true,
  },
  {
    label: 'Tagged Providers',
    key: 'provider_count',
    style: { width: 150 },
    hideForMobile: true,
  },
  {
    label: 'Tagged Contacts',
    key: 'contact_count',
    style: { width: 150 },
    hideForMobile: true,
  },
  {
    label: 'Tagged Locations',
    key: 'location_count',
    style: { width: 150 },
    hideForMobile: true,
  },
];

class ProviderCommentsReportTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: startOfDay(subDays(new Date(), 365)),
      endDate: endOfDay(new Date()),
      query: {
        order: [['created_notes', 'DESC']],
        limit: 25,
        offset: 0,
      },
    };
  }

  componentDidMount() {
    if (StoreUtil.needsLoadNoCache(this.props.commentsReport)) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    // anytime the client changes
    if (this.props.app.selectedClient !== prevProps.app.selectedClient 
      || this.props.dateRange.startDate.getDate() !== prevProps.dateRange.startDate.getDate()
      || this.props.dateRange.endDate.getDate() !== prevProps.dateRange.endDate.getDate()) {
      this.fetchData();
    } else if (StoreUtil.needsLoadMediumCache(this.props.commentsReport)) {
      this.fetchData();
    } else if (
      StoreUtil.hasSavedSinceUpdate(
        this.props.editComment,
        prevProps.editComment
      )
    ) {
      this.fetchData();
    }
  }

  fetchData() {
    const { query } = this.state;
    const { dispatch, clientId } = this.props;

    dispatch(
      loadAnalytics({
        type: 'PROVIDER_COMMENTS_REPORT',
        filter: {
          client_id: clientId,
          comment_date: {
            $gte: DateUtil.formatDateTimeFromDB(this.props.dateRange.startDate),
            $lte: DateUtil.formatDateTimeFromDB(this.props.dateRange.endDate),
          },
        },
        opts: {
          limit: 100,
          offset: 0,
          order: query.order,
        },
      })
    );
  }

  fetchCSVDownload() {
    const { query } = this.state;
    const { clientId } = this.props;
    const { startDate, endDate } = this.props.dateRange;

    axiosInstance
      .post('/Analytics', {
        type: 'PROVIDER_COMMENTS_REPORT',
        filter: {
          client_id: clientId,
          comment_date: {
            $gte: DateUtil.formatDateTimeFromDB(startDate),
            $lte: DateUtil.formatDateTimeFromDB(endDate),
          },
        },
        opts: {
          limit: query.limit,
          offset: query.offset,
          order: query.order,
        },
        format: 'csv',
        headers: {
          'Content-Type': 'text/csv',
        },
      },
      {
        params: {
          selected_client_id: clientId,
        }
      })
      .then((response) => {
        const filename = `doctivity_notes_report_${moment().format(
          'YY_MM_DD'
        )}.csv`;
        try {
          const data = this.formatCSV(response.data);
          fileDownload(data, filename);
        } catch (err) {
          console.error(`Could not format csv for ${filename}`);
          console.error(err);
          fileDownload(response.data, filename);
        }
      });
  }

  formatCSV(data) {
    let csvColumns = CsvUtil.stringToMatrix(data);
    if (csvColumns.length > 0) {
      let firstNameIndex = -1;
      let lastNameIndex = -1;
      for (let index = 0; index < csvColumns[0].length; index++) {
        if (csvColumns[0][index] === 'user.first_name') {
          firstNameIndex = index;
        } else if (csvColumns[0][index] === 'user.last_name') {
          lastNameIndex = index;
        }
      }

      if (lastNameIndex > -1) {
        for (let index = 0; index < csvColumns.length; index++) {
          if (csvColumns[index].length > 2) {
            const tempField = csvColumns[index][2];
            csvColumns[index][2] = csvColumns[index][lastNameIndex];
            csvColumns[index][lastNameIndex] = tempField;
          }
        }
      }

      if (firstNameIndex > -1) {
        for (let index = 0; index < csvColumns.length; index++) {
          if (csvColumns[index].length > 1) {
            const tempField = csvColumns[index][1];
            csvColumns[index][1] = csvColumns[index][firstNameIndex];
            csvColumns[index][firstNameIndex] = tempField;
          }
        }
      }
    }

    return CsvUtil.matrixToString(csvColumns);
  }

  render() {
    const { commentsReport, classes } = this.props;

    const { query } = this.state;

    if (!StoreUtil.isLoaded(commentsReport)) {
      return <LoadingView />;
    }

    return (
      <Card>
        <CardContent>
          <div className={classes.header}>
            <Typography component='h2' variant='h6' color='primary'>
              Activity Notes Report
            </Typography>
            <div className={classes.pdfButton}>
              <IconButton
                onClick={() => {
                  this.fetchCSVDownload();
                }}
                size='large'
              >
                <GetApp className={classes.pdfIcon} />
              </IconButton>
            </div>
            <div className={classes.spacer} />
          </div>

          <div className={classes.filters}>
            <FormControl className={classes.rangeControl}>
              <DatePicker
                renderInput={(params) => <TextField {...params} />}
                label='From'
                name='from'
                value={this.props.dateRange.startDate}
                maxDate={this.props.dateRange.endDate}
                onChange={this.onChangeStartDate}
                format={DateUtil.LDML_DISPLAY_FORMAT}
              />
            </FormControl>
            <div className={classes.filterSpace} />
            <FormControl className={classes.rangeControl}>
              <DatePicker
                renderInput={(params) => <TextField {...params} />}
                label='To'
                value={this.props.dateRange.endDate}
                minDate={this.props.dateRange.startDate}
                maxDate={new Date()}
                onChange={this.onChangeEndDate}
                name='to'
                format={DateUtil.LDML_DISPLAY_FORMAT}
              />
            </FormControl>
          </div>

          <DynamicTable
            idKey='user_id'
            columns={columns} // providers.length > 0 ? columns.slice(1) :
            data={commentsReport}
            showFilters={false}
            ContainerType={React.Fragment}
            query={query}
            onQueryChange={this.onQueryChange}
            onRowClick={this.onRowClick}
            rowClasses={classes}
          />
        </CardContent>
      </Card>
    );
  }

  onQueryChange = (query) => {
    this.setState({ query }, this.fetchData);
  };

  onRowClick = (row) => {
    if (row.providers && row.providers.length > 0) {
      this.props.router.navigate(`/providers/${row.providers[0].id}/notes`);
    } else if (row.contacts && row.contacts.length > 0) {
      this.props.router.navigate(`/contacts/${row.contacts[0].id}`);
    }
  };

  onChangeStartDate = (newDate) => {
    this.props.onChangeStartDate(newDate);
  };

  onChangeEndDate = (newDate) => {
    this.props.onChangeEndDate(newDate);
  };
}

ProviderCommentsReportTable.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  clientId: PropTypes.number.isRequired,
  router: PropTypes.object.isRequired,
  app: PropTypes.object,
  user: PropTypes.object,
  editComment: PropTypes.object,
  commentsReport: PropTypes.object.isRequired,
  dateRange: PropTypes.object,
  onChangeStartDate: PropTypes.func,
  onChangeEndDate: PropTypes.func,
};

function mapStateToProps(state) {
  const { app, analytics, user, providerComments } = state;

  return {
    app,
    user,
    clientId: app.selectedClient,
    editComment: StoreUtil.get(providerComments, StoreUtil.COMMON_EDIT_ITEM),
    commentsReport: StoreUtil.get(analytics, 'PROVIDER_COMMENTS_REPORT'),
  };
}

const styled = withStyles(styles)(ProviderCommentsReportTable);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as ProviderCommentsReportTable };
