import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import {
  Box,
  Icon,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Grid,
} from '@mui/material';
import { withIsMobile, withRouter } from 'utils';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { DashboardProvidersPage } from './DashboardProvidersPage.js';
import { DashboardLocationsPage } from './DashboardLocationsPage.js';
import { DashboardContactsPage } from './DashboardContactsPage.js';
import { ActivityNotesSummaryChart } from './ActivityNotesSummaryChart';
import { ActivityNotes } from './ActivityNotes';
import { UserSavedCriteria } from './UserSavedCriteria';

const styles = (theme) => ({
  darkBackgroundSelect: {
    height: '58px',
    fontSize: '20px',
    '&.MuiInput-root:after, &.MuiInput-root:before, &.MuiInput-root:hover': {
      borderBottom: 'none !important',
    },
    '&.MuiInput-root': {
      [theme.breakpoints.down('md')]: {
        fontSize: 14,
      },
      marginBottom: '-3px',
      '& fieldset': {
        borderColor: theme.palette.tertiary.main,
        color: 'white',
      },
      '& svg': {
        color: 'white',
      },
      '&:hover fieldset': {
        borderColor: theme.palette.tertiary.main,
        color: 'white',
      },
      '&:hover svg': {
        color: 'white',
      },
    },
    color: 'white',
    '& .MuiInputBase-input': {
      '&:focus': {
        backgroundColor: 'transparent',
      },
      borderRadius: 4,
      position: 'relative',
      color: 'white',
    },
  },
  container: {
    backgroundColor: '#fafafa',
    fontFamily: 'Roboto',
    minHeight: '80vw',
    '& a': {
      color: '#214E56',
      '&:visited': {
        color: '#214E56',
      },
    },
  },
  containerHeaderBackground: {
    paddingBottom: 255,
    backgroundColor: '#214E56',
  },
  tab: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    fontSize: 14,
    textTransform: 'none',
    alignItems: 'start',
    paddingTop: 15,
    paddingInline: 30,
    [theme.breakpoints.down('md')]: {
      paddingInline: 10,
      fontSize: 12,
    },
  },
  greetingContainer: {
    display: 'flex',
    color: '#FFF',
    [theme.breakpoints.down('md')]: {
      marginLeft: '30px',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  greetingText: {
    fontSize: '24px',
    alignContent: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
  },
  morningIcon: {
    color: '#D8B807',
    fontSize: '36px',
    marginTop: '8px',
    marginRight: '5px',
    [theme.breakpoints.down('md')]: {
      fontSize: '15px',
      marginTop: '2px',
    },
  },
  eveningIcon: {
    color: '#93F0FF',
    fontSize: '36px',
    marginTop: '8px',
    marginRight: '5px',
    [theme.breakpoints.down('md')]: {
      fontSize: '15px',
      marginTop: '2px',
    },
  },
});

class DashboardV2Page extends React.Component {
  constructor(props) {
    super(props);
    const collection = localStorage.getItem('dashboard-collection');
    this.state = {
      collection: collection ?? 'favorite',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.collection !== prevState.collection) {
      localStorage.setItem('dashboard-collection', this.state.collection);
    }
  }

  render() {
    const { classes, isMobile, user } = this.props;
    const { collection } = this.state;
    // const missedData = StoreUtil.getData(missedMedications);
    // const colSize = this.props.isMobile ? 12 : 4;

    const currentTab = this.props.tab || 'providers';

    let today = new Date();
    let curHr = today.getHours();
    let time = null;

    if (curHr < 12) {
      time = 'morning';
    } else if (curHr < 18) {
      time = 'afternoon';
    } else {
      time = 'evening';
    }

    let greetingIcon = null;
    if (curHr < 18) {
      greetingIcon = <Icon className={classes.morningIcon}>light_mode</Icon>;
    } else {
      greetingIcon = <Icon className={classes.eveningIcon}>dark_mode</Icon>;
    }

    return (
      <>
        <Helmet defer={false}>
          <title>Doctivity Dashboard</title>
        </Helmet>
        <div className={classes.container}>
          <div className={classes.containerHeaderBackground}></div>
          <div
            style={{
              marginLeft: !isMobile ? 150 : 0,
              marginRight: !isMobile ? 25 : 0,
              marginTop: -275,
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'baseline',
                paddingTop: isMobile ? 30 : 50,
                paddingBottom: isMobile ? 8 : 30,
              }}
            >
              <Box className={classes.greetingContainer}>
                <Typography component='span'>{greetingIcon}</Typography>{' '}
                <Typography component='span' className={classes.greetingText}>
                  Good {time}, {`${user.first_name} ${user.last_name}`}!
                </Typography>
              </Box>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                backgroundColor: '#85A5A8',
              }}
            >
              <Tabs
                value={currentTab}
                onChange={this.onTabChange}
                TabIndicatorProps={{
                  style: { display: 'none' },
                }}
                scrollButtons={false}
                variant='scrollable'
                sx={{
                  height: 60,
                  backgroundColor: '85A5A8',
                  '.Mui-selected': {
                    backgroundColor: 'white',
                  },
                  paddingTop: 1,
                }}
                style={{
                  alignItems: 'center',
                }}
              >
                <Tab
                  label='Providers'
                  disableRipple
                  icon={<Icon>people</Icon>}
                  iconPosition='start'
                  value='providers'
                  className={classes.tab}
                  style={{
                    color: currentTab === 'providers' ? '#214E56' : 'white',
                    display: 'flex',
                    alignItems: 'center',
                    paddingTop: 0,
                  }}
                />
                <Tab
                  label='Locations'
                  disableRipple
                  icon={<Icon>place</Icon>}
                  iconPosition='start'
                  value='locations'
                  className={classes.tab}
                  style={{
                    color: currentTab === 'locations' ? '#214E56' : 'white',
                    display: 'flex',
                    alignItems: 'center',
                    paddingTop: 0,
                  }}
                />
                <Tab
                  label='Contacts'
                  disableRipple
                  icon={<Icon>contact_phone</Icon>}
                  iconPosition='start'
                  value='contacts'
                  className={classes.tab}
                  style={{
                    color: currentTab === 'contacts' ? '#214E56' : 'white',
                    display: 'flex',
                    alignItems: 'center',
                    paddingTop: 0,
                  }}
                />
              </Tabs>
              <div
                style={{
                  display: 'flex',
                  marginRight: 'auto',
                  paddingLeft: isMobile ? 15 : 31,
                  paddingRight: isMobile ? 15 : 31,
                  textWrap: 'nowrap',
                  fontSize: isMobile ? 14 : 20,
                  color: 'white',
                  alignItems: 'center',
                }}
              >
                <FormControl
                  variant='outlined'
                  sx={{ m: 1, minWidth: isMobile ? 75 : 90 }}
                >
                  <Select
                    labelId='collection-label'
                    id='collection'
                    value={collection}
                    variant='standard'
                    className={classes.darkBackgroundSelect}
                    style={{
                      fontSize: `${isMobile ? 14 : 20}px !important`,
                    }}
                    onChange={({ target: { value } }) =>
                      value !== null && this.setState({ collection: value })}
                  >
                    <MenuItem value={'favorite'}>Your Favorite</MenuItem>
                    <MenuItem value={'recent'}>Your Recent</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>

            <div
              style={{
                marginTop: -10,
                paddingTop: 10,
                paddingBottom: 10,
                backgroundColor: 'white',
                borderRadius: 10,
                color: 'rgba(0, 0, 0, 0.87)',
                transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                boxShadow:
                  '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
              }}
            >
              {currentTab === 'providers' && (
                <DashboardProvidersPage collection={collection} />
              )}
              {currentTab === 'locations' && (
                <DashboardLocationsPage collection={collection} />
              )}
              {currentTab === 'contacts' && (
                <DashboardContactsPage collection={collection} />
              )}
            </div>
            <Box mt={3}>
              <Grid container spacing={2}>
                <Grid item xs={12} xl={6}>
                  <ActivityNotes />
                </Grid>
                <Grid item xs={12} xl={6}>
                  <ActivityNotesSummaryChart />
                </Grid>
              </Grid>
            </Box>
            {user.is_admin && <Box mt={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <UserSavedCriteria />
                </Grid>
              </Grid>
            </Box>}
          </div>
        </div>
      </>
    );
  }

  onTabChange = (_, newValue) => {
    this.props.router.navigate(`/dashboard/${newValue}`);
  };
}

DashboardV2Page.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  app: PropTypes.object,
  user: PropTypes.object,
  router: PropTypes.object.isRequired,
  tab: PropTypes.string,
};

function mapStateToProps(state) {
  const { app, user } = state;

  return {
    app,
    user,
  };
}

const styled = withStyles(styles)(withIsMobile(DashboardV2Page));
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as DashboardV2Page };
