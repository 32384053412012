import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { StoreUtil, PatientType } from 'doctivity-shared/utils';
import axiosInstance from 'utils/axiosUtil';
import { ClaimsPageQueryParams } from 'params';
import { upsertSavedCriteria } from 'store/actions/savedCriteriaActions';
import { MarketsUtil } from 'utils';
import { useSearchParams } from 'react-router-dom';
import { ObjectUtil } from 'utils';

const ReportContext = React.createContext();

const ReportParameters = {
  claimsType: {
    type: 'enum',
    values: ['providers', 'organizations'],
    param: ClaimsPageQueryParams.CLAIMS_TYPE,
  },
  report: {
    type: 'string',
    param: ClaimsPageQueryParams.REPORT,
  },
  affiliated_client_id: {
    type: 'number',
    param: ClaimsPageQueryParams.AFFILIATED_CLIENT_ID,
  },
  before: {
    type: 'boolean',
    param: ClaimsPageQueryParams.CLAIMS_DATE,
  },
  isMarket: {
    type: 'boolean',
    param: ClaimsPageQueryParams.IS_MARKET,
  },
  market: {
    type: 'number',
    param: ClaimsPageQueryParams.MARKET,
  },
  tag: {
    type: 'number',
    param: ClaimsPageQueryParams.TAG,
  },
  serviceline: {
    type: 'number',
    isArray: true,
    param: ClaimsPageQueryParams.SERVICELINE,
  },
  patientType: {
    type: 'string',
    param: ClaimsPageQueryParams.PATIENT_TYPE,
  },
  groupByServicelines: {
    type: 'boolean',
    param: ClaimsPageQueryParams.GROUP_BY_SERVICELINE,
  },
  payors: {
    type: 'string',
    isArray: true,
    param: ClaimsPageQueryParams.PAYORS,
  },
  taxonomies: {
    type: 'string',
    isArray: true,
    param: ClaimsPageQueryParams.TAXONOMIES,
  },
  affiliated: {
    type: 'boolean',
    param: ClaimsPageQueryParams.AFFILIATION,
  },
  codes: {
    type: 'array',
    param: ClaimsPageQueryParams.CODES,
  },
  codesType: {
    type: 'string',
    param: ClaimsPageQueryParams.CODESTYPE,
  },
  claimSetting: {
    type: 'number',
    param: ClaimsPageQueryParams.CARE_SETTING,
  },
};
const IGNORED_PARAMS = new Set(['q', 't', 'client', 'selected_client_id', 'report']);
const hasCustomParams = (searchParams) => Array.from(searchParams.keys()).find((k) => !IGNORED_PARAMS.has(k)) !== undefined;

export function useReportCriteria(report) {
  const savedCriteriaInterval = 3;
  /**
   * @typedef {Object} ReportState
   * @property {boolean} loadedFromSaved
   * @property {boolean} loadedFromQuery
   * @property {boolean} ready
   * @property {string} report
   * @property {string} affiliation
   * @property {string} claimsType
   * @property {boolean} before
   * @property {boolean} isMarket
   * @property {number} market
   * @property {number} tag
   * @property {string} serviceline
   * @property {PatientType} patientType
   * @property {boolean} groupByServicelines
   * @property {number[]} payors
   * @property {number[]} taxonomies
   * @property {number} affiliated_client_id
   * @property {number} codesType
   * @property {number} codes
   */
  const [searchParams, setSearchParams] = useSearchParams();
  /**
   * @type {ReturnType<typeof useState<ReportState>>}
   */
  const [state, setState] = useState({
    loadedFromSaved: false,
    loadedFromQuery: false,
    loading: false,
    ready: false,
    report: !!report ? report : searchParams.get(ClaimsPageQueryParams.REPORT.name),
    affiliation: 'all',
    claimsType: 'providers',
    before: false,
    isMarket: true,
    patientType: PatientType.ALL_PATIENTS,
    groupByServicelines: false,
    claimSetting: 0
  });
  const [lastTimeCriteriaChanged, setLastTimeCriteriaChanged] = useState(null);
  const [lastLoadTime, setLastLoadTime] = useState(null);
  const dispatch = useDispatch();
  const userId = useSelector((s) => s.user)?.id;
  const clientId = useSelector((s) => s.app.selectedClient);
  const markets = useSelector((state) => MarketsUtil.getMarkets(state));
  const [shouldUseQuery] = useState(hasCustomParams(searchParams));
  const [currentParams, setCurrentParams] = useState('');
  const marketsLoaded = StoreUtil.isLoaded(markets);
  
  const internal = {
    saveCriteria: () => {
      let criteriaArray = [];
      let hasMarketOrTag = false;
      Object.keys(ReportParameters).forEach((key) => {
        const field = ReportParameters[key];
        const value = ObjectUtil.getPropertyByString(state, key);
        if (field.param.name === ClaimsPageQueryParams.MARKET.accessor ||
          field.param.name === ClaimsPageQueryParams.TAG.accessor
        ) {
          hasMarketOrTag = hasMarketOrTag || !!value;
        }
        criteriaArray.push({
          field: field.param.name,
          value: field.toQueryParam ? field.toQueryParam(value) : value,
        });
      });
      if (!hasMarketOrTag) {
        console.log('no market or tag on report', state);
        return
      }
      dispatch(
        upsertSavedCriteria({
          criteria_type_id: 1,
          criteria_json: JSON.stringify({
            criteria: criteriaArray,
          }),
          client_id: clientId,
          user_id: userId,
        }),
      );
      setLastTimeCriteriaChanged(null);
    },
    setQueryParams: (state) => {
      const allParams = Object.values(ReportParameters);
      const newParams = {};
      allParams.forEach((rp) => {
        const value = ObjectUtil.getPropertyByString(state, rp.param.accessor);
        if (value) {
          if (Array.isArray(value)) {
            if (rp.toQueryParam) {
              newParams[rp.param.name] = rp.toQueryParam(value);
            } else {
              newParams[rp.param.name] = value.map((v) => v.id ?? v).join(',');
            }
          } else if (typeof value === 'object') {
            newParams[rp.param.name] = value?.id?.toLowerCase
              ? value.id.toLowerCase()
              : value?.id;
          } else {
            newParams[rp.param.name] = value.toLowerCase
              ? value.toLowerCase()
              : rp.toQueryParam?.(value) ?? value;
          }
        } else if (value === false || value === 0) {
          newParams[rp.param.name] = value.toLowerCase
            ? value.toLowerCase()
            : value;
        } else {
          delete newParams[rp.param.name];
        }
      });
      newParams['client'] = searchParams.get('client');
      console.log('setting query string', JSON.stringify(newParams));
      const params = new URLSearchParams(searchParams)
      Object.keys(newParams).forEach((key) => {
        if (newParams[key] === undefined || newParams[key] === null || newParams[key] === '') {
          params.delete(key);
        } else {
          params.set(key, newParams[key]);
        }
      });
      setSearchParams(params);
      setCurrentParams(params.toString());
    },
    loadFromLastSaved: async () => {
      const { loading, loadedFromQuery, report } = state;
      if (loading || loadedFromQuery || !report) return;
      if (lastLoadTime) return
      setLastLoadTime(new Date().getTime());
      setState((state) => ({ ...state, loading: true }));
      try {
        console.log('load last saved...', state);
        const savedCriteria = await axiosInstance.get('/UsersSavedCriteria', {
          params: {
            opts: {
              clientId: clientId,
              criteriaTypeId: 1,
            },
          },
        });
        console.log('saved criteria', savedCriteria);
        if (savedCriteria.data && savedCriteria.data.length > 0) {
          const criteria = savedCriteria.data.find((d) => {
            try {
              if (typeof d.criteria_json === 'string') { 
                JSON.parse(d.criteria_json);
              }
              return true;
            } catch (e) {
              return false;
            }
          })?.criteria_json;

          let mostRecent =
            (typeof criteria === 'string' ? JSON.parse(criteria) : criteria)
              ?.criteria ?? {};

          let mostRecentAffiliated = mostRecent.find(
            (c) => c.field === 'affiliation' && !!c.value,
          );
          let mostRecentBefore = mostRecent.find((c) => c.field === 'before' && !!c.value);
          let mostRecentServiceLine = mostRecent.find(
            (c) => c.field === 'serviceline' && !!c.value,
          );
          let mostRecentMarket = mostRecent.find((c) => c.field === 'market' && !!c.value);
          let mostRecentTag = mostRecent.find((c) => c.field === 'tag' && !!c.value);
          let mostRecentIsMarket = mostRecent.find(
            (c) => c.field === 'isMarket' && !!c.value,
          );
          let mostRecentPayors = mostRecent.find((c) => c.field === 'payors' && !!c.value);
          let mostRecentGroupBy = mostRecent.find(
            (c) => c.field === 'groubByServicelines' && !!c.value,
          );
          let mostRecentTaxonomy = mostRecent.find(
            (c) => c.field === 'specialties' && c.value?.length > 0,
          );
          let mostRecentClaimsType =
            mostRecent.find((c) => c.field === 'claimsType' && !!c.value)?.value ??
            ReportParameters.claimsType.values[0];

          const payors = (
            Array.isArray(mostRecentPayors?.value)
              ? mostRecentPayors.value
              : (mostRecentPayors?.value ?? '').split(',')
          ).filter((v) => v.length > 0);

          const mostRecentPatientType = mostRecent.find(
            (c) => c.field === 'patientType' && !!c.value,
          );

          const newState = {
            ...state,
            report: !!report ? report : mostRecent.find((c) => c.field === 'report' && !!c.value)?.value,
            loadedFromSaved: true,
            ready: true,
            market: mostRecentMarket?.value,
            tag: mostRecentTag?.value,
            isMarket: mostRecentIsMarket?.value ?? true,
            serviceline: mostRecentServiceLine?.value ?? 'all',
            before: mostRecentBefore?.value ?? false,
            affiliation: mostRecentAffiliated?.value ?? 'all',
            groupByServicelines: mostRecentGroupBy?.value ?? false,
            taxonomies: mostRecentTaxonomy?.value ?? [],
            payors,
            patientType:
              mostRecentPatientType?.value?.toUpperCase() ??
              PatientType.ALL_PATIENTS,
            claimsType:
              ReportParameters.claimsType.values.find(
                (v) => v === mostRecentClaimsType,
              ) ?? ReportParameters.claimsType.values[0],
            codes: mostRecent.find((c) => c.field === 'codes' && !!c.value)?.value,
            codesType: mostRecent.find((c) => c.field === 'codesType' && !!c.value)?.value,
            claimSetting: mostRecent.find((c) => c.field === 'claimSetting' && !!c.value)?.value,
          };
          console.log('set state from recent search', newState);
          setState(newState);
          internal.setQueryParams(newState);
        } else {
          setState((state) => ({ report, ...state, ready: true, loadedFromSaved: true, }));
        }
      } finally {
        setState((state) => ({ ...state, loading: false }));
      }
    },
  };
  const service = {
    getCriteria: useCallback(() => {
      const queryClaimsDate = searchParams.get(
        ClaimsPageQueryParams.CLAIMS_DATE.name,
      );
      const queryMarket = searchParams.get(ClaimsPageQueryParams.MARKET.name);
      let queryServiceLine = searchParams.get(
        ClaimsPageQueryParams.SERVICELINE.name,
      );
      const queryPatientType = searchParams.get(
        ClaimsPageQueryParams.PATIENT_TYPE.name,
      );

      const queryAffiliation = searchParams.get(
        ClaimsPageQueryParams.AFFILIATION.name,
      );
      if (/,/.test(queryServiceLine)) {
        queryServiceLine = queryServiceLine.split(',');
      } else if (queryServiceLine === 'all') {
        queryServiceLine = [];
      } else {
        queryServiceLine = queryServiceLine ? [queryServiceLine] : [];
      }
      queryServiceLine = queryServiceLine.map((sl) => parseInt(sl, 10));

      const queryPayors = searchParams.get(ClaimsPageQueryParams.PAYORS.name);

      const queryIsMarket = searchParams.get('isMarket');
      const queryGroupBy = searchParams.get('groupByServicelines');
      const queryTag = searchParams.get('tag');
      const codes = searchParams.get('codes')?.split(',');
      const codesType = searchParams.get('codesType');
      const queryClaimsType = searchParams.get('claimsType');
      const queryClaimSetting = searchParams.get('claimSetting');

      if (marketsLoaded) {
        let querySelectedMarket = null;
        const marketsData = StoreUtil.getData(markets);
        if (marketsData && marketsData.length > 0) {
          querySelectedMarket = marketsData.find(
            (market) => market.id === parseInt(queryMarket, 10),
          );
        }
        if (!querySelectedMarket && marketsData.length > 0) {
          querySelectedMarket = marketsData[0];
        }

        console.log('get state from query string', searchParams.toString());
        const state = {
          report: !!report ? report : searchParams.get(ClaimsPageQueryParams.REPORT.name),
          affiliated_client_id: clientId,
          before: String(queryClaimsDate).toLowerCase() == 'true' ?? false,
          isMarket: queryIsMarket === 'true' || !queryTag,
          market: querySelectedMarket ? querySelectedMarket.id : undefined,
          tag: queryTag ? parseInt(queryTag, 10) : undefined,
          serviceline: queryServiceLine,
          patientType:
            queryPatientType?.toUpperCase() ?? PatientType.ALL_PATIENTS,
          affiliation: queryAffiliation ?? 'all',
          groupByServicelines: queryGroupBy === 'true',
          taxonomies:
            searchParams
              .get(ClaimsPageQueryParams.TAXONOMIES.name)
              ?.split(',')
              ?.filter((t) => t.length > 0) ?? [],
          payors: (
            queryPayors?.split(',').filter((p) => p?.length > 0) ?? []
          ).map((p) => parseInt(p, 10)),
          claimsType: queryClaimsType,
          codes,
          codesType,
          claimSetting: queryClaimSetting && !Number.isNaN(queryClaimSetting) ? parseInt(queryClaimSetting) : undefined,
        };
        console.log('state: ', state);
        return state;
      }
      return undefined;
    }, [searchParams, markets]),
    current: state,
    updateCriteria: (newState) => {
      setState((state) => {
        const next = { report, ...state, ...newState }
        internal.setQueryParams(next);
        return next;
      });
      setLastTimeCriteriaChanged(new Date().getTime());
    },
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (
        lastTimeCriteriaChanged !== null &&
        (new Date().getTime() - lastTimeCriteriaChanged) / 1000 >
          savedCriteriaInterval
      ) {
        internal.saveCriteria();
        setLastTimeCriteriaChanged(null);
      }
    }, savedCriteriaInterval * 1000);
    return () => clearInterval(interval);
  }, [state, lastTimeCriteriaChanged]);

  useEffect(() => {
    if (!marketsLoaded || state.loading) {
      return;
    }
    if (marketsLoaded && !(state.loadedFromSaved || state.loadedFromQuery)) {
      console.log('shouldUseQuery', shouldUseQuery, document.location.search) 
      if (shouldUseQuery) {
        setState((state) => ({
          ...state, 
          loadedFromQuery: true,
          loading: false,
          ready: true,
          ...service.getCriteria()
        }));
      } else {
        setState((state) => ({ ...state, loading: true, loadedFromSaved: true }));
        setTimeout(() => internal.loadFromLastSaved(), 0);
      }
    }
  }, [marketsLoaded, state, shouldUseQuery]);

  useEffect(() => {
    if (state.ready && (state.loadedFromSaved || state.loadedFromQuery) && currentParams !== searchParams.toString() && hasCustomParams(searchParams)) {
    setState((state) => {
       const next = { report, ...state, ...service.getCriteria() }
       setCurrentParams(searchParams.toString());
       return next;
     })
    }
  }, [searchParams, state]);

  return service;
}

export function withReportCriteria(report, Component) {
  return function WrappedReportCriteriaComponent(props) {
    const { getCriteria, updateCriteria, current } = useReportCriteria(report);
    return (
      <ReportContext.Provider value={{ getCriteria, updateCriteria, current }}>
        <ReportContext.Consumer>
          {({ getCriteria, updateCriteria, current }) => (
            <Component
              {...props}
              getCriteria={getCriteria}
              updateCriteria={updateCriteria}
              currentCriteria={current}
            />
          )}
        </ReportContext.Consumer>
      </ReportContext.Provider>
    );
  };
}
