import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

import {
  StoreUtil,
} from 'doctivity-shared/utils';

import {
  DynamicTable,
  AffiliatedIcon,
} from 'components';

import {
  queryOrganizations,
} from 'store/actions/organizationsActions';

// this controls which columns are displayed and how they are looked up in data
const columns = [
  {
    label: '',
    sortable: false,
    showFilter: false,
    key: 'clients.id',
    type: 'bool',
    format: (_, row) =>{
      return row?.affiliated?.length > 0 ? <AffiliatedIcon /> : ''
    },
    style: { width: 16 },
  },
  {
    label: 'Name',
    key: 'name',
    fulltext: true,
  },
  {
    label: 'Type',
    key: 'facility_type',
  },
  {
    label: 'NPI',
    key: 'npi',
  },
  {
    label: 'City',
    key: 'primary_location.city',
    fulltext: true,
  },
  {
    label: 'State',
    key: 'primary_location.state',
    style: { width: 50 },
    filterExact: true,
    autoCapitalize: true,
  },
];

const styles = (theme) => ({
  filters: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  instructions: {
    fontSize: 14,
    fontWeight: 300,
    flex: 1,
    color: theme.palette.primary.main,
  },
});

class FindOrganizationDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: {
        where: { },
        include: [
          {
            association: 'primary_location',
            attributes: ['id', 'city', 'state'],
          },
          {
            association: 'affiliated',
            attributes: ['id'],
            required: false,
            duplicating: false,
            through: { attributes: [],
            where: {
              client_id: props.clientId,
            }},
          },
        ],
        limit: 10,
        offset: 0,
        order: [
          ['id', 'ASC'],
        ],
      },
    };
  }

  componentDidMount() {
    const {
      organizations,
    } = this.props;

    if (StoreUtil.needsLoadNoCache(organizations)) {
      this.fetchData();
    }
  }

  onQueryChange = (query) => {
    this.setState({ query }, this.fetchData.bind(this));
  };

  fetchData() {
    this.props.dispatch(queryOrganizations(this.state.query));
  }

  render() {
    const {
      classes,
      organizations,
      open,
    } = this.props;

    const {
      query,
    } = this.state;
    const tableProps = {}
    if (this.props.onSaveMultiSelect) {
      tableProps.multiSelectedRows = this.props.selectedRows;
      tableProps.onMultiSelect = this.props.onMultiSelect;
    } else {
      tableProps.onRowClick = this.onRowClick;
    }
    return (
      <Dialog
        onClose={this.onCancel}
        aria-labelledby='simple-dialog-title'
        open={open}
        fullWidth
        maxWidth='lg'
        scroll='paper'
      >
        <DialogTitle>
          Find an Organization
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.filters}>
            <Typography className={classes.instructions}>
              Filter and select the {this.props.onMultiSelect ? 'organizations' : 'organization'}
            </Typography>
          </div>
          <DynamicTable
            columns={columns}
            query={query}
            data={organizations}
            noDataMessage='No organizations found.'
            onQueryChange={this.onQueryChange}
            ContainerType={React.Fragment}
            {...tableProps}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onCancel} color='primary'>
            Cancel
          </Button>
          {this.props.onMultiSelect && (
            <Button
              onClick={this.props.onSaveMultiSelect}
              variant='contained'
              color='primary'
            >
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }

  onCancel = () => {
    const {
      onSelect,
    } = this.props;

    if (onSelect) {
      onSelect(null);
    }
  };

  onRowClick = (row) => {
    const {
      onSelect,
    } = this.props;

    if (onSelect) {
      onSelect(row);
    }
  };
}

FindOrganizationDialog.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  clientId: PropTypes.number,
  organizations: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  onMultiSelect: PropTypes.arrayOf(PropTypes.object),
  onSaveMultiSelect: PropTypes.func,
  selectedRows: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const {
    organizations,
  } = state;

  return {
    organizations: StoreUtil.get(organizations, StoreUtil.COMMON_TABLE),
    clientId: state.app.selectedClient
  };
}

const styled = withStyles(styles)(FindOrganizationDialog);
const connected = connect(mapStateToProps)(styled);
export { connected as FindOrganizationDialog };
