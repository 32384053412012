import React, { useState, useEffect, useCallback } from 'react';
import withStyles from '@mui/styles/withStyles';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { withIsMobile } from 'utils';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
} from '@mui/material';
import {
  LoadingView,
} from 'components';
//import { TagsDropdowns } from 'tags';
import {
  upsertProviderTag,
  deleteProviderTag,
} from 'store/actions/providerTagsActions';
import { listTagNamespacesByClient } from 'store/actions/tagNamespacesActions';
import { loadProvider } from 'store/actions/providersActions'
import { StoreUtil } from 'doctivity-shared/utils';
import { TagsUtil } from '../utils';

//const styles = () => ({});
const styles = (theme) => ({
 
  textField: {
    width: '100%',
  },

  typeContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    flexWrap: 'wrap',

    justifyContent: 'space-right',
    '& > *': {
      marginBottom: theme.spacing(1),
    },
  },
  typeSpacer: {
    width: theme.spacing(2),
  },
  spaceLeft: {
    marginLeft: theme.spacing(1),
  },
 
  modalContent: {
    padding: theme.spacing(1),
  },
  tagSelector: {
    flexBasis: '*',
    minWidth: '24%'
  },
  fullTagSelector: {
    flexBasis: '100%',
  },

});





function EditProviderTags({ open, setOpen, provider, dispatch, tagNamespaces, clientId, classes, isMobile }) {

  const [selectedTags, setSelectedTags] = useState({});
  
  const onTagSelectChange = (event, namespace) => {
    let selected = event.target.value;
    if (!namespace.activity_notes_multiple) {
      selected = [selected];
    }
    console.log(selectedTags);
 
    let newTags = {...selectedTags, [namespace.id]: selected};
    
    setSelectedTags(
        newTags
    );

  };
  
  
  
  const onCancel = useCallback(() => {
    setSelectedTags([]);
    setOpen(false);
  }, []);
  const onSaveMultiSelect = useCallback(() => {
    const tagIds = new Set(provider.tags.map((t) => t.id));
    //const selectedTagIdsOld = new Set(selectedTags.map((t) => t.id));
    
    let selectedTagIds = [];

    const namespaces = Object.values(selectedTags);
    if (namespaces) {
      namespaces.forEach((namespace) => {
        selectedTagIds.push(...namespace);
      });
    }
    
    const addedTags = selectedTagIds.filter((st) => !tagIds.has(st));
    const removedTags = provider.tags.filter((t) => !selectedTagIds.includes(t.id));

    // console.error('adding', addedTags, 'removing', removedTags);
    if (addedTags.length > 0) {
      dispatch(
        upsertProviderTag(
          addedTags.map((t) => ({ tag_id: t, provider_id: provider.id })),
          loadProvider(provider.id, clientId),
        ),
      );
    }
    if (removedTags.length > 0) {
      dispatch(
        deleteProviderTag(
          removedTags.map((tag) => ({
            provider_id: provider.id,
            tag_id: tag.id,
          })),
          undefined,
          loadProvider(provider.id, clientId),
        ),
      );
    }
    setOpen(false);
  }, [dispatch, provider, selectedTags, clientId]);
  useEffect(() => {
    if (StoreUtil.needsLoadLongCache(tagNamespaces) || tagNamespaces.clientId !== clientId) {
      dispatch(listTagNamespacesByClient(clientId));
    }
  });

 

 


  useEffect(() => {
    //const selTags = provider?.tags;
    const selTags = provider?.selectTags;
    //if (Array.isArray(selTags) && selTags.length > 0) {
     setSelectedTags(selTags);
    //}
  }, [tagNamespaces, provider, open]);
  //const clearNamespace = useCallback((nsId) => {
    //setSelectedTags(selectedTags.filter((t) => TagsUtil.findNamespaceForTagId(t.id, tagNamespaces)?.id !== nsId));
  //}, [selectedTags]);

  const namespacesWithTagsSorted =
  TagsUtil.getNamespacesByType(
    'providers',
    tagNamespaces,
  ) || [];



  return (
    <Dialog
      onClose={() => setOpen(false)}
      aria-labelledby='simple-dialog-title'
      fullWidth
      fullScreen={isMobile}
      maxWidth='lg'
      open={open}
      scroll='paper'
    >
      <DialogTitle>Edit Provider Labels</DialogTitle>
     
      <DialogContent dividers>
      <div className={classes.modalContent}>
        <div className={classes.typeContainer}>
          {false ? (
            <LoadingView />
          ) : (


            namespacesWithTagsSorted.map((namespace) => (
              <FormControl
                variant='outlined'
                className={
                  !isMobile && namespacesWithTagsSorted.length > 1
                    ? classes.tagSelector
                    : classes.fullTagSelector
                }
                key={namespace.id}
              >
                <InputLabel shrink={true} id={`label-${namespace.id}`}>
                  {`${namespace.name} ${namespace.activity_notes_required ? '*' : ''}`}
                </InputLabel>
                <Select
                  notched={true}
                  labelId={`label-${namespace.id}`}
                  id={`select-${namespace.id}`}
                  displayEmpty={true}
                  value={
                    namespace.activity_notes_multiple
                      ? selectedTags[namespace.id] || []
                      : (selectedTags[namespace.id] &&
                        selectedTags[namespace.id].length > 0 &&
                        selectedTags[namespace.id][0]) ||
                        ''
                  }
                  label={`${namespace.name} ${namespace.activity_notes_required ? '*' : ''}`}
                  onChange={(event) => {
                    onTagSelectChange(event, namespace);
                  }}
                  multiple={namespace.activity_notes_multiple || false}
                  variant='outlined'
                  renderValue={(selected) => {
                    if (
                      namespace.activity_notes_multiple &&
                      selected?.length > 0
                    ) {
                      return selected
                        .map(
                          (id) =>
                            namespace.tags.find((t) => t.id === id).name,
                        )
                        .join(', ');
                    }
                    const tag = namespace.tags.find(
                      (t) => t.id === selected,
                    );
                    if (tag) {
                      return tag.name;
                    }
                    return <em>(None)</em>;
                  }}
                >
                  {!namespace.activity_notes_multiple &&
                    !namespace.activity_notes_required && (
                      <MenuItem value={0} key={0}>
                        <em>(None)</em>
                      </MenuItem>
                    )}
                  {namespace.activity_notes_multiple
                    ? namespace.tags.map((tag) => (
                        <MenuItem value={tag.id} key={tag.id}>
                          <Checkbox
                            checked={
                              selectedTags[namespace.id] !==
                                undefined &&
                                selectedTags[namespace.id].includes(
                                tag.id,
                              )
                            }
                          />
                          <ListItemText primary={tag.name} />
                        </MenuItem>
                      ))
                    : namespace.tags.map((tag) => (
                        <MenuItem value={tag.id} key={tag.id}>
                          {tag.name}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            ))
          )}
        </div>
        </div>
    
        <DialogActions>
          <Button onClick={onCancel} color='primary'>
            Cancel
          </Button>
          <Button
            onClick={onSaveMultiSelect}
            variant='contained'
            color='primary'
          >
            Save
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}



function mapStateToProps(state) {
  return {
    tagNamespaces: StoreUtil.get(state.tagNamespaces, 'API_TAG_NAMESPACES_LIST_BY_CLIENT'),
    clientId: state.app.selectedClient,
  };
}

EditProviderTags.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  dispatch: PropTypes.func,
  classes: PropTypes.object,
  open: PropTypes.bool,
  provider: PropTypes.object,
  setOpen: PropTypes.func,
  tagNamespaces: PropTypes.object.isRequired,
  clientId: PropTypes.number,
};
const styled = withStyles(styles)(withIsMobile(EditProviderTags));
const connected = connect(mapStateToProps)(styled);
export { connected as EditProviderTags };
