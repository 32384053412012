import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { PropTypes } from 'prop-types';
import Chart from 'react-apexcharts';
import moment from 'moment';
import fileDownload from 'js-file-download';
import withStyles from '@mui/styles/withStyles';
import {
  Button,
  Icon,
  Grid,
  Card,
  CardContent,
  Typography,
  Tabs,
  Tab,
  Divider,
  IconButton,
  Tooltip,
} from '@mui/material';
import { PictureAsPdf } from '@mui/icons-material';
import {
  LoadingView,
  OverlineLabel,
  UnderlineData,
  DialogForm,
  DynamicTable,
  ProviderComments,
  ProviderScorecard,
  ClientRevenue,
  TitleTooltip,
  ReferringAffiliationPieChart,
  PayerPieChart,
  TopReferralsInTable,
  TopReferralsOutTable,
  TopDepartmentsReferredTable,
  ClaimsProviderTab,
  FavoriteIconButton,
  ProfileNotes,
  FixedHeader,
  StickyHeader,
  DateRangeSelector,
  BreadcrumbRow,
  IconGridItem,
  ClickToCopy,
  ClaimsDateProvider,
} from 'components';
import { LocationView } from 'locations';
import { CodesPage } from 'codes';
import {
  PhoneUtil,
  DateUtil,
  StoreUtil,
  SpecialtyUtil,
} from 'doctivity-shared/utils';
import { StringUtil, withRouter, withIsMobile, withAppbarHeight } from 'utils';
import { upsertProvider, loadProvider } from 'store/actions/providersActions';
import { setDateRange } from 'store/actions/appActions';
import axiosInstance from 'utils/axiosUtil';
import { ConfirmAddProviderDialog } from 'locations';
import ProviderIcon from 'assets/images/provider_icon.svg';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { ProviderTagList } from 'tags';
import { SharedAffiliationCharts } from 'providers';

const MONTHS_TO_SHOW = 15;

const styles = (theme) => ({
  linksContainer: {
    position: 'absolute',
    textAlign: 'right',
    top: theme.spacing(2),
    right: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      top: 14,
      right: 0,
    },
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
  cardContainer: {
    marginTop: theme.spacing(3),
  },
  header: {
    padding: theme.spacing(3),
    paddingBottom: 0,
    backgroundColor: theme.palette.tertiary.main,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
  },
  headerContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.palette.tertiary.main,
    paddingLeft: 60,
    paddingBottom: 3,
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      alignItems: 'start',
    },
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 500,
    fontSize: 'clamp(16px, 2.5vw, 24px)',
    paddingRight: 30,
  },
  headerItem: {
    fontSize: 14,
    paddingRight: 15,
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  headerItemNoPadding: {
    fontSize: 14,
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  favIcon: {
    [theme.breakpoints.down('md')]: {
      marginTop: -6.5,
    },
  },
  headerIcon: {
    color: theme.palette.secondary.accent,
    marginRight: theme.spacing(1),
    paddingTop: 4,
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
    },
  },
  tabs: {
    paddingLeft: theme.spacing(3),
    backgroundColor: theme.palette.tertiary.main,
  },
  headerDivider: {},
  tabPanel: {},
  container: {
    padding: theme.spacing(3),
  },
  pdfButton: {},
  spacer: {
    flex: 1,
  },
  pdfIcon: {
    fontSize: 20,
    fontWeight: 200,
    color: theme.palette.secondary.main,
  },
  affiliatedIcon: {
    fontSize: 20,
    verticalAlign: 'text-top',
    marginRight: theme.spacing(1),
    paddingTop: 7,
    [theme.breakpoints.down('md')]: {
      marginTop: -6.5,
    },
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.secondary.bright,
    },
  },
  affiliatedInfoTip: {
    fontSize: 13,
    fontWeight: 200,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    borderRightStyle: 'solid',
    borderRightWidth: '1px',
    borderColor: theme.palette.tertiary.main,
    maxWidth: 380,
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      borderRight: 'none',
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
    },
  },
  detailsCard: {
    position: 'relative',
    display: 'flex',
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  cardDecorationBar: {
    position: 'absolute',
    backgroundColor: theme.palette.secondary.accent,
    width: 13,
    top: 0,
    left: 0,
    height: '100%',
  },
  icons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
  },
  organizationIcon: {
    marginRight: 2,
    color: '#999999',
  },
  pdfExportContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    marginBottom: theme.spacing(2),
  },
  pdfIcon: {
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.secondary.bright,
    },
    color: theme.palette.secondary.main,
  },
  providerIcon: {
    alignSelf: 'center',
    justifySelf: 'center',
    width: 110,
  },
  specialistPill: {
    fontSize: 10,
    borderRadius: 20,
    backgroundColor: theme.palette.quaternary.main,
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    fontWeight: 500,
    color: '#fff',
    alignSelf: 'flex-start',
  },
  providerDetailsTitle: {
    color: theme.palette.primary.dark,
    flexShrink: 0,
    font: 'normal normal 24px/37px Roboto',
    textAlign: 'center',
  },
  editButton: {
    color: theme.palette.secondary.main,
  },
  detailsFields: {
    paddingLeft: theme.spacing(5),
    paddingRight: 100,
    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  detailsRow: {
    display: 'flex',
    alignItems: 'center',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderColor: theme.palette.tertiary.main,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
      borderBottom: 'none',
    },
  },
  detailsItem: {
    flexBasis: '50%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderColor: theme.palette.tertiary.main,
    },
  },
  phoneRow: {
    display: 'flex',
    alignItems: 'start',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  phoneColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  organizationButton: {
    color: theme.palette.secondary.main,
    padding: 0,
    fontWeight: 400,
    fontSize: 13,
  },
  tagBubble: {
    border: '2px solid ' + theme.palette.tertiary.main,
    borderRadius: '20px',
    padding: '0.5rem',
  },
  mobileChart: {
    '&:nth-child(odd)': {
      display: 'none',
    },
  },
});

const providerSchema = {
  type: 'object',
  required: ['first_name', 'last_name'],
  properties: {
    first_name: { type: 'string', title: 'First Name' },
    middle_name: { type: 'string', title: 'Middle Name' },
    last_name: { type: 'string', title: 'Last Name' },
    business_name: { type: 'string', title: 'Business Name' },
    npi: { type: 'string', title: 'NPI' },
    credential: { type: 'string', title: 'Credential' },
    phone: {
      type: 'string',
      title: 'Phone',
      maxLength: 11,
      mask: 'phone-ext',
    },
    mobile_phone: {
      type: 'string',
      title: 'Mobile',
      maxLength: 11,
      mask: 'phone-ext',
    },
    email: { type: 'string', title: 'Email' },
    primary_taxonomy: { type: 'string', title: 'Primary Taxonomy' },
    // system: { type: 'string', title: 'System' },
    is_affiliated: { type: 'boolean', title: 'Affiliated' },
  },
  dependencies: {
    is_affiliated: {
      oneOf: [
        {
          properties: {
            is_affiliated: { enum: [true] }, // if this is true, then below added
            is_specialist: { type: 'boolean', title: 'Specialist' },
            rvu_goal: { type: ['integer', 'null'], title: 'RVU Goal (1 Year)' },
          },
        },
      ],
    },
  },
};

const providerUISchema = (user) =>  ({
  first_name: {
    classNames: 'two-column-field',
    'ui:readonly': !user.is_admin,
  },
  middle_name: {
    classNames: 'two-column-field',
    'ui:readonly': !user.is_admin,
  },
  last_name: {
    'ui:readonly': !user.is_admin,
  },
  npi: {
    classNames: 'two-column-field',
    'ui:readonly': !user.is_admin,
  },
  credential: {
    classNames: 'two-column-field',
  },
  phone: {
    classNames: 'two-column-field',
  },
  mobile_phone: {
    classNames: 'two-column-field',
  },
  email: {
    classNames: 'two-column-field',
  },
  is_affiliated: {
    classNames: 'two-column-field',
  },
  is_specialist: {
    classNames: 'two-column-field',
  },
  rvu_goal: {
    classNames: 'two-column-field',
  },
});

class ProviderDetailPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: false,
      editProviderForm: {},
      editFormError: false,
      editFormErrorMessage: '',

      confirmChangeLocationData: undefined,

      loadingTopVisitTypes: false,
      topVisitTypes: [],
      topVisitTypesQuery: {
        limit: 7, // number of types to show in pie chart before making "other"
      },

      loadingReferralCountsByMonth: false,
      referralCountsByMonth: [],

      visitsTable: [],
      visitsQuery: {
        limit: 25,
        offset: 0,
        where: {
          $or: {
            provider_id: this.props.router.params.id,
          },
        },
        include: [
          { association: 'payer' },
          { association: 'referrer', attributes: ['last_name'] },
          { association: 'provider', attributes: ['last_name'] },
        ],
        order: [['encounter_date', 'DESC']],
      },

      loadingReferralOutCountsByMonth: false,
      referralOutCountsByMonth: [],
      headerHeight: 0,
      stickyTab: undefined,
    };
  }

  componentDidMount() {
    const { dispatch, clientId } = this.props;

    dispatch(loadProvider(this.props.router.params.id, clientId));
    this.fetchTopVisitTypes();
    this.fetchReferralCountsByMonth();
    this.fetchReferralOutCountsByMonth();
    this.fetchVisits();
  }

  componentDidUpdate(prevProps) {
    const { dispatch, clientId } = this.props;

    if (
      this.props.app.selectedClient !== prevProps.app.selectedClient ||
      this.props.router.params.id !== prevProps.router.params.id
    ) {
      dispatch(loadProvider(this.props.router.params.id, clientId));
      this.fetchTopVisitTypes();
      this.fetchReferralCountsByMonth();
      this.fetchReferralOutCountsByMonth();

      this.setState(
        {
          visitsQuery: {
            ...this.state.visitsQuery,
            limit: 25,
            offset: 0,
            where: {
              $or: {
                provider_id: this.props.router.params.id,
                referrer_id: this.props.router.params.id,
              },
            },
          },
        },
        () => {
          this.fetchVisits();
        },
      );
    } else if (this.props.app.dateRange !== prevProps.app.dateRange) {
      this.fetchTopVisitTypes();
      this.fetchReferralCountsByMonth();
      this.fetchReferralOutCountsByMonth();
      this.fetchVisits();
    }
  }

  fetchTopVisitTypes() {
    this.setState({
      loadingTopVisitTypes: true,
    });
    axiosInstance
      .post('/Analytics', {
        type: 'VISIT_TYPES_BY_PROVIDER',
        filter: {
          client_id: this.props.app.selectedClient,
          provider_id: this.props.router.params.id,
        },
        opts: {
          ...this.state.topVisitTypesQuery,
          from: this.props.app.dateRange.start,
          to: this.props.app.dateRange.end,
        },
      },
      {
        params: {
          selected_client_id: this.props.app.selectedClient,
        },
      })
      .then((response) => {
        this.setState({
          loadingTopVisitTypes: false,
          topVisitTypes: this.transformVisitTypes(response.data),
        });
      });
  }

  fetchReferralCountsByMonth(csv) {
    if (!csv) {
      this.setState({
        loadingReferralCountsByMonth: true,
      });
    }
    const endDate = moment(this.props.app.dateRange.end);
    const startDate = moment(this.props.app.dateRange.start);
    axiosInstance
      .post('/Analytics', {
        type: 'VISIT_COUNT_BY_MONTH',
        format: csv ? 'csv' : 'json',
        filter: {
          client_id: this.props.app.selectedClient,
          provider_id: this.props.router.params.id,
          to: endDate.endOf('day'),
          from: startDate.startOf('day'),
        },
      },
      {
        params: {
          selected_client_id: this.props.app.selectedClient,
        },
      })
      .then((response) => {
        if (csv) {
          const filename = `doctivity_${
            this.props.provider.data.last_name
          }_referred_${moment().format('YY_MM_DD')}.csv`;
          fileDownload(response.data, filename);
        } else {
          this.setState({
            loadingReferralCountsByMonth: false,
            referralCountsByMonth: this.transformVisitCounts(
              response.data,
              'referred',
              'count',
            ),
          });
        }
      });
  }

  fetchReferralOutCountsByMonth(csv) {
    if (!csv) {
      this.setState({
        loadingReferralOutCountsByMonth: true,
      });
    }
    const endDate = moment(this.props.app.dateRange.end);
    axiosInstance
      .post('/Analytics', {
        type: 'PROVIDER_REFERRED_OUT_COUNT_BY_MONTH',
        format: csv ? 'csv' : 'json',
        filter: {
          client_id: this.props.app.selectedClient,
          referrer_id: this.props.router.params.id,
          to: endDate,
          from: endDate
            .clone()
            .subtract(MONTHS_TO_SHOW, 'months')
            .startOf('day'),
        },
      },
      {
        params: {
          selected_client_id: this.props.app.selectedClient,
        },
      })
      .then((response) => {
        if (csv) {
          const filename = `doctivity_${
            this.props.provider.data.last_name
          }_referrals_${moment().format('YY_MM_DD')}.csv`;
          fileDownload(response.data, filename);
        } else {
          this.setState({
            loadingReferralOutCountsByMonth: false,
            referralOutCountsByMonth: this.transformVisitCounts(
              response.data,
              'count_referrer_id',
            ),
          });
        }
      });
  }

  fetchVisits(csv) {
    const query = {
      ...this.state.visitsQuery,
      where: {
        ...this.state.visitsQuery.where,
        client_id: this.props.app.selectedClient,
      },
      limit: csv ? null : this.state.visitsQuery.limit,
      format: csv ? 'csv' : 'json',
    };
    //console.log(query);
    axiosInstance
      .get('/Visits', {
        params: {
          opts: JSON.stringify(query),
          selected_client_id: this.props.app.selectedClient,
        },
      })
      .then((response) => {
        if (csv) {
          const filename = `doctivity_${
            this.props.provider.data.last_name
          }_visits_${moment().format('YY_MM_DD')}.csv`;
          fileDownload(response.data, filename);
        } else {
          this.setState({
            visitsTable: response.data,
          });
        }
      });
  }

  fetchPDFReport() {
    axiosInstance
      .post('/PDF', {
        filter: {
          client_id: this.props.app.selectedClient,
          provider_id: this.props.router.params.id,
          from: this.props.app.dateRange.start,
          to: this.props.app.dateRange.end,
        },
      },
      {
        params: {
          selected_client_id: this.props.app.selectedClient,
        },
      })
      .then((response) => {
        const filename = `doctivity_${
          this.props.provider.data.last_name
        }_${moment().format('YY_MM_DD')}.pdf`;
        fileDownload(
          StringUtil.b64toBlob(response.data, 'application/pdf'),
          filename,
        );
      });
  }

  render() {
    const {
      isEditing,
      editProviderForm,
      confirmChangeLocationData,
      headerHeight,
      stickyTab,
    } = this.state;

    const { classes, app, clients, appbarHeight, isMobile } = this.props;

    if (!StoreUtil.isLoaded(this.props.provider)) {
      return <LoadingView />;
    }

    let client = null;
    if (app.selectedClient && StoreUtil.isLoaded(clients)) {
      const data = StoreUtil.getData(clients);
      client = data.find((c) => c.id === app.selectedClient);
    }

    const provider = StoreUtil.getData(this.props.provider);
    if (
      !provider ||
      provider.id !== parseInt(this.props.router.params.id, 10)
    ) {
      return <LoadingView />;
    }

    const updatedEditProviderForm = {
      ...editProviderForm,
      profile_notes: null,
    };
    let isAffiliated = false;
    let isSpecialist = false;
    let rvuGoal = null;

    const affiliated = provider.clients.find(
      (c) => c.id === this.props.app.selectedClient,
    );
    if (affiliated) {
      updatedEditProviderForm.is_affiliated = true;
      updatedEditProviderForm.is_specialist =
        affiliated.ProvidersToClients.is_specialist;
      updatedEditProviderForm.rvu_goal = affiliated.ProvidersToClients.rvu_goal;
      isAffiliated = true;
      isSpecialist = affiliated.ProvidersToClients.is_specialist;
      rvuGoal = affiliated.ProvidersToClients.rvu_goal;
    } else {
      updatedEditProviderForm.is_affiliated = false;
      updatedEditProviderForm.is_specialist = true;
      updatedEditProviderForm.rvu_goal = null;
    }

    const specialty = SpecialtyUtil.getNameFromTaxonomy(provider.taxonomy);

    let noDataMessage = 'No data found.';
    if (app.dateRange.name) {
      noDataMessage = `No data found in ${app.dateRange.name}.`;
    }

    let currentTab = this.props.router.params.tab || 'details';
    if (currentTab === 'scorecard' && !isAffiliated) {
      currentTab = 'visits';
    }
    let locationStr = '';
    const location = provider.location;
    if (location && location.city && location.state) {
      locationStr = `${StringUtil.toTitleCase(location.city)}, ${
        location.state
      }`;
    } else if (location && location.city) {
      locationStr = StringUtil.toTitleCase(location.city);
    } else if (location && location.state) {
      locationStr = location.state;
    }

    const providerName = `${provider.last_name}, ${provider.first_name} ${
      provider.middle_name || ''
    } ${provider.credential ? ` | ${provider.credential}` : ''}`;

    const showProfileNotes = !!provider;
    const email = provider?.related_clients?.find((c) => c.id === app.selectedClient)?.options?.email ?? provider.email;

    return (
      <div>
        <Helmet defer={false}>
          <title>{providerName}</title>
        </Helmet>
        <div className={classes.appBarSpacer} />
        <FixedHeader onChangeHeaderHeight={this.onChangeHeaderHeight}>
          <div className={classes.breadcrumb}>
            <BreadcrumbRow
              label='Providers'
              path='/providers'
              icon='chevron_left'
            />
          </div>
          <div className={classes.header}>
            <Icon className={classes.headerIcon}>people</Icon>
            <Typography className={classes.headerTitle}>
              {providerName}
            </Typography>
            <div className={classes.spacer} />
            <div className={classes.icons}>
              {isAffiliated && (
                <Tooltip
                  placement='bottom-start'
                  title={
                    <Typography className={classes.affiliatedInfoTip}>
                      Affiliated Provider
                    </Typography>
                  }
                >
                  <Icon className={classes.affiliatedIcon}>hub</Icon>
                </Tooltip>
              )}
              <div className={classes.favIcon}>
                <FavoriteIconButton
                  objectType='Providers'
                  objectId={provider.id}
                />
              </div>
            </div>
          </div>
          <div className={classes.headerContent}>
            {specialty && (
              <Typography className={classes.headerItem}>
                {specialty}
              </Typography>
            )}
            <Typography className={classes.headerItemNoPadding}>
              {locationStr}
            </Typography>
          </div>
          <Divider
            style={{
              borderColor: stickyTab === false ? '#0000001f' : '#EDEDED',
              transition: 'borderColor 0.1s ease-in',
            }}
          />
        </FixedHeader>
        <StickyHeader
          headerTop={headerHeight + appbarHeight}
          onChangeIsSticky={this.onChangeIsSticky}
        >
          <Tabs
            variant='scrollable'
            scrollButtons={false}
            className={classes.tabs}
            value={currentTab}
            onChange={this.onTabChange}
          >
            <Tab label='Details & Contact' value='details' disableRipple />
            {isAffiliated && (
              <Tab label='Metrics' value='scorecard' disableRipple />
            )}
            <Tab label='Visits & Referrals' value='visits' disableRipple />
            <Tab label='Activity Notes' value='notes' disableRipple />
            {client && client.has_claims_access && (
              <Tab label='Market Data' value='market' disableRipple />
            )}
            {client && client.has_claims_access && (
              <Tab label='Shared Patients' value='shared' disableRipple />
            )}
            {client && client.has_claims_access && (
              <Tab label='Captured Codes' value='codes' disableRipple />
            )}
          </Tabs>
          <Divider className={classes.headerDivider} />
        </StickyHeader>

        <div className={classes.container}>
          {(currentTab === 'codes' ||
            currentTab === 'market' ||
            currentTab === 'shared') && <ClaimsDateProvider />}
          {currentTab === 'scorecard' && (
            <>
              <div className={classes.pdfExportContainer}>
                <DateRangeSelector useClientDate={true} />
                {isAffiliated && (
                  <div className={classes.pdfButton}>
                    <IconButton
                      onClick={() => {
                        this.fetchPDFReport();
                      }}
                      size='large'
                      className={classes.pdfIcon}
                    >
                      <PictureAsPdf className={classes.pdfIcon} />
                    </IconButton>
                  </div>
                )}
              </div>
              <ClientRevenue
                clientId={app.selectedClient}
                dateRange={app.dateRange}
                providerId={provider.id}
              />
              <ProviderScorecard
                providerId={provider.id}
                clientId={app.selectedClient}
                specialty={provider.primary_taxonomy}
                state={provider.state}
                rvuGoal={rvuGoal}
                providerName={`${provider.last_name}, ${provider.first_name}`}
              />
            </>
          )}
          {currentTab === 'visits' && isAffiliated && (
            <>
              <div className={classes.pdfExportContainer}>
                <DateRangeSelector useClientDate={true} />
                <div className={classes.pdfButton}>
                  <IconButton
                    onClick={() => {
                      this.fetchPDFReport();
                    }}
                    size='large'
                  >
                    <PictureAsPdf className={classes.pdfIcon} />
                  </IconButton>
                </div>
              </div>
              <Card>
                <CardContent>
                  {this.state.loadingReferralCountsByMonth && <LoadingView />}
                  {!this.state.loadingReferralCountsByMonth && (
                    <>
                      <TitleTooltip
                        title='Monthly Visits'
                        tooltip='All visits by the affiliated Doctivity provider. This includes the unreffered and referred visits for that month. Click an individual month to recalculate the top referrer and visit types below.'
                        onExport={() => {
                          this.fetchReferralCountsByMonth(true);
                        }}
                      />
                      <Chart
                        type='line'
                        height={260}
                        options={{
                          chart: {
                            id: 'visit_types',
                            toolbar: {
                              show: false,
                            },
                            events: {
                              dataPointSelection: this.onBarChartClick,
                            },
                          },
                          xaxis: {
                            categories: this.state.referralCountsByMonth.dates,
                            hideOverlappingLabels: false,
                            labels: {
                              style: {
                                cssClass: isMobile ? classes.mobileChart : '',
                              },
                            },
                          },
                          // colors: ['#07594d'],
                          tooltip: {
                            enabled: true,
                            shared: true,
                            intersect: false,
                          },
                          stroke: {
                            curve: 'smooth',
                            width: [0, 0, 0, 3, 3],
                          },
                          dataLabels: {
                            enabled: false,
                            /*
                            enabledOnSeries: [4],
                            hideOverflowingLabels: false,
                            dropShadow: {
                              enabled: true,
                              left: 0,
                              top: 0,
                              opacity: 0.4,
                            },
                            */
                          },
                          legend: {
                            position: 'top',
                          },
                          yaxis: [
                            {
                              seriesName: 'New/Consult',
                              axisTicks: {
                                show: true,
                              },
                              title: {
                                text: 'Visit Types',
                              },
                            },
                            {
                              seriesName: 'New/Consult',
                              show: false,
                            },
                            {
                              seriesName: 'New/Consult',
                              show: false,
                            },
                            {
                              seriesName: 'New/Consult',
                              show: false,
                            },
                            {
                              seriesName: 'Total Visits',
                              opposite: true,
                              axisTicks: {
                                show: true,
                              },
                              title: {
                                text: 'Total Visits',
                              },
                            },
                          ],
                        }}
                        series={[
                          {
                            name: 'New/Consult',
                            data: this.state.referralCountsByMonth.new_visits,
                            type: 'column',
                          },
                          {
                            name: 'Established',
                            data: this.state.referralCountsByMonth.est_visits,
                            type: 'column',
                          },
                          {
                            name: 'Referred',
                            data: this.state.referralCountsByMonth.counts,
                            type: 'column',
                          },
                          {
                            name: 'Unreferred',
                            data: this.state.referralCountsByMonth.unreferred,
                            type: 'column',
                          },
                          {
                            name: 'Total Visits',
                            data: this.state.referralCountsByMonth.total,
                            type: 'line',
                          },
                        ]}
                      />
                    </>
                  )}
                </CardContent>
              </Card>
            </>
          )}
          {currentTab === 'visits' && !isAffiliated && (
            <>
              <div className={classes.pdfExportContainer}>
                <DateRangeSelector useClientDate={true} />
              </div>
              <Card>
                <CardContent>
                  {this.state.loadingReferralOutCountsByMonth && (
                    <LoadingView />
                  )}
                  {!this.state.loadingReferralOutCountsByMonth && (
                    <>
                      <Typography
                        component='h2'
                        variant='h6'
                        color='primary'
                        gutterBottom
                      >
                        {`Referrals Made by ${provider.last_name}`}
                      </Typography>
                      <Chart
                        type='bar'
                        height={225}
                        options={{
                          chart: {
                            id: 'basic-bar',
                            toolbar: {
                              show: false,
                            },
                          },
                          xaxis: {
                            categories:
                              this.state.referralOutCountsByMonth.dates,
                          },
                          // colors: ['#07594d'],
                          tooltip: {
                            enabled: true,
                          },
                          dataLabels: {
                            enabled: true,
                            dropShadow: {
                              enabled: true,
                              left: 0,
                              top: 0,
                              opacity: 0.4,
                            },
                          },
                        }}
                        series={[
                          {
                            name: 'Referrals',
                            data: this.state.referralOutCountsByMonth.counts,
                          },
                        ]}
                      />
                    </>
                  )}
                </CardContent>
              </Card>
            </>
          )}
          {currentTab === 'visits' && isAffiliated && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                {isSpecialist && (
                  <TopReferralsInTable
                    providerId={provider.id}
                    clientId={app.selectedClient}
                  />
                )}
                {!isSpecialist && (
                  <TopReferralsOutTable
                    providerId={provider.id}
                    clientId={app.selectedClient}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <PayerPieChart
                  providerId={provider.id}
                  clientId={app.selectedClient}
                />
                {this.state.loadingTopVisitTypes ? (
                  <LoadingView />
                ) : (
                  <Card className={classes.cardContainer}>
                    <CardContent>
                      <TitleTooltip
                        title='Visit Types'
                        tooltip='Visit types correspond with associated billing codes to identify the patient mix of the affiliated Doctivity provider based on all referrals.'
                      />
                      {this.state.topVisitTypes &&
                      this.state.topVisitTypes.counts &&
                      this.state.topVisitTypes.counts.length > 0 ? (
                        <Chart
                          type='pie'
                          {...(isMobile ? { height: 400 } : { height: 310 })}
                          options={{
                            ...(isMobile
                              ? {
                                  legend: {
                                    horizontalAlign: 'left',
                                    position: 'bottom',
                                  },
                                }
                              : {}),
                            chart: {
                              id: 'basic-pie',
                              events: {
                                dataPointSelection: this.onPieChartClick,
                              },
                            },
                            labels: this.state.topVisitTypes.labels,
                          }}
                          series={this.state.topVisitTypes.counts}
                        />
                      ) : (
                        <Typography>{noDataMessage}</Typography>
                      )}
                    </CardContent>
                  </Card>
                )}
                <ReferringAffiliationPieChart
                  providerId={provider.id}
                  clientId={app.selectedClient}
                />
              </Grid>
            </Grid>
          )}
          {currentTab === 'visits' && !isAffiliated && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TopReferralsOutTable
                  providerId={provider.id}
                  clientId={app.selectedClient}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TopDepartmentsReferredTable
                  providerId={provider.id}
                  clientId={app.selectedClient}
                />
              </Grid>
            </Grid>
          )}
          {currentTab === 'visits' && (
            <Card
              className={classes.cardContainer}
              ref={(el) => {
                this.allVisitsEl = el;
              }} // keep a ref to this card to scroll to it
            >
              <CardContent>
                <DynamicTable
                  ContainerType={React.Fragment}
                  header={
                    <TitleTooltip
                      title='Visits'
                      tooltip='Searchable table of all referrals made to the affiliated Doctivity provider that includes date patient was seen, name of referring provider, employed or non-employed status, and visit type.'
                      onExport={() => {
                        this.fetchVisits(true);
                      }}
                    />
                  }
                  columns={[
                    {
                      label: 'Date',
                      key: 'encounter_date',
                      // type: 'date', // dynamic table really needs to support a range first
                      filter: false,
                      format: DateUtil.formatFromDB,
                      style: { width: 90 },
                    },
                    {
                      label: 'Visit Type',
                      key: 'diagnosis_description',
                    },
                    { 
                      label: 'Referrer',
                      key: 'referrer.last_name',
                      format: (_, row) => {
                        if (row.referrer && row.referrer_id != provider.id) {
                          return row.referrer.last_name;
                        }
                        return ''
                      }
                    },
                    {
                      label: 'Department',
                      key: 'department',
                    },
                    {
                      label: 'Payor',
                      key: 'payer.name',
                      filter: false,
                    },
                  ]}
                  data={this.state.visitsTable}
                  query={this.state.visitsQuery}
                  onRowClick={(row) => {
                    if (row.referrer_id) {
                      if (isAffiliated) {
                        this.props.router.navigate(
                          `/providers/${row.referrer_id}`,
                        );
                      } else {
                        this.props.router.navigate(
                          `/providers/${row.provider_id}`,
                        );
                      }
                    }
                  }}
                  onQueryChange={this.onVisitsQueryChange}
                />
              </CardContent>
            </Card>
          )}
          {currentTab === 'notes' && (
            <ProviderComments
              provider={provider}
              clientId={app.selectedClient}
            />
          )}
          {currentTab === 'details' && (
            <div>
              <Card>
                <CardContent className={classes.detailsCard}>
                  <div className={classes.cardDecorationBar} />
                  <div className={classes.titleContainer}>
                    <Typography noWrap className={classes.providerDetailsTitle}>
                      Provider Details
                    </Typography>
                    <img
                      src={ProviderIcon}
                      alt='Provider Icon'
                      className={classes.providerIcon}
                    />
                    {affiliated?.ProvidersToClients.is_specialist && (
                      <Typography className={classes.specialistPill}>
                        SPECIALIST
                      </Typography>
                    )}
                    <OverlineLabel>PROVIDER NAME</OverlineLabel>
                    <UnderlineData>{`${provider.last_name}, ${
                      provider.first_name
                    } ${provider.middle_name || ''}${
                      provider.credential ? ` | ${provider.credential}` : ''
                    }`}</UnderlineData>
                    <OverlineLabel>SPECIALTY</OverlineLabel>
                    <UnderlineData>{specialty}</UnderlineData>
                  </div>

                  <div className={classes.detailsFields}>
                    <div className={classes.detailsRow}>
                      <div className={classes.detailsItem}>
                        <IconGridItem iconName='place'>
                          <OverlineLabel>LOCATION</OverlineLabel>
                          <LocationView
                            location={location}
                            onChange={this.onLocationSelect}
                          />
                        </IconGridItem>
                      </div>
                      <div className={classes.detailsItem}>
                        <IconGridItem iconName='tag'>
                          <OverlineLabel>NPI</OverlineLabel>
                          <UnderlineData>
                            <ClickToCopy>{provider.npi}</ClickToCopy>
                          </UnderlineData>
                        </IconGridItem>
                      </div>
                    </div>
                    <div className={classes.detailsRow}>
                      <div className={classes.detailsItem}>
                        <IconGridItem iconName='smartphone'>
                          <div className={classes.phoneRow}>
                            <div
                              className={classes.phoneColumn}
                              style={{ marginRight: 48 }}
                            >
                              <OverlineLabel>PHONE</OverlineLabel>
                              <UnderlineData>
                                {PhoneUtil.formatNumber(provider.phone)}
                              </UnderlineData>
                            </div>
                            <div className={classes.phoneColumn}>
                              <OverlineLabel>MOBILE</OverlineLabel>
                              <UnderlineData>
                                {PhoneUtil.formatNumber(provider.mobile_phone)}
                              </UnderlineData>
                            </div>
                          </div>
                        </IconGridItem>
                      </div>
                      <div className={classes.detailsItem}>
                        <IconGridItem iconName='apartment'>
                          <OverlineLabel>ORGANIZATION</OverlineLabel>
                          <UnderlineData>
                            {location?.organization ? (
                              <Button
                                onClick={() => {
                                  this.props.router.navigate(
                                    `/organizations/${location.organization.id}`,
                                  );
                                }}
                                variant='text'
                                className={classes.organizationButton}
                              >
                                {location.organization.name}
                              </Button>
                            ) : (
                              'No organization'
                            )}
                          </UnderlineData>
                        </IconGridItem>
                      </div>
                    </div>
                    <div
                      className={classes.detailsRow}
                      style={{ borderBottom: 'none' }}
                    >
                      <div className={classes.detailsItem}>
                        <IconGridItem iconName='email'>
                          <OverlineLabel>EMAIL</OverlineLabel>
                          <UnderlineData>{email}</UnderlineData>
                        </IconGridItem>
                      </div>
                    </div>
                  </div>

                  <div className={classes.linksContainer}>
                    <Button
                      variant='text'
                      color='primary'
                      onClick={this.editProvider}
                      className={classes.editButton}
                    >
                      <EditOutlinedIcon className={classes.leftIcon} />
                      Edit
                    </Button>
                  </div>
                </CardContent>
              </Card>
              <div className={classes.cardContainer}>
                <ProviderTagList provider={provider} />
              </div>
              {showProfileNotes && (
                <div className={classes.cardContainer}>
                  <ProfileNotes model='provider' data={provider} />
                </div>
              )}
            </div>
          )}
          {currentTab === 'market' && client && client.has_claims_access && (
            <ClaimsProviderTab provider={provider} />
          )}
          {currentTab === 'shared' && client && client.has_claims_access && (
            <SharedAffiliationCharts provider={provider} />
          )}
          {currentTab === 'codes' && client && client.has_claims_access && (
            <CodesPage provider={provider} />
          )}
        </div>

        <DialogForm
          formData={updatedEditProviderForm}
          open={isEditing}
          schema={providerSchema}
          uiSchema={providerUISchema(this.props.user)}
          onClose={this.closeEditProvider}
          onFormSubmit={this.onSave}
          status={
            this.state.editFormError ? this.state.editFormErrorMessage : ''
          }
          statusIsError
        />

        {confirmChangeLocationData && (
          <ConfirmAddProviderDialog
            open={!!confirmChangeLocationData}
            locationData={confirmChangeLocationData}
            provider={provider}
            onConfirm={this.onConfirmChangeLocation}
            onClose={this.closeConfirmChangeLocationDialog}
          />
        )}
      </div>
    );
  }

  transformVisitCounts(results, countField, totalField) {
    const transformed = {
      dates: [],
      counts: [],
      unreferred: [],
      est_visits: [],
      new_visits: [],
      total: [],
    };

    results.forEach((row) => {
      transformed.dates.push(
        `${moment().month(row.month).format('MMM')} ${moment()
          .year(row.year)
          .format('YY')}`,
      );
      if (totalField) {
        transformed.counts.push(row[countField]);
        transformed.unreferred.push(row[totalField] - row[countField]);
        transformed.est_visits.push(row.est_visits);
        transformed.new_visits.push(row.new_visits);
        transformed.total.push(row[totalField]);
      } else {
        transformed.counts.push(row[countField]);
      }
    });

    return transformed;
  }

  transformVisitTypes(results) {
    const transformed = {
      labels: [],
      counts: [],
    };

    results.forEach((row) => {
      if (row.diagnosis_description) {
        if (row.diagnosis_description.length > 27) {
          transformed.labels.push(
            `${row.diagnosis_description.substring(0, 27)}...`,
          );
        } else {
          transformed.labels.push(row.diagnosis_description);
        }
      } else {
        transformed.labels.push('Unavailable');
      }
      transformed.counts.push(row.count_diagnosis_description);
    });

    return transformed;
  }

  editProvider = () => {
    this.setState({
      isEditing: true,
      editProviderForm: {
        ...this.props.provider.data,
        email: this.props.provider.data.related_clients ? 
          this.props.provider.data.related_clients.find((c) => c.id === this.props.app.selectedClient)?.options?.email ?? this.props.provider.data.email : this.props.provider.data.email,
    }});
  };

  closeEditProvider = () => {
    this.setState({
      isEditing: false,
      editFormError: false,
      editFormErrorMessage: '',
    });
  };

  onSave = (data) => {
    const { dispatch } = this.props;

    this.setState({
      isEditing: false,
    });

    data.selectedClient = this.props.app.selectedClient;

    dispatch(upsertProvider(data));
  };

  onLocationSelect = (location) => {
    this.setState({ confirmChangeLocationData: location });
  };

  onConfirmChangeLocation = () => {
    const { confirmChangeLocationData } = this.state;
    const provider = StoreUtil.getData(this.props.provider);
    if (provider && confirmChangeLocationData) {
      if (provider.location_id !== confirmChangeLocationData.id) {
        provider.location_updated_at = DateUtil.formatNowForDB();
      }
      provider.location = confirmChangeLocationData;
      provider.location_id = confirmChangeLocationData.id;
      this.props.dispatch(upsertProvider(provider));
    }
  };

  closeConfirmChangeLocationDialog = () => {
    this.setState({ confirmChangeLocationData: undefined });
  };

  onTabChange = (event, newValue) => {
    this.props.router.navigate(
      `/providers/${this.props.router.params.id}/${newValue}`,
    );
  };

  onVisitsQueryChange = (query) => {
    this.setState(
      {
        visitsQuery: { 
          ...this.state.visitsQuery,
          ...query,
        }
      },
      () => {
        this.fetchVisits();
      },
    );
  };

  onBarChartClick = (event, chartContext, config) => {
    const d = moment(this.props.app.dateRange.end).subtract(
      MONTHS_TO_SHOW - config.dataPointIndex,
      'months',
    );

    const { dispatch } = this.props;

    dispatch(
      setDateRange({
        start: d.startOf('month'),
        end: moment(d).endOf('month'),
        name: d.format('MMMM YYYY'),
      }),
    );
  };

  onPieChartClick = (event, chartContext, config) => {
    let visitType;
    if (
      this.state.topVisitTypes &&
      this.state.topVisitTypes.labels &&
      this.state.topVisitTypes.labels.length > config.dataPointIndex
    ) {
      visitType = this.state.topVisitTypes.labels[config.dataPointIndex];
    }

    if (visitType && visitType !== 'Other') {
      this.setState(
        {
          visitsQuery: {
            ...this.state.visitsQuery,
            limit: 25,
            offset: 0,
            where: {
              $or: {
                provider_id: this.props.router.params.id,
                referrer_id: this.props.router.params.id,
              },
              diagnosis_description: {
                $like: `%${visitType}%`,
              },
            },
          },
        },
        () => {
          this.fetchVisits();
        },
      );
    }
    if (this.allVisitsEl) {
      // scroll to top of visits table
      const main = document.getElementsByTagName('main');
      if (main && main.length > 0) {
        main[0].scrollTo(0, this.allVisitsEl.offsetTop - 300);
      }
    }
  };

  onChangeHeaderHeight = (headerHeight) => {
    this.setState({ headerHeight });
  };

  onChangeIsSticky = (isSticky) => {
    this.setState({ stickyTab: isSticky });
  };
}

ProviderDetailPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  appbarHeight: PropTypes.number.isRequired,
  classes: PropTypes.object,
  app: PropTypes.object,
  user: PropTypes.object,
  provider: PropTypes.object,
  clients: PropTypes.object,
  isMobile: PropTypes.bool,
};

function mapStateToProps(state, props) {
  const { app, user, clients } = state;

  return {
    app,
    user,
    provider: StoreUtil.get(
      state.providers,
      StoreUtil.COMMON_ITEM,
      props.router.params.id,
    ),
    clients: StoreUtil.get(clients, StoreUtil.COMMON_LIST),
    clientId: app.selectedClient,
  };
}

const styled = withStyles(styles)(
  withAppbarHeight(withIsMobile(ProviderDetailPage)),
);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as ProviderDetailPage };
