import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Chip, Box } from '@mui/material';

const TruncatedChips = ({ text, className }) => {
  const containerRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [visibleChips, setVisibleChips] = useState([]);
  const [overflowCount, setOverflowCount] = useState(0);

  useEffect(() => {
    const checkOverflow = () => {
      const container = containerRef.current;
      if (container) {
        const containerWidth = container.clientWidth;
        let totalWidth = 0;
        let tempVisibleChips = [];
        let overflow = 0;

        for (let i = 0; i < text.length; i++) {
          const chip = document.createElement('span');
          chip.style.visibility = 'hidden';
          chip.style.display = 'inline-block';
          chip.textContent = text[i];
          container.appendChild(chip);
          const chipWidth = chip.offsetWidth + 24;
          container.removeChild(chip);

          if (totalWidth + chipWidth < containerWidth) {
            totalWidth += chipWidth;
            tempVisibleChips.push(text[i]);
          } else {
            overflow = text.length - i;
            break;
          }
        }

        setVisibleChips(tempVisibleChips);
        setOverflowCount(overflow);
        setIsOverflowing(overflow > 0);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [text]);

  return (
    <>
      <Box
        ref={containerRef}
        className={className}
        sx={{ display: 'flex', flexWrap: 'nowrap', overflow: 'hidden' }}
      >
        {visibleChips.map((chipText, index) => (
          <Chip
            key={index}
            label={chipText}
            sx={{ marginRight: 1, background: '#f0fcf9' }}
          />
        ))}
      </Box>
      {isOverflowing && overflowCount > 0 ? (
        <span style={{ marginLeft: '5px', marginRight: '5px' }}>
          (+{overflowCount})
        </span>
      ) : (
        ''
      )}
    </>
  );
};

TruncatedChips.propTypes = {
  text: PropTypes.array.isRequired,
  className: PropTypes.string,
};

export default TruncatedChips;
