import React from 'react';
import { connect } from 'react-redux';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { TitleTooltip, LoadingView } from 'components';
import { StoreUtil, CsvUtil  } from 'doctivity-shared/utils';
import { withIsMobile} from 'utils';
import { listClaimsPayersByProvider } from 'store/actions/claimsActions';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { ColorUtil } from 'utils';

const styles = (theme) => ({
  card: {
    height: '100%',
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
  nothingFound: {
    fontWeight: 300,
    fontSize: 12,
  },
});

class ClaimsPayers extends React.Component {
  componentDidMount() {
    const { payers } = this.props;

    if (StoreUtil.needsLoadNoCache(payers)) {
      this.fetchPayers();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.provider !== prevProps.provider) {
      this.fetchPayers();
    }
  }

  fetchPayers() {
    this.props.dispatch(listClaimsPayersByProvider(this.props.provider.id));
  }

  render() {
    const { classes, payers, isMobile } = this.props;

    if (!payers || !StoreUtil.isLoaded(payers)) {
      return <LoadingView />;
    }

    const labels = [];
    const data = [];
    const max = 8;
    let i = 0;
    let otherData = 0;
    payers.data.forEach((p) => {
      if (i < max) {
        let name = p.payer.name;
        if (name.startsWith('Other')) {
          otherData += parseFloat(p.percent);
          return; // don't let i increment here
        }
        if (name.length > 20) {
          name = `${name.substring(0, 20)}...`;
        }
        labels.push(name);
        data.push(parseFloat(p.percent));
      } else if (i === max) {
        labels.push('Other');
        data.push(parseFloat(p.percent) + otherData);
      } else {
        data[max] += parseFloat(p.percent);
        data[max] = data[max].toFixed(2) * 1;
      }
      i++;
    });

    // this means other was included in data, but we didn't hit the max
    if (otherData > 0 && i <= max) {
      labels.push('Other');
      data.push(otherData);
    }

    return (
      <Card
        className={classes.card}
        style={{ height: isMobile ? 450 : '100%' }}
      >
        <CardContent style={{ height: isMobile ? '100%' : 290 }}>
          <TitleTooltip
            title='Payor'
            tooltip="Insight into the payors that are affiliated to a physician based on their patient's claims."
            rightLabel='Based on claims from all ages'
            onExport={() => {
              this.downloadPayorData();
            }}

          />

          <Chart
            type='pie'
            height={isMobile ? 400 : 250}
            options={{
              chart: {
                animations: {
                  enabled: false,
                },
              },
              responsive: [
                {
                  breakpoint: 900,
                  options: {
                    legend: {
                      position: 'bottom',
                    },
                  },
                },
              ],
              labels,
            }}
            series={data}
            colors={ColorUtil.COLORS}
          />
        </CardContent>
      </Card>
    );
  }

  downloadPayorData() {
    const { provider, payers } = this.props;
    const data = payers.data.map((p) => {
      return  [
        provider.npi,
        provider.first_name,
        provider.last_name,
        p.payer.name,
        p.percent,
      ];
    });
    const header = ['NPI', 'First Name', 'Last Name', 'Payer', 'Percent'];
    const filename = `doctivity_${provider.last_name}_${provider.first_name}_PAYORS_${moment().format('YY_MM_DD')}.csv`;
    fileDownload(CsvUtil.matrixToString([header, ...data]), filename);
  }
}

ClaimsPayers.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  classes: PropTypes.object,
  payers: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  provider: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    payers: StoreUtil.get(state.claims, 'payers_list'),
  };
}

const styled = withStyles(styles)(withIsMobile(ClaimsPayers));
const connected = connect(mapStateToProps)(styled);
export { connected as ClaimsPayers };
