import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Map, Source, Layer } from 'react-map-gl';
import { PropTypes } from 'prop-types';
import { StateUtil, StoreUtil } from 'doctivity-shared/utils';
import { LngLatBounds } from 'mapbox-gl';
import { listCountiesByState } from 'store/actions/countiesActions';
import axios from 'axios';

const mapboxToken =
  'pk.eyJ1IjoiZG9jdGl2aXR5IiwiYSI6ImNsMmo2OWx6bTBtdmUza3J6eDQ2Z3pvdG0ifQ.o9j6xJxOa2WKQLYdqQaJRQ';

function MarketMap(props) {
  const mapRef = useRef(null);
  const allCounties = useSelector((state) => state.counties);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [state, setState] = useState({
    name: null,
    editedName: false,
    geodata: null,
    byPostal: false,
    states: [],
    postals: '',
    isPrivate: false,
    dataModified: false,
    confirmCloseOpen: false,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    axios.get('maps/counties.geojson').then((response) => {
      setState({
        ...state,
        geodata: response.data,
      });
      fetchCountyGeo()
    });
  }, []);
  useEffect(() => {
    if (!mapLoaded) {
      return;
    }
    let states = [
      {
        abbr: 'PA',
      },
    ];
    let postals = '';
    let name = '';
    let byPostal = false;
    let isPrivate = false;
    const { market } = props;
    if (market) {
      name = market.name;
      byPostal = market.by_postal;
      isPrivate = market.is_private;
      if (byPostal && market.postals) {
        postals = market.postals.join(', ');
      } else if (market.states) {
        states = market.states.slice(); // copy array
      }
    }
    // make sure they all have a county array
    states.forEach((state) => {
      if (!state.counties) {
        state.counties = [];
      }
      state.countiesOpen = false;
    });
    setState({
      ...state,
      name,
      states,
      byPostal,
      postals,
      isPrivate,
    });
  }, [props.market, state.geodata, mapLoaded]);
  useEffect(() => {
    fetchCountiesForAllStates().then(() => {
      if (state.geodata) {
        setInitialMapState(true);
      }
    });
  }, [state.states]);
  useEffect(() => {
    if (state.geodata && state.states) {
      setInitialMapState();
    }
  }, [state.geodata, state.states, state.name]);
  const fetchCountiesForAllStates = async () => {
    if (state.states && state.states.length > 0) {
      state.states.forEach((state) => {
        if (
          !StoreUtil.isLoaded(
            StoreUtil.get(allCounties, 'counties_by_state', state.abbr),
          )
        ) {
          return fetchCounties(state.abbr);
        }
      });
    }
  };
  const setInitialMapState = (clearMap = true) => {
    if (mapRef) {
      if (clearMap && mapRef.current.getSource('counties')) {
        mapRef.current.querySourceFeatures('counties').forEach((feature) => {
          const fs = mapRef.current.getFeatureState({
            source: 'counties',
            id: feature.properties.GEOID,
          });
          if (fs?.selected) {
            mapRef.current.setFeatureState(
              { id: feature.properties.GEOID, source: 'counties' },
              { selected: false },
            );
          }
        });
      }
      if (state.states && state.states.length > 0) {
        state.states.forEach((state) => {
          if (state.counties && state.counties.length === 0) {
            selectAllOnMap(state.abbr, true);
          } else if (state.counties) {
            state.counties.forEach((county) => {
              selectOnMap(county.geo_id, true);
            });
          }
        });
        zoomToBounds();
      }
    }
  };
  const selectOnMap = (geoId, selected) => {
    if (mapRef) {
      mapRef.current.setFeatureState(
        { id: geoId, source: 'counties' },
        { selected },
      );
    } else {
      console.log('MAP IS NOT READY!');
    }
  };

  const selectAllOnMap = (stateAbbr, selected) => {
    const stateCounties = StoreUtil.get(
      allCounties,
      'counties_by_state',
      stateAbbr,
    );
    let usedDb = false;
    if (stateCounties && StoreUtil.isLoaded(stateCounties)) {
      const counties = StoreUtil.getData(stateCounties);
      if (counties && counties.length > 0) {
        usedDb = true;
        counties.forEach((county) => {
          selectOnMap(county.geo_id, selected);
        });
      }
    }

    if (!usedDb && state.geodata) {
      const fips = StateUtil.getFipsForAbbr(stateAbbr);
      if (fips) {
        const features = state.geodata.features.filter(
          (f) => f.properties.STATEFP === fips,
        );

        features.forEach((feature) => {
          selectOnMap(feature.properties.GEOID, selected);
        });
      }
    }
  };

  const zoomToBounds = () => {
    if (state.states && state.states.length > 0) {
      const bounds = new LngLatBounds();
      let count = 0;
      state.states.forEach((state) => {
        if (state.counties.length === 0) {
          if (!state.bounds) {
            setStateBounds(state);
          }
          if (state.bounds) {
            bounds.extend(state.bounds);
            count++;
          }
        } else {
          state.counties.forEach((county) => {
            if (!county.bounds) {
              setCountyBounds(county);
            }
            if (county.bounds) {
              bounds.extend(county.bounds);
              count++;
            }
          });
        }
      });
      if (count > 0) {
        mapRef.current.fitBounds(bounds, { padding: 40, duration: 500 });
      }
    }
  };

  const setCountyBounds = (county) => {
    if (state.geodata) {
      const feature = state.geodata.features.find(
        (f) => f.properties.GEOID === county.geo_id,
      );
      if (feature) {
        const bounds = new LngLatBounds();
        let count = 0;
        feature.geometry.coordinates.forEach((a) => {
          a.forEach((f) => {
            count++;
            bounds.extend(f);
          });
        });
        if (count > 0) {
          county.bounds = bounds;
        }
      }
    }
  };

  const setStateBounds = (selState) => {
    if (state.geodata) {
      const fips = StateUtil.getFipsForAbbr(selState.abbr);
      const features = state.geodata.features.filter(
        (f) => f.properties.STATEFP === fips,
      );
      if (features) {
        const bounds = new LngLatBounds();
        let count = 0;
        features.forEach((feature) => {
          feature.geometry.coordinates.forEach((a) => {
            a.forEach((f) => {
              count++;
              bounds.extend(f);
            });
          });
        });
        if (count > 0) {
          selState.bounds = bounds;
        }
      }
    }
  };

  const fetchCounties = (state) => {
    dispatch(listCountiesByState(state));
  };

  const fetchCountyGeo = async () => {
    const response = await axios.get('maps/counties.geojson');
    setState({
      ...state,
      geodata: response.data,
    });
  };

  return (
    <Map
      mapLib={import('mapbox-gl')}
      ref={mapRef}
      initialViewState={{
        longitude: -100,
        latitude: 40,
        zoom: 6,
      }}
      style={{
        minHeight: 400,
        opacity: state.byPostal ? 0.1 : 1,
        width: '568px',
        maxHeight: '400px',
      }}
      mapStyle='mapbox://styles/mapbox/light-v10'
      mapboxAccessToken={mapboxToken}
      interactiveLayerIds={['county-lines']}
      onLoad={() => setMapLoaded(true)}
      attributionControl={false}
    >
      <Source
        id='counties'
        type='geojson'
        data={state.geodata}
        promoteId='GEOID'
      >
        <Layer
          id='county-lines'
          type='fill'
          paint={{
            'fill-color': '#00A887',
            'fill-opacity': [
              'case',
              ['boolean', ['feature-state', 'selected'], false],
              0.6,
              0.075,
            ],
          }}
        />
      </Source>
    </Map>
  );
}

MarketMap.propTypes = {
  market: PropTypes.object.isRequired,
};

export { MarketMap };
