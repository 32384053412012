import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import jmespath from 'jmespath';

import { LoginRoot } from 'containers/LoginRoot';
import { AppRoot } from 'containers/AppRoot';
import { logoutUser } from 'store/actions/loginActions';
import { ColorUtil } from 'utils';

// TODO: Add Google Analytics
// import ReactGA from 'react-ga';
// ReactGA.initialize('UA-129296270-1');
// ReactGA.pageview(window.location.pathname + window.location.search);

Apex.colors = ColorUtil.COLORS;

class App extends Component {
  componentDidMount() {
    const {
      history,
      user,
      dispatch,
    } = this.props;

    const authenticated = user.id && jmespath.search(user, 'meta.authenticated');
    // paths that should not be accessed via authenticated user

    console.log('authenticated!', authenticated);
    // console.log('history', history);
    if (
      authenticated
      && (history.location // make sure we aren't trying to get to register or recover
        && history.location.pathname
        && (
          history.location.pathname.indexOf('register') !== -1
          || history.location.pathname.indexOf('recover') !== -1
        )
      )
    ) {
      // logout this user
      dispatch(logoutUser());
    }
  }

  render() {
    const {
      user,
    } = this.props;

    let content = <LoginRoot />;

    const authenticated = user.id && jmespath.search(user, 'meta.authenticated');

    if (authenticated) {
      content = <AppRoot />;
    }

    return (
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          {content}
        </LocalizationProvider>
      </BrowserRouter>
    );
  }
}

App.propTypes = {
  history: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object,
};

function mapStateToProps(state) {
  const {
    user,
  } = state;

  return {
    user,
  };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App };
