import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import {
  LoadingView,
  TitleTooltip,
} from 'components';

import {
  StoreUtil,
} from 'doctivity-shared/utils';

import {
  loadAnalytics,
} from 'store/actions/analyticsActions';

const styles = (theme) => ({
  card: {
    marginTop: theme.spacing(3),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
  nothingFound: {
    fontWeight: 300,
    fontSize: 12,
  },
});

class MonthlyVisitsBarChart extends React.Component {
  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.providerId !== prevProps.providerId
      || this.props.clientId !== prevProps.clientId
      || this.props.app.dateRange !== prevProps.app.dateRange
      || this.props.department !== prevProps.department
      || this.props.referringOrganization !== prevProps.referringOrganization
      || this.props.market !== prevProps.market) {
      this.fetchData();
    }
  }

  fetchData() {
    const {
      dispatch,
      providerId,
      clientId,
    } = this.props;

    dispatch(loadAnalytics({
      type: 'VISIT_COUNT_BY_MONTH',
      filter: {
        provider_id: providerId,
        client_id: clientId,
        market: this.props.market,
        department: this.props.department,
        referring_organization: this.props.referringOrganization,
        from: this.props.app.dateRange.start,
        to: this.props.app.dateRange.end,
      },
    }));
  }

  render() {
    const {
      classes,
      data,
    } = this.props;

    let graph;
    if (!data
      || StoreUtil.isLoading(data)) {
      graph = (<LoadingView />);
    } else if (data && data.data && data.data.length > 0) {
      const cleanData = this.transformVisitCounts(StoreUtil.getData(data), 'referred', 'count');
      const labels = {
        formatter: (value) => {
          return value.toLocaleString();
        }
      };
      graph = (
        <Chart
          type='line'
          height={260}
          options={{
            chart: {
              id: 'visit_types',
              toolbar: {
                show: false,
              },
              events: {
                dataPointSelection: this.onBarChartClick,
              },
            },
            xaxis: {
              categories: cleanData.dates,
            },
            // colors: ['#07594d'],
            tooltip: {
              enabled: true,
              shared: true,
              intersect: false,
            },
            stroke: {
              curve: 'smooth',
              width: [0, 0, 0, 3, 3],
            },
            dataLabels: {
              enabled: false,
              /*
              enabledOnSeries: [4],
              hideOverflowingLabels: false,
              dropShadow: {
                enabled: true,
                left: 0,
                top: 0,
                opacity: 0.4,
              },
              */
            },
            legend: {
              position: 'top',
            },
            yaxis: [
              {
                seriesName: 'New/Consult',
                axisTicks: {
                  show: true,
                },
                title: {
                  text: 'Visit Types',
                },
                labels,
              },
              {
                seriesName: 'New/Consult',
                show: false,
                labels,
              },
              {
                seriesName: 'New/Consult',
                show: false,
                labels,
              },
              {
                seriesName: 'New/Consult',
                show: false,
                labels,
              },
              {
                seriesName: 'Total Visits',
                opposite: true,
                axisTicks: {
                  show: true,
                },
                title: {
                  text: 'Total Visits',
                },
                labels,
              },
            ],
          }}
          series={[
            {
              name: 'New/Consult',
              data: cleanData.new_visits,
              type: 'column',
            },
            {
              name: 'Established',
              data: cleanData.est_visits,
              type: 'column',
            },
            {
              name: 'Referred',
              data: cleanData.counts,
              type: 'column',
            },
            {
              name: 'Unreferred',
              data: cleanData.unreferred,
              type: 'column',
            },
            {
              name: 'Total Visits',
              data: cleanData.total,
              type: 'line',
            },
          ]}
        />
      );
    } else {
      graph = (
        <Typography className={classes.nothingFound}>
          No referring affiliation data available.
        </Typography>
      );
    }

    return (
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.header}>
            <TitleTooltip
              title='Monthly Visits'
            />
          </div>

          {graph}

        </CardContent>
      </Card>
    );
  }

  transformVisitCounts(results, countField, totalField) {
    const transformed = {
      dates: [],
      counts: [],
      unreferred: [],
      est_visits: [],
      new_visits: [],
      total: [],
    };

    results.forEach((row) => {
      transformed.dates.push(`${moment().month(row.month).format('MMM')} ${moment().year(row.year).format('YY')}`);
      if (totalField) {
        transformed.counts.push(row[countField]);
        transformed.unreferred.push(row[totalField] - row[countField]);
        transformed.est_visits.push(row.est_visits);
        transformed.new_visits.push(row.new_visits);
        transformed.total.push(row[totalField]);
      } else {
        transformed.counts.push(row[countField]);
      }
    });

    return transformed;
  }
}

MonthlyVisitsBarChart.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object,
  app: PropTypes.object,
  data: PropTypes.object,
  clientId: PropTypes.number.isRequired,
  market: PropTypes.object,
  providerId: PropTypes.number,
  department: PropTypes.string,
  referringOrganization: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    app: state.app,
    data: StoreUtil.get(state.analytics, 'VISIT_COUNT_BY_MONTH'),
  };
}

const styled = withStyles(styles)(MonthlyVisitsBarChart);
const connected = connect(mapStateToProps)(styled);
export { connected as MonthlyVisitsBarChart };
