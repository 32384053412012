import React from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
// import { useSelector } from 'react-redux';

const withClientQuery = (WrappedComponent) => {
  const clientWrapper = (props) => {
    /*
    const navigate = useNavigate();
    const location = useLocation();
    const client = useSelector((state) => state.app.selectedClient);
    useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      if (!searchParams.has('client')) {
        searchParams.set('client', client);
        navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
      }
    }, [location, history, client]);
    */

    return <WrappedComponent {...props} />;
  };
  return clientWrapper;
};

export { withClientQuery };
