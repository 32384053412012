import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import { addDays } from 'date-fns';
import { LocationsUtil, withIsMobile } from 'utils';
import { withRouter } from 'utils';
import {
  DocumentDialogWithLink,
} from 'components';
import axios from 'axios';
import axiosInstance from 'utils/axiosUtil';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  Chip,
  FormControl,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  Icon,
  ListItemText,
  Box,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import {
  DateUtil,
  EncounterTypeUtil,
  StoreUtil,
} from 'doctivity-shared/utils/';
import {
  FindProviderDialog,
  SmallIconTextButton,
  LoadingView,
  ConfirmationDialog,
  ProviderCommentDeleteDialog,
} from 'components';
import { FindContactDialog } from 'contacts';
import { FindLocationDialog } from 'locations';

import { showConfirmationDialog } from 'store/actions/systemActions';
import {
  API_TAG_NAMESPACES_LIST_BY_CLIENT,
  listTagNamespacesByClient,
} from 'store/actions/tagNamespacesActions';

import {
  getProviderComment,
  deleteProviderComment,
} from 'store/actions/providerCommentsActions';
import { TagsUtil } from '../utils';

const styles = (theme) => ({
  smartSuggestionsContainer: {
    flexBasis: '30%',
    marginLeft: theme.spacing(2),
    borderLeft: '1px solid #999999',
    marginTop: -parseInt(theme.spacing(2)),
    marginBottom: -parseInt(theme.spacing(2)),
  },
  smartSuggestions: {
    padding: theme.spacing(2),
  },
  dateField: {
    paddingBottom: theme.spacing(1),
  },
  selectField: {
    minWidth: '200px',
  },
  providers: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  providersLabel: {
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.6)',
    paddingBottom: theme.spacing(1),
  },
  textField: {
    width: '100%',
  },

  typeContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    flexWrap: 'wrap',

    justifyContent: 'space-right',
    '& > *': {
      marginBottom: theme.spacing(1),
    },
  },
  typeSpacer: {
    width: theme.spacing(2),
  },
  spaceLeft: {
    marginLeft: theme.spacing(1),
  },
  followUpContainer: {
    display: 'flex',
    paddingTop: theme.spacing(1),
  },
  twoColumnContainer: {
    display: 'flex',
    paddingTop: theme.spacing(1),
  },
  icon: {
    height: 17,
    fontSize: 17,
    color: '#666666',
    marginRight: 6,
  },
  locationContainer: {
    paddingBottom: theme.spacing(3),
  },
  modalContent: {
    padding: theme.spacing(1),
  },
  tagSelector: {
    flexBasis: '*',
    minWidth: '200px'
  },
  fullTagSelector: {
    flexBasis: '100%',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  attachIcon: {
    transform: 'rotate(45deg)'
  },
});

const DEFAULT_DUE_DAYS = 7;

class ProviderCommentsDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isNew: true,
      comment: '',
      commentDate: new Date(),
      providers: [],
      contacts: [],
      documents: [],
      location: null,
      findProviderOpen: false,
      findContactOpen: false,
      findLocationOpen: false,
      confirmCloseOpen: false,
      encounterTypeId: 0,
      tagSelect: {},
      needsFollowUp: false,
      dueDate: addDays(new Date(), DEFAULT_DUE_DAYS),
      dataModified: false,
      showDeletePrompt: false,
    };
    if (props.tag) {
      this.state.tagSelect[props.tag.namespace.id] = [props.tag.id];
    }
  }

  componentDidMount() {
    const { data } = this.props;
    this.resetData();

    if (this.props.user.is_admin) {
      this.props.dispatch(
        showConfirmationDialog({
          title: 'Admin Users Can Not Create Notes',
          content: 'Login as a non-admin user to create activity notes.',
          confirmLabel: 'OK',
          onConfirm: () => {
            this.props.onClose?.();
          },
        }),
      );
    }

    if (
      StoreUtil.needsLoadLongCache(this.props.tagNamespaces) ||
      this.props.tagNamespaces.clientId !== this.props.clientId
    ) {
      // this.fetchTagNamespaces();
    }
    if (data && data.id) {
      this.fetchCommentFromDataId();
      this.setState({isNew: false});
    }
    if (this.props.tag) {
      const current = this.state.tagSelect[this.props.tag.namespace.id] ?? [];
      this.setState({
        tagSelect: {
          ...this.state.tagSelect,
          [this.props.tag.namespace.id]: Array.from(
            new Set([...(current ?? []), this.props.tag.id]),
          ),
        },
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.provider !== prevProps.provider ||
      this.props.contact !== prevProps.contact ||
      this.props.document !== prevProps.document
    ) {
      this.resetData();
    } else if (this.props.data && this.props.data !== prevProps.data) {
      this.resetData(this.props.data);
    }

    if (
      StoreUtil.needsLoadLongCache(this.props.tagNamespaces) ||
      this.props.tagNamespaces.clientId !== this.props.clientId
    ) {
      this.fetchTagNamespaces();
    }

    if (prevProps.created.data !== this.props.created.data) {
      this.onContactSelected(this.props.created.data);
    }
  }

  resetData(data) {

    //if (this.document) {
      //this.document.docid = this.state.docId;
    //}

    const newState = {
      providers: this.props.provider ? [this.props.provider] : [],
      contacts: this.props.contact ? [this.props.contact] : [],
      documents: this.props.document ? [this.props.document] : [],
      location: this.props.location,
      comment: '',
      commentDate: new Date(),
      encounterTypeId: 0,
      tagSelect: {},
      needsFollowUp: false,
      dueDate: addDays(new Date(), DEFAULT_DUE_DAYS),
      isResolved: null,
      confirmCloseOpen: false,
      dataModified: false,
    };
    if (data && data.id) {
      newState.comment = data.body;
      newState.commentDate = new Date(data.comment_date);
      if (data.providers && data.providers.length > 0) {
        newState.providers = data.providers;
      }
      if (data.contacts && data.contacts.length > 0) {
        newState.contacts = data.contacts;
      }
      if (data.documents && data.documents.length > 0) {
        newState.documents = data.documents;
      }
      if (data.uploads && data.uploads.length > 0) {
        newState.uploads = data.uploads;
      }
      if (data.location) {
        newState.location = data.location;
      }
      newState.encounterTypeId = data.encounter_type_id || 0;
      if (data.tags) {
        data.tags.forEach((tag) => {
          if (!newState.tagSelect[tag.namespace_id]) {
            newState.tagSelect[tag.namespace_id] = [];
          }
          newState.tagSelect[tag.namespace_id].push(tag.id);
        });
      }
      newState.needsFollowUp = data.needs_followup;
      newState.isResolved = data.is_resolved;
      newState.dueDate = new Date(data.due_date);
      if (this.props.tag) {
        const { tag } = this.props;
        const current = newState.tagSelect[tag.namespace.id];
        current.push(tag);
        newState.tagSelect[tag.namespace.id] = Array.from(new Set(current));
      }
    }
    this.setState(newState);
  }

  fetchTagNamespaces() {
    this.props.dispatch(listTagNamespacesByClient(this.props.clientId));
  }

  fetchCommentFromDataId() {
    const { dispatch, data } = this.props;
    dispatch(getProviderComment(data.id));
  }

  getRequiredNamespaces() {
    const namespaces = TagsUtil.getNamespacesByType(
      'activity_notes',
      this.props.tagNamespaces,
    );
    return namespaces.filter((ns) => ns.activity_notes_required);
  }

  isSaveDisabled() {
    const { comment, commentDate  } = this.state;

    const requiredNamespaces = this.getRequiredNamespaces();
    if (requiredNamespaces?.length > 0 && this.state.tagSelect) {
      for (const ns of requiredNamespaces) {
        if (
          !this.state.tagSelect[ns.id] ||
          this.state.tagSelect[ns.id].length === 0
        ) {
          return true;
        }
      }
    }

    return (
      !comment ||
      comment.length === 0 ||
      !commentDate
       //||  (providers.length < 1 && contacts.length < 1 && !location)
    );
  }

  render() {
    const { classes, open, provider, contact, document, clientId, isMobile , app} = this.props;

    const namespacesWithTagsSorted =
      TagsUtil.getNamespacesByType(
        'activity_notes',
        this.props.tagNamespaces,
      ) || [];

    const {
      isNew,
      comment,
      commentDate,
      providers,
      contacts,
      documents,
      location,
      findProviderOpen,
      findContactOpen,
      findLocationOpen,
      confirmCloseOpen,
      encounterTypeId,
      showDeletePrompt,
    } = this.state;

    const saveDisabled = this.isSaveDisabled();

    return (
      <>
        <Dialog
          onClose={this.onCancel}
          aria-labelledby='simple-dialog-title'
          open={open}
          fullScreen={this.props.isMobile}
          fullWidth
          maxWidth='lw'
          scroll='paper'
        >
          <DialogTitle>Activity Note</DialogTitle>
          <DialogContent dividers>
            <div className={classes.modalContent}>
              <Box
                className={classes.twoColumnContainer}
                sx={{ flexDirection: ['column', 'row'] }}
              >
                <div className={classes.typeContainer}>
                <FormControl >
                  {this.props.isMobile ? (
                    <DateTimePicker
                      className={classes.dateField}
                      label='Date'
                      value={commentDate}
                      onChange={this.onDateChange}
                      renderInput={(params) => <TextField {...params} />}
                      format={DateUtil.LDML_DISPLAY_DATE_TIME_INPUT_FORMAT}
                      maxDate={new Date()}
                    />
                  ) : (
                    <DatePicker
                      className={classes.dateField}
                      label='Date'
                      value={commentDate}
                      onChange={this.onDateChange}
                      renderInput={(params) => <TextField {...params} />}
                      format={DateUtil.LDML_DISPLAY_DATE_TIME_INPUT_FORMAT}
                      maxDate={new Date()}
                    />
                  )}
                </FormControl>

                <FormControl>
                  <InputLabel id='type-label'>Encounter Type</InputLabel>
                  <Select
                    className={classes.selectField}
                    labelId='type-label'
                    id='type-select'
                    value={encounterTypeId || ''}
                    label='Encounter Type'
                    onChange={this.onEncounterTypeChange}
                  >
                    {EncounterTypeUtil.getAll().map((t) => (
                      <MenuItem value={t.id} key={t.id}>
                        <Icon className={classes.icon}>{t.icon}</Icon>
                        {t.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                </div>
              </Box>
              <div className={classes.providers}>
                <Typography className={classes.providersLabel}>
                  Providers &amp; Contacts:
                </Typography>
                {providers.map((p) => (
                  <Chip
                    label={`${p.last_name}, ${p.first_name}`}
                    onDelete={
                      provider && p.id === provider.id
                        ? undefined
                        : () => {
                            this.onDeleteProvider(p);
                          }
                    }
                    variant='outlined'
                    key={p.id}
                  />
                ))}
                {contacts.map((c) => (
                  <Chip
                    label={`${c.last_name}, ${c.first_name}`}
                    onDelete={
                      contact && c.id === contact.id
                        ? undefined
                        : () => {
                            this.onDeleteContact(c);
                          }
                    }
                    variant='outlined'
                    key={c.id}
                  />
                ))}
                
                <SmallIconTextButton
                  icon='add'
                  text='Provider'
                  onClick={this.onAddProvider}
                />
                <SmallIconTextButton
                  icon='add'
                  text='Contact'
                  onClick={this.onAddContact}
                />
              </div>
              <div className={classes.locationContainer}>
                <Typography className={classes.providersLabel}>
                  Location:
                </Typography>
                {location ? (
                  <Chip
                    label={LocationsUtil.formatNameOrAddress(location)}
                    onDelete={() => {
                      this.onDeleteLocation(location);
                    }}
                    variant='outlined'
                    key={location.id}
                  />
                ) : (
                  <SmallIconTextButton
                    icon='add'
                    text='Location'
                    onClick={this.onAddLocation}
                  />
                )}
              </div>
              <div className={classes.typeContainer}>
                {!StoreUtil.isLoaded(this.props.tagNamespaces) ? (
                  <LoadingView />
                ) : (
                  namespacesWithTagsSorted.map((namespace) => (
                    <FormControl
                      variant='outlined'
                      className={
                        !isMobile && namespacesWithTagsSorted.length > 1
                          ? classes.tagSelector
                          : classes.fullTagSelector
                      }
                      key={namespace.id}
                    >
                      <InputLabel shrink={true} id={`label-${namespace.id}`}>
                        {`${namespace.name} ${namespace.activity_notes_required ? '*' : ''}`}
                      </InputLabel>
                      <Select
                        notched={true}
                        labelId={`label-${namespace.id}`}
                        id={`select-${namespace.id}`}
                        displayEmpty={true}
                        value={
                          namespace.activity_notes_multiple
                            ? this.state.tagSelect[namespace.id] || []
                            : (this.state.tagSelect[namespace.id] &&
                                this.state.tagSelect[namespace.id].length > 0 &&
                                this.state.tagSelect[namespace.id][0]) ||
                              ''
                        }
                        label={`${namespace.name} ${namespace.activity_notes_required ? '*' : ''}`}
                        onChange={(event) => {
                          this.onTagSelectChange(event, namespace);
                        }}
                        multiple={namespace.activity_notes_multiple || false}
                        variant='outlined'
                        renderValue={(selected) => {
                          if (
                            namespace.activity_notes_multiple &&
                            selected?.length > 0
                          ) {
                            return selected
                              .map(
                                (id) =>
                                  namespace.tags.find((t) => t.id === id).name,
                              )
                              .join(', ');
                          }
                          const tag = namespace.tags.find(
                            (t) => t.id === selected,
                          );
                          if (tag) {
                            return tag.name;
                          }
                          return <em>(None)</em>;
                        }}
                      >
                        {!namespace.activity_notes_multiple &&
                          !namespace.activity_notes_required && (
                            <MenuItem value={0} key={0}>
                              <em>(None)</em>
                            </MenuItem>
                          )}
                        {namespace.activity_notes_multiple
                          ? namespace.tags.map((tag) => (
                              <MenuItem value={tag.id} key={tag.id}>
                                <Checkbox
                                  checked={
                                    this.state.tagSelect[namespace.id] !==
                                      undefined &&
                                    this.state.tagSelect[namespace.id].includes(
                                      tag.id,
                                    )
                                  }
                                />
                                <ListItemText primary={tag.name} />
                              </MenuItem>
                            ))
                          : namespace.tags.map((tag) => (
                              <MenuItem value={tag.id} key={tag.id}>
                                {tag.name}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>
                  ))
                )}
              </div>
              <TextField
                label='Record your Activity Notes*'
                multiline
                rows={4}
                variant='outlined'
                value={comment}
                onChange={this.onTextChange}
                className={classes.textField}
              />

              <div className={classes.followUpContainer}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!!this.state.needsFollowUp}
                      onChange={this.onFollowUpChange}
                    />
                  }
                  label='Needs Resolution'
                />

                {this.state.needsFollowUp && (
                  <DatePicker
                    className={classes.dateField}
                    label='Due Date'
                    value={this.state.dueDate}
                    onChange={this.onDueDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    format={DateUtil.LDML_DISPLAY_FORMAT}
                    minDate={new Date()}
                    fullWidth
                  />
                )}
              </div>
              {this.state.needsFollowUp &&
                this.props.data &&
                this.props.data.id && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!this.state.isResolved}
                        onChange={this.onResolvedChange}
                      />
                    }
                    label='Is Resolved'
                  />
                )}
            </div>
            <div>
            {documents.map((d) => {
              if (!d.docId && d.name) {
                d.docId = d.ProviderCommentDocuments.document_id;
                d.file = {name: d.name};
              }
            })}
            {documents.map((d) => (
                  <Chip
                    label={`${d.file.name}`}
                    onDelete={
                      document && d.file.name === document.file.name
                        ? undefined
                        : () => {
                            console.log(d);
                            this.onDeleteDocument(d);
                          }
                    }
                    variant='outlined'
                    key={d.id}
                  />
                ))}
            </div>
            <Button
                  color='primary'
                  size='small'
                  onClick={() => this.openUploadDialog(undefined)}
                >
                  <Icon className={classes.attachIcon}>attach_file</Icon>
                  Attach Document
                </Button>


          </DialogContent>
          <DialogActions>

            { !isNew && 
            <Button onClick={this.onDelete} color='primary'>
            
              <Icon>delete</Icon>
            </Button>}
            <Button onClick={this.onCancel} color='primary'>
              Cancel
            </Button>
            <Button
              onClick={this.onSave}
              color='primary'
              variant='contained'
              disabled={saveDisabled}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <DocumentDialogWithLink
            clientId={app.selectedClient}
            isDataUpload={false}
            open={this.state.isUploadDialogOpen}
            onClose={this.closeUploadDialog}
            onExited={this.handleUploadExited}
            onLink={this.linkDocument}           
            onUpload={this.startUpload}
            uploadState={this.state.uploadDialogState}
            uploadProgress={this.state.uploadProgress}
            editing={this.state.editingDocument || null}
          />

        {findProviderOpen && (
          <FindProviderDialog
            clientId={clientId}
            provider={provider}
            selectedContactId={contacts.length > 0 ? contacts[0].id : undefined}
            selectedLocationId={location?.id}
            selectedLabelId={
                namespacesWithTagsSorted ? namespacesWithTagsSorted?.flatMap((ns) =>
                    ns.tags.map((t) => t.id),
                  )
                : []
            }
            open={findProviderOpen}
            onMultiSelect={this.onProvidersMultiSelected}
          />
        )}
        {findContactOpen && (
          <FindContactDialog
            clientId={clientId}
            contact={contact}
            selectedProviderId={
              providers.length > 0 ? providers[0].id : undefined
            }
            selectedLocationId={location?.id}
            open={findContactOpen}
            onMultiSelect={this.onContactsMultiSelected}
          />
        )}
        {findLocationOpen && (
          <FindLocationDialog
            open={findLocationOpen}
            showSuggested
            selectedProviderId={
              providers.length > 0 ? providers[0].id : undefined
            }
            selectedContactId={contacts.length > 0 ? contacts[0].id : undefined}
            onSelect={this.onLocationSelected}
            onClose={() => this.onLocationSelected(null)}
          />
        )}
        {showDeletePrompt && (
          <ProviderCommentDeleteDialog
            data={this.props.data}
            onClose={this.cancelDelete}
            open={showDeletePrompt}
            onDelete={this.deleteComment}
          />
        )}
        <ConfirmationDialog
          open={confirmCloseOpen}
          title='Close Dialog?'
          content='You will lose any unsaved changes.'
          onClose={this.onCloseConfirmCancel}
          onCancel={this.onCloseConfirmCancel}
          onConfirm={this.onConfirmCancel}
        />
      </>
    );
  }

  openUploadDialog = (editingDocument) => {
    this.setState({
      isUploadDialogOpen: true,
      uploadDialogState: 'EDIT',
      editingDocument,
    });
  };

  handleUploadExited = () => {
    this.setState({
      uploadDialogState: 'EDIT',
    });
  };

  closeUploadDialog = () => {
    if (this.state.uploadDialogState !== 'LOADING') {
      this.setState({
        isUploadDialogOpen: false,
        editingDocument: null,
      });
    }
  };


  onAddProvider = () => {
    this.setState({
      findProviderOpen: true,
    });
  };

  onProviderSelected = (provider) => {
    if (!provider) {
      // cancel sends a null provider, just close
      this.setState({
        findProviderOpen: false,
      });
    } else {
      this.setState((state) => {
        let existing = false;
        state.providers.forEach((p) => {
          if (p.id === provider.id) {
            existing = true;
          }
        });
        if (!existing) {
          state.providers.push(provider);
        }
        return {
          providers: state.providers,
          findProviderOpen: false,
          dataModified: true,
        };
      });
    }
  };

  onProvidersMultiSelected = (providers) => {
    if (!providers) {
      // cancel sends a null provider, just close
      this.setState({
        findProviderOpen: false,
      });
    } else {
      this.setState((state) => {
        providers.forEach((newProvider) => {
          let existing = false;
          state.providers.forEach((p) => {
            if (p.id === newProvider.id) {
              existing = true;
            }
          });
          if (!existing) {
            state.providers.push(newProvider);
          }
        });

        return {
          providers: state.providers,
          findProviderOpen: false,
          dataModified: true,
        };
      });
    }
  };

  onContactsMultiSelected = (contacts) => {
    if (!contacts) {
      // cancel sends a null provider, just close
      this.setState({
        findContactOpen: false,
      });
    } else {
      this.setState((state) => {
        contacts.forEach((newContact) => {
          let existing = false;
          state.contacts.forEach((p) => {
            if (p.id === newContact.id) {
              existing = true;
            }
          });
          if (!existing) {
            state.contacts.push(newContact);
          }
        });

        return {
          contacts: state.contacts,
          findContactOpen: false,
          dataModified: true,
        };
      });
    }
  };

  onDeleteProvider = (provider) => {
    if (!this.props.provider || provider.id !== this.props.provider.id) {
      this.setState((state) => {
        if (
          !this.props.data ||
          !this.props.data.id || // not editing
          state.contacts.length + state.providers.length > 1
        ) {
          const i = state.providers.indexOf(provider);
          if (i >= 0) {
            state.providers.splice(i, 1);
          }
        }
        return { providers: state.providers, dataModified: true };
      });
    }
  };

  onDeleteDocument = (document) => {
    if (!this.props.document || document.file.name !== this.props.document.file.id) {
      this.setState((state) => {
        if (
          !this.props.data ||
          !this.props.data.id || // not editing
          state.contacts.length + state.providers.length > 1
        ) {
          const i = state.documents.indexOf(document);
          if (i >= 0) {
            state.documents.splice(i, 1);
          }
        }
        return { documents: state.documents, dataModified: true };
      });
    }
  };

  onAddContact = () => {
    this.setState({
      findContactOpen: true,
    });
  };

  onContactSelected = (contact) => {
    if (!contact) {
      // cancel sends a null contact, just close
      this.setState({
        findContactOpen: false,
      });
    } else {
      this.setState((state) => {
        let existing = false;
        state.contacts.forEach((c) => {
          if (c.id === contact.id) {
            existing = true;
          }
        });
        if (!existing) {
          state.contacts.push(contact);
        }
        return {
          contacts: state.contacts,
          findContactOpen: false,
          dataModified: true,
        };
      });
    }
  };

  onDeleteContact = (contact) => {
    if (!this.props.contact || contact.id !== this.props.contact.id) {
      this.setState((state) => {
        if (
          !this.props.data ||
          !this.props.data.id || // not editing
          state.contacts.length + state.providers.length > 1
        ) {
          const i = state.contacts.indexOf(contact);
          if (i >= 0) {
            state.contacts.splice(i, 1);
          }
        }
        return { contacts: state.contacts, dataModified: true };
      });
    }
  };

  onAddLocation = () => {
    this.setState({
      findLocationOpen: true,
    });
  };

  onLocationSelected = (location) => {
    if (!location) {
      // cancel sends a null location, just close
      this.setState({
        findLocationOpen: false,
      });
    } else {
      this.setState((state) => {
        let existing = state.location?.id === location.id;
        if (!existing) {
          state.location = location;
        }
        return {
          location: state.location,
          findLocationOpen: false,
          dataModified: true,
        };
      });
    }
  };

  onDeleteLocation = () => {
    this.setState((state) => {
      if (
        !this.props.data ||
        !this.props.data.id || // not editing
        state.location
      ) {
        state.location = null;
      }
      return { location: state.location, dataModified: true };
    });
  };

  onDelete = () => {
    this.setState({ showDeletePrompt: true });
  };
  cancelDelete = () => {
    this.setState({ showDeletePrompt: false });
  };
  deleteComment = (data) => {
    this.props.dispatch(deleteProviderComment(data.id));
    this.props.router.navigate('/notes');
  };

  onCancel = (event, reason) => {
    const { dataModified } = this.state;

    if (
      dataModified &&
      !this.isSaveDisabled() &&
      reason &&
      reason === 'backdropClick'
    ) {
      this.setState({ confirmCloseOpen: true });
      return;
    }

    this.onConfirmCancel();
  };

  onConfirmCancel = () => {
    const { onClose } = this.props;

    this.resetData();
    if (onClose) {
      onClose();
    }
  };

  onSave = () => {
    const {
      comment,
      commentDate,
      providers,
      contacts,
      documents,
      location,
      encounterTypeId,
      needsFollowUp,
      dueDate,
      isResolved,
    } = this.state;

    const providerIds = providers.map((p) => p.id);
    const contactIds = contacts.map((c) => c.id);
    const documentIds = documents.map((d) => d.docId);
    const locationId = location?.id;
    if (this.isSaveDisabled() || (needsFollowUp && !dueDate)) {
      return;
    }

    const tags = [];
    const namespaces = Object.values(this.state.tagSelect);
    if (namespaces) {
      namespaces.forEach((namespace) => {
        tags.push(...namespace);
      });
    }
    // console.log('tags: ', tags);

    const toSave = {
      body: comment,
      comment_date: commentDate,
      providers: providerIds,
      contacts: contactIds,
      documents: documentIds,
      location_id: locationId,
      encounter_type_id: encounterTypeId || null,
      tags,
      needs_followup: needsFollowUp,
      due_date: (needsFollowUp && dueDate) || null,
      is_resolved: needsFollowUp && isResolved,
    };
    if (this.props.data && this.props.data.id) {
      toSave.id = this.props.data.id;
    }
    this.props.onSave(toSave);
  };

  onTextChange = (event) => {
    this.setState({
      comment: event.target.value,
      dataModified: true,
    });
  };

  onDateChange = (newDate) => {
    if (newDate && newDate.toString() === 'Invalid Date') {
      this.setState({
        commentDate: null,
        dataModified: true,
      });
    } else {
      this.setState({
        commentDate: newDate,
        dataModified: true,
      });
    }
  };

  onEncounterTypeChange = (event) => {
    this.setState({
      encounterTypeId: event.target.value,
      dataModified: true,
    });
  };

  onTagSelectChange = (event, namespace) => {
    this.setState((state) => {
      let selected = event.target.value;
      if (!namespace.activity_notes_multiple) {
        selected = [selected];
      }
      return {
        tagSelect: {
          ...state.tagSelect,
          [namespace.id]: selected,
        },
        dataModified: true,
      };
    });
  };

  onFollowUpChange = (event) => {
    this.setState({
      needsFollowUp: event.target.checked,
      dataModified: true,
    });
  };

  onDueDateChange = (newDate) => {
    this.setState({
      dueDate: newDate,
      dataModified: true,
    });
  };

  onResolvedChange = (event) => {
    this.setState({
      isResolved: event.target.checked,
      dataModified: true,
    });
  };

  onCloseConfirmCancel = () => {
    this.setState({ confirmCloseOpen: false });
  };

  onSmartLocationSelected = (location) => {
    console.log(location);
  };

  onSmartProviderSelected = (provider) => {
    console.log(provider);
  };

  onSmartContactSelected = (contact) => {
    console.log(contact);
  };

  linkDocument = (data) => {
    this.setState((state) => {
      let existing = false;
      state.documents.forEach((d) => {
        if (d.docId === data.docId) {
          existing = true;
        }
      });
      if (!existing) {
        state.documents.push(data);
//        this.startUploadNext(data);
      }
      return {
        documents: state.documents,
        dataModified: true,
        uploadDialogState: 'SUCCESS',
      };
    });
  }

  startUpload = (data) => {
    this.setState((state) => {
      let existing = false;
      state.documents.forEach((d) => {
        if (d.docId === data.docId) {
          existing = true;
        }
      });
      if (!existing) {
        state.documents.push(data);
        this.startUploadNext(data);
      }
      return {
        documents: state.documents,
        dataModified: true,
        uploadDialogState: 'SUCCESS',
      };
    });
  }

  startUploadNext = (data) => {
    if (this.state.editingDocument) {
      this.closeUploadDialog();
      this.props.dispatch(upsertDocument(data));
    } else {
      this.setState({
        uploadDialogState: 'LOADING',
      });

      const wakeupInterval = setInterval(
        () => {
          this.props.dispatch(wakeupServer());
        },
        5 * 60 * 1000, // ever 5 min make sure app doesn't logout
      );
      axiosInstance.post('/Documents', {
        name: data.name,
        description: data.description,
        client_id: data.client_id,
        original_filename: data.file.name,
        size_bytes: data.file.size,
        content_type: data.file.type
      }, {
        params: {
          selected_client_id: data.client_id,
        }
      }).then((response) => {
        const result = response.data;
        data.docId = response.data.docId;
        axios(result.uploadUrl, {
          method: 'PUT',
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 1000) / progressEvent.total,
            ) / 10;
            this.setState({
              uploadProgress: percentCompleted,
            });
          },
          data: data.file,
          headers: {
            'content-type': data.file.type,
            // 'x-amz-acl': 'authenticated-read',
            // 'x-amz-meta-key': result.key,
          },
        }).then(() => {
          this.setState({
            uploadDialogState: 'SUCCESS',
          }, () => {
          });
          clearInterval(wakeupInterval);
        }).catch(() => {
          this.setState({
            uploadDialogState: 'ERROR',
          });
          clearInterval(wakeupInterval);
        });
      });
    }
  };


}

ProviderCommentsDialog.propTypes = {
  app: PropTypes.object,
  router: PropTypes.object,
  classes: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  data: PropTypes.object,
  clientId: PropTypes.number.isRequired,
  tag: PropTypes.object,
  open: PropTypes.bool.isRequired,
  provider: PropTypes.object,
  contact: PropTypes.object,
  document: PropTypes.object,
  location: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  tagNamespaces: PropTypes.object,
  created: PropTypes.object,
  user: PropTypes.object,
};

function mapStateToProps(state) {
  const { contacts, user, app } = state;

  return {
    app,
    user,
    created: StoreUtil.get(contacts, StoreUtil.COMMON_EDIT_ITEM),
    tagNamespaces: StoreUtil.get(
      state.tagNamespaces,
      API_TAG_NAMESPACES_LIST_BY_CLIENT,
    ),
  };
}
const c = withStyles(styles)(withIsMobile(ProviderCommentsDialog));
const connected = connect(mapStateToProps)(c);
const routed = withRouter(connected);
export { routed as ProviderCommentsDialog };
