import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import { StoreUtil } from 'doctivity-shared/utils';
import { DynamicTable } from 'components';
import { ActivityLogsUtil } from 'utils';
import { activityLogsByUserId } from 'store/actions/activityLogsActions';
import { ContactsTabs } from './ContactsTabs';

import { Helmet } from 'react-helmet';
import { Typography } from '@mui/material';

const styles = (theme) => ({
  title: {
     padding: theme.spacing(3),
     paddingBottom: 0,
     display: 'flex',
     flexDirection: 'row',
     alignItems: 'baseline',
     backgroundColor: theme.palette.primary.accent,
   },
   headerTitle: {
     color: '#FFFFFF',
     fontSize: 24,
     paddingRight: 30,
   },
   container: {
     padding: theme.spacing(3),
   },
   headerContainer: {
     display: 'flex',
     flexDirection: 'row',
     alignItems: 'center',
   },
   header: {
     marginRight: theme.spacing(2),
     flex: 1,
   },
   leftIcon: {
     marginRight: theme.spacing(1),
   },
 });

class RecentContactsPage extends React.Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        label: 'Last Name',
        key: 'last_name',
        fulltext: true,
        style: { minWidth: 70 },
      },
      {
        label: 'First Name',
        key: 'first_name',
        fulltext: true,
        style: { minWidth: 70 },
      },
      {
        label: 'Organization',
        key: 'location.organization.name',
        fulltext: true,
      },
      { 
        label: 'Company',
        key: 'company',
        style: { minWidth: 100 },
      },
      {
        label: 'Owner',
        key: 'owner.last_name',
        format: (value, row) => {
          if (row.owner) {
            return `${row.owner.last_name}, ${row.owner.first_name}`;
          }
          return '';
        },
        style: { width: 80 }
      },
    ];

    this.state = {
      query: {
        include: [
          {
            association: 'owner',
            attributes: ['id', 'first_name', 'last_name'],
          },
        ],
        limit: 25,
        offset: 0,
      },
    };
  }

  componentDidMount() {
    const { recents } = this.props;

    if (StoreUtil.needsLoadNoCache(recents)) {
      this.fetchData();
    }
  }

  fetchData() {
    const { dispatch, userId, clientId, contacts } = this.props;

    const { query } = this.state;

    StoreUtil.setInvalid(contacts, StoreUtil.COMMON_TABLE);
    dispatch(
      activityLogsByUserId(userId, 'Contacts', clientId, {
        ...query,
      })
    );
  }

  render() {
    const { recents, classes } = this.props;

    const { query } = this.state;

//    const contactsData = StoreUtil.getData(contacts);
  //  const recentsData = StoreUtil.getData(recents);
    //if (
      //contactsData?.rows &&
      //contactsData?.rows.length === recentsData?.rows?.length
    //) {
      //for (let i = 0; i < contactsData.rows.length; i++) {
        //recentsData.rows[i].affiliated = organizationsData.rows.find(
//          (organization) => {
  //          return organization.id === recentsData.rows[i].id;
    //      }
      //  )?.affiliated;
      //}
    //}

    return (
      <>
       <Helmet defer={false}>
          <title>Contacts</title>
        </Helmet>
        <div className={classes.title}>
          <Typography className={classes.headerTitle}>Contacts</Typography>
        </div>
        <ContactsTabs tab='recent_contacts' />
        <div className={classes.container}>
          <DynamicTable
            header='Recent Contacts'
            showFilters={false}
            columns={this.columns}
            query={query}
            data={recents}
            onQueryChange={this.onQueryChange}
            getRowHref={(row) => `/contacts/${row.id}`}
          />
        </div>
      </>
    );
  }

  onQueryChange = (query) => {
    this.setState({ query }, this.fetchData.bind(this));
  };
}

RecentContactsPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  recents: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  contacts: PropTypes.object,
};

function mapStateToProps(state) {
  const { activityLogs } = state;

  const userId = state.user ? state.user.id : null;

  return {
    userId,
    clientId: state.app.selectedClient,
    contacts: StoreUtil.get(state.contacts, StoreUtil.COMMON_TABLE),
    recents: ActivityLogsUtil.getContactsFromLogs(activityLogs, userId),
  };
}

const styled = withStyles(styles)(RecentContactsPage);
const connected = connect(mapStateToProps)(styled);
export { connected as RecentContactsPage };
