import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { alpha } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import MaskedPhoneNumberFormat, {
  MaskedPhoneNumberExtensionFormat,
} from './MaskedPhoneNumberFormat';

const styles = (theme) => ({
  container: {
    width: '100%',
    maxWidth: 800,
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',
  },
  cPathway: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    margin: 0,
  },
  cRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cPathwayAlt: {
    backgroundColor: '#F5F5F5',
  },
  cHeader: {
    backgroundColor: alpha(theme.palette.primary.light, 0.6),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  input: {
    // backgroundColor: '#F8F8F8',
    width: '100%',
  },
  inputLabel: {
    // transform: 'none',
    // lineHeight: '120%',
    zIndex: 10,
  },
  inputPathway: {
    width: '35%',
    marginBottom: 16,
    marginTop: 16,
    backgroundColor: 'transparent',
  },
  inputPathwayColor: {
    backgroundColor: 'transparent',
  },
  inputLabelPathway: {
    width: '60%',
    position: 'relative',
  },
  inputLabelHeader: {
    color: '#FFFFFF',
  },
});

function onChangeEvent(event, onChange, options) {
  onChange(
    event.target.value === '' && options.emptyValue
      ? options.emptyValue
      : event.target.value
  );
}

function TextWidget(props) {
  // Note: since React 15.2.0 we can't forward unknown element attributes, so we
  // exclude the "options" and "schema" ones here.
  if (!props.id) {
    throw new Error(`no id for props ${JSON.stringify(props)}`);
  }
  const {
    value,
    readonly,
    disabled,
    autofocus,
    onBlur,
    onFocus,
    options,
    schema,
    formContext,
    registry,
    rawErrors,
    classes,
    required,
    label,
    multiline,
    variant,
    onChange,
    ...inputProps
  } = props;

  if (schema && formContext && registry && rawErrors) {
    // just grabbing an error for not using this.  Need to be taken from ...inputProps
  }

  inputProps.type = options.inputType || inputProps.type || 'text';

  //let description;
  let hasError = false;
  if (rawErrors != null) {
    if ((value && value.length > 0) || required) {
    //  description = rawErrors;
      hasError = rawErrors != null;
    }
  } else {
    //description = schema.description;
  }

  let rows = 1;
  if (multiline) {
    rows = 3;
    if (options.rows && options.rows > 0) {
      rows = options.rows;
    }
  }

  let containerClsList;
  let labelClsList;
  let inputClsList;

  if (options.pathwayMode) {
    labelClsList = `${classes.inputLabel} ${classes.inputLabelPathway}`;
    if (multiline) {
      inputClsList = `${classes.input} ${classes.inputPathwayColor}`;

      if (options.altRow) {
        containerClsList = `${classes.container} ${classes.cPathway} ${classes.cPathwayAlt}`;
      } else {
        containerClsList = `${classes.container} ${classes.cPathway}`;
      }
    } else {
      inputClsList = `${classes.input} ${classes.inputPathway} ${classes.inputPathwayColor}`;

      if (options.altRow) {
        containerClsList = `${classes.container} ${classes.cPathway} ${classes.cRow} ${classes.cPathwayAlt}`;
      } else {
        containerClsList = `${classes.container}  ${classes.cPathway} ${classes.cRow}`;
      }
    }
  } else {
    containerClsList = `${classes.container}`;
    labelClsList = `${classes.inputLabel}`;
    inputClsList = `${classes.input}`;
  }

  let inputComponent = 'input';
  if (schema.mask) {
    if (schema.mask === 'phone') {
      inputComponent = MaskedPhoneNumberFormat;
    } else if (schema.mask === 'phone-ext') {
      inputComponent = MaskedPhoneNumberExtensionFormat;
    }
  }

  return (
    <TextField
      required={required}
      label={label}
      className={containerClsList}
      disabled={disabled || readonly}
      autoFocus={autofocus}
      value={value == null ? '' : value}
      onChange={(event) => onChangeEvent(event, onChange, options)}
      onBlur={onBlur && ((event) => onBlur(inputProps.id, event.target.value))}
      onFocus={
        onFocus && ((event) => onFocus(inputProps.id, event.target.value))
      }

      error={hasError}
      margin='normal'
      variant={variant}
      multiline={multiline}
      InputProps={{
        className: inputClsList,
        inputComponent,
      }}
      type={inputProps.type}
      InputLabelProps={{
        shrink: !!value,
        // disableAnimation: true,
        className: labelClsList,
      }}
      rows={rows}
    />
  );
}

TextWidget.defaultProps = {
  required: false,
  disabled: false,
  readonly: false,
  autofocus: false,
};

TextWidget.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
    PropTypes.array,
  ]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  autofocus: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  options: PropTypes.object,
  schema: PropTypes.object,
  formContext: PropTypes.object,
  registry: PropTypes.object,
  rawErrors: PropTypes.array,
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  variant: PropTypes.string,
  multiline: PropTypes.bool,
  pathwayMode: PropTypes.bool,
};

const styled = withStyles(styles)(TextWidget);
export { styled as TextWidget };
