import React from 'react';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'utils';
import { Typography, Tabs, Tab } from '@mui/material';

const styles = (theme) => ({

  header: {
    padding: theme.spacing(3),
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  headerTitle: {
    color: `${theme.palette.primary.main}`,
    fontSize: 24,
    paddingRight: 30,
  },
  tabs: {
    paddingTop: 3,
    paddingLeft: theme.spacing(3),
    color: `${theme.palette.secondary.main}`,
  },
  indicator: {
    backgroundColor:`${theme.palette.secondary.main}`,
    height: 2,
    marginBottom: 8,
  },
  headerDivider: {},
  tabPanel: {
    color: `${theme.palette.primary.accent}`,
    '&.Mui-selected': {
      color: `${theme.palette.secondary.main}`,
    },
    '&:hover': {
      color: `${theme.palette.secondary.main}`,
    },
  },
});

class UploadTabs extends React.Component {
  constructor(props) {
    super(props);

 
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <Typography className={classes.headerTitle}>{this.props.title}</Typography>
        </div>
        <Tabs
          variant='scrollable'
          TabIndicatorProps={{ className: classes.indicator }}
          className={classes.tabs}
          value={this.props.tab}
          onChange={this.onTabChange}
        >
           <Tab
              className={classes.tabPanel}
              label='Upload Document'
              value='upload_document'
              disableRipple
            />
             <Tab
              className={classes.tabPanel}
              label='Link Existing Document'
              value='link_document'
              disableRipple
            />
        </Tabs>
      </div>
    );
  }
  
  onTabChange = (_event, tab) => {
      this.props.newTab(tab);
  }
}

UploadTabs.propTypes = {
  router: PropTypes.object.isRequired,
  classes: PropTypes.object,
  newTab: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired

};

const styled = withStyles(styles)(UploadTabs);
const routed = withRouter(styled);
export { routed as UploadTabs };
