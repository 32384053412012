import React from 'react';
import { PropTypes } from 'prop-types';
import { Helmet } from 'react-helmet';
import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'utils';
import { Typography, Tabs } from '@mui/material';
import { LinkTab } from 'components';

const styles = (theme) => ({
  container: {
    backgroundColor: theme.palette.primary.accent,
  },
  header: {
    padding: theme.spacing(3),
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  headerTitle: {
    color: '#FFFFFF',
    fontSize: 24,
    paddingRight: 30,
  },
  tabs: {
    paddingTop: 3,
    paddingLeft: theme.spacing(3),
  },
  indicator: {
    backgroundColor: '#FFFFFF',
    height: 2,
    marginBottom: 8,
  },
  headerDivider: {},
  tabPanel: {
    color: '#E0E0E0',
    '&.Mui-selected': {
      color: '#FFFFFF',
    },
    '&:hover': {
      color: '#FFFFFF',
    },
  },
});

class ProvidersTabs extends React.Component {
  render() {
    const { tab, classes } = this.props;

    return (
      <div className={classes.container}>
        <Helmet defer={false}>
          <title>Providers</title>
        </Helmet>
        <div className={classes.header}>
          <Typography className={classes.headerTitle}>Providers</Typography>
        </div>
        <Tabs
          variant='scrollable'
          TabIndicatorProps={{ className: classes.indicator }}
          className={classes.tabs}
          value={tab}
          onChange={this.onTabChange}
        >
          <LinkTab
            className={classes.tabPanel}
            label='All'
            value='providers'
            href='/providers/'
          />
           <LinkTab
            className={classes.tabPanel}
            label='Favorites'
            value='favorite_providers'
            href='/favotire_providers/'
          />
          <LinkTab
            className={classes.tabPanel}
            label='Recent'
            value='recent_providers'
            href='/recent_providers/'
          />
          <LinkTab
            className={classes.tabPanel}
            label='Affiliated'
            value='affiliated_providers'
            href='/affiliated_providers/'
          />
          <LinkTab
            className={classes.tabPanel}
            label='Referring'
            value='referring_providers'
            href='/referring_providers/'
          />
        </Tabs>
      </div>
    );
  }

  onTabChange = (event, newValue) => {
    this.props.router.navigate(`/${newValue}/`);
  };
}

ProvidersTabs.propTypes = {
  router: PropTypes.object.isRequired,
  classes: PropTypes.object,
  tab: PropTypes.string.isRequired,
};

const styled = withStyles(styles)(ProvidersTabs);
const routed = withRouter(styled);
export { routed as ProvidersTabs };
