export const API_SAVED_CRITERIA_UPSERT = 'API_SAVED_CRITERIA_UPSERT';
export const API_SAVED_CRITERIA_LIST = 'API_SAVED_CRITERIA_LIST';

export const querySavedCriteria = (options, type = API_SAVED_CRITERIA_LIST) => ({
  type,
  request: {
    url: '/UsersSavedCriteria',
    method: 'GET',
    params: {
      opts: options 
    }
  },
});

export const upsertSavedCriteria = (data) => {
    let options = null;
    let id = 0;
  
    if (data.id && data.id > 0) {
      id = data.id;
      options = {
        where: { id },
      };
    }
  
    return {
      type: API_SAVED_CRITERIA_UPSERT,
      id,
      request: {
        url: '/UsersSavedCriteria',
        method: 'PUT',
        params: { opts: options },
        data,
      },
    };
  };
  
